import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';

import { AdminService } from 'src/app/admin.service';
import { DeleteBgpersonsComponent } from '../delete-bgpersons/delete-bgpersons.component';
import { ExpdeleteComponent } from '../expdelete/expdelete.component';

@Component({
  selector: 'app-requirments',
  templateUrl: './requirments.component.html',
  styleUrls: ['./requirments.component.css']
})
export class RequirmentsComponent implements OnInit {
  serverResponse: any;
  requirments: any;
  requireForm: FormGroup;
  issubmitted: boolean;
  isupdated: boolean;
  requirementID: string = "";
  isLoading: boolean;
  loading: boolean;
  DropDownItems : any;
  productionID : string;
  requirementItems : any;

  constructor(private admin: AdminService, private fb: FormBuilder, 
    private snackBar: MatSnackBar,private dialog:MatDialog) { }

  ngOnInit() {

    this.requireForm = this.fb.group({
      // userID:new FormControl('', [Validators.required]),
      Quantity: new FormControl('', [Validators.required,Validators.pattern("^[0-9]*$")]),
      item: new FormControl('', [Validators.required]),
      Description: new FormControl('', [Validators.required]),
    })
    this.getItems();
    this.getrequirments();
  }

  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }

  // requireok(){
  //   location.reload()
  // }

  numericOnly(event) {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }
  getItems(){
    let user = JSON.parse(sessionStorage.getItem('user'));
    let dropdownitems=JSON.parse( localStorage.getItem('dropdownitems'));
    console.log(dropdownitems);
    this.requirementItems = dropdownitems.Requirement;
    let obj = {
      userID: user.userID
    }
    this.admin.getItems(obj).subscribe((posRes)=>{
      console.log(posRes);
      if (posRes.response == 3) {
        this.DropDownItems = posRes.DropDownItems[0];
        this.productionID = this.DropDownItems.productionID;
        // this.requirementItems = this.DropDownItems.Requirement;
        console.log(this.requirementItems);

      }else{
        this.openSnackBar("No Items get Error","")
      }
    },(err: HttpErrorResponse) => {
      this.openSnackBar(err.message, "");
      this.admin.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }

  getrequirments() {
    this.admin.showLoader.next(true);
    //console.log(sessionStorage.getItem('user'))
    let user = JSON.parse(sessionStorage.getItem('user'));
    //console.log(user)
    //userID: user.userID,
    let obj = {  
      type: "All"
    }

    this.admin.getrequirments(obj).subscribe((posRes) => {
      this.serverResponse = posRes;
      console.log(this.serverResponse)
      if (this.serverResponse.response == 3) {
        this.admin.showLoader.next(false);
        this.requirments = this.serverResponse.Info;
        // this.openSnackBar(posRes.message, "");
      }else{
        this.admin.showLoader.next(false);
      }
    },(err: HttpErrorResponse) => {
      
      this.openSnackBar(err.message, "");
      this.admin.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }

  requireupdate(require) {
    console.log(require);
    this.isupdated = true;
    this.requirementID = require.requirementID;
    this.requireForm.patchValue({
      Description: require.Description,
      item: require.item,
      Quantity: "" + require.Quantity,
    })
    document.getElementById("item").focus();
  }
  FormPatchValues(){
    this.requireForm.patchValue({
      Quantity :"",
      Description :"",
      item : ""
    })
  }
  require(){
    let payLoad = { ...this.requireForm.value };
    if(payLoad.Quantity == ""){
      this.openSnackBar("Please Enter Quantity","");
    }else {
      if(payLoad.Description == ""){
        this.openSnackBar("Please Enter Description","");
      }else{
        this.requiredForm();
      }
    }
   
  }

  requiredForm() {
    this.admin.showLoader.next(true);
    this.loading = true;
    let user = JSON.parse(sessionStorage.getItem('user'));
    let payLoad = { ...this.requireForm.value };
   
    payLoad.userID = user.userID;
    payLoad.Quantity = "" + payLoad.Quantity
    console.log(payLoad);
    if (this.isupdated) {
      payLoad.requirementID = this.requirementID;

      this.admin.updaterequire(payLoad).subscribe((posRes) => {
        this.serverResponse = posRes;
        console.log(this.serverResponse)
       
        if (this.serverResponse.response == 3) {
          this.loading = false;
          this.admin.showLoader.next(false);
          this.issubmitted = false;
          this.isupdated = false
          this.openSnackBar(posRes.message, "");

          this.getrequirments();
          this.FormPatchValues();
          location.reload()
        }else{
          this.loading = false;
        }
      },(err: HttpErrorResponse) => {
      
        this.openSnackBar(err.message, "");
        this.admin.showLoader.next(false);
        if (err.error instanceof Error) {
          console.warn("Client SIde Error", err.error);
        } else {
          console.warn("Server Error", err.error);
        }
      })
    } else {

      this.admin.require(payLoad).subscribe((posRes) => {
        this.serverResponse = posRes;
        console.log(this.serverResponse)
        if (this.serverResponse.response == 3) {
          this.admin.showLoader.next(false);
          this.loading = false;
          this.openSnackBar(posRes.message, "");
          this.FormPatchValues();
          //this.requireForm.reset()
         // location.reload()
          this.getrequirments();
          
        }else{
          this.loading = false;
        }
      },(err: HttpErrorResponse) => {
      
        this.openSnackBar(err.message, "");
        this.admin.showLoader.next(false);
        if (err.error instanceof Error) {
          console.warn("Client SIde Error", err.error);
        } else {
          console.warn("Server Error", err.error);
        }
      })

    }

  }

  requiredelete(require) {
    console.log(require);

    let dialogRef = this.dialog.open(ExpdeleteComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true,
      data: require
    })
    dialogRef.afterClosed().subscribe(res => {
      let user = JSON.parse(sessionStorage.getItem('user'));

      if (res.isDelete) {
        console.log(require)
        this.isLoading = true;
        let obj = {
          userID: user.userID,
          type: require.requirementID

        }

        this.admin.deleterequire(obj).subscribe((dele) => {

          console.log("del", dele);
          if (dele.response === 3) {
            this.isLoading = false;
            this.openSnackBar(dele.message, "");
            this.getrequirments();
            this.requireForm.reset()
          } else {
            this.isLoading = false;
          }
        })

      }

    })
  }


  checkLength2(e, input) {
    const functionalKeys = ['Backspace', 'ArrowRight', 'ArrowLeft'];

    if (functionalKeys.indexOf(e.key) !== -1) {
      return;
    }

    const keyValue = +e.key;
    if (isNaN(keyValue)) {
      e.preventDefault();
      return;
    }

    const hasSelection =
      input.selectionStart !== input.selectionEnd &&
      input.selectionStart !== null;
    let newValue;
    if (hasSelection) {
      newValue = this.replaceSelection(input, e.key);
    } else {
      newValue = input.value + keyValue.toString();
    }

    if (+newValue > 111111111 || newValue.length > 9) {
      e.preventDefault();
      console.log(newValue)
    }
  }
  private replaceSelection(input, key) {
    const inputValue = input.value;
    const start = input.selectionStart;
    const end = input.selectionEnd || input.selectionStart;
    return inputValue.substring(0, start) + key + inputValue.substring(end + 1);
  }

}


