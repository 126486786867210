import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AdminComponent } from './components/admin/admin.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ClosingBalanceSheetComponent } from './components/closing-balance-sheet/closing-balance-sheet.component';
import { ReportsComponent } from './components/reports/reports.component';
import { AllClientsComponent } from './components/all-clients/all-clients.component';
import { AllSalespersonsComponent } from './components/all-salespersons/all-salespersons.component';
import { ProcurementsComponent } from './components/procurements/procurements.component';
import { ClientordersComponent } from './components/clientorders/clientorders.component';
import { RequirmentsComponent } from './components/requirments/requirments.component';
import { ExpensesComponent } from './components/expenses/expenses.component';
import { SalesComponent } from './components/sales/sales.component';
import { RequirmentsinsertComponent } from './components/requirmentsinsert/requirmentsinsert.component';
import { DispatchApproveComponent } from './components/dispatch-approve/dispatch-approve.component';
import { ClientorderseditComponent } from './components/clientordersedit/clientordersedit.component';
import { AllproductPersonsComponent } from './components/allproduct-persons/allproduct-persons.component';
import { ClientordersviewComponent } from './components/clientordersview/clientordersview.component';
import { ProductsComponent } from './components/products/products.component';
import { ClosingBalanceViewComponent } from './components/closing-balance-view/closing-balance-view.component';
import { AddNewBrandsComponent } from './components/add-new-brands/add-new-brands.component';
import { AddBranchComponent  } from './components/add-branch/add-branch.component';
import { AddClientViewComponent } from './components/add-client-view/add-client-view.component';
import { AllReportsComponent } from './components/all-reports/all-reports.component';
import { ViewReportComponent } from './components/view-report/view-report.component';
import { AddnewItemComponent } from './components/addnew-item/addnew-item.component';
import { AddBrandInfoComponent } from './components/add-brand-info/add-brand-info.component';
import { AllmobileReportComponent } from './components/allmobile-report/allmobile-report.component';
import { ViewmobileReportComponent } from './components/viewmobile-report/viewmobile-report.component';
import { ProductEditComponent } from './components/product-edit/product-edit.component';
import { SalespersonViewComponent } from './components/salesperson-view/salesperson-view.component';
import { ProductionpersonViewComponent } from './components/productionperson-view/productionperson-view.component';
import { B2BComponent } from './components/b2-b/b2-b.component';
import { AdminDemoComponent } from './components/admin-demo/admin-demo.component';
import { AdminDemo2Component } from './components/admin-demo2/admin-demo2.component';
import { AddAdminComponent } from './components/add-admin/add-admin.component';
import { AllVendorsComponent } from './components/all-vendors/all-vendors.component';
import { AllReportsViewComponent } from './components/all-reports-view/all-reports-view.component';
import { DashboardnewComponent } from './components/dashboardnew/dashboardnew.component';
import { HeaderComponent } from './components/header/header.component';
import { AdminDemo3Component } from './components/admin-demo3/admin-demo3.component';
import { BankComponent } from './components/bank/bank.component';
import { CollectionComponent } from './components/collection/collection.component';
import { AmountGivenComponent } from './components/amount-given/amount-given.component';
import { RawmaterialspaymentsComponent } from './components/rawmaterialspayments/rawmaterialspayments.component';
import { ChatComponent } from './components/chat/chat.component';
import { DialyvisitComponent } from './components/dialyvisit/dialyvisit.component';
import { WorkersComponent } from './components/workers/workers.component';
import { WarehouseComponent } from './components/warehouse/warehouse.component';
import { MbaddnewitemComponent } from './components/mbaddnewitem/mbaddnewitem.component';
import { AdminDemo4Component } from './components/admin-demo4/admin-demo4.component';
import { AllReprtsViewnewComponent } from './components/all-reprts-viewnew/all-reprts-viewnew.component';
const routes: Routes = [
  {path:"login",component:LoginComponent},
  {path:"admin",component:AdminDemo4Component,children:[
    {path:"warehouse",component:WarehouseComponent},
    {path:"worker",component:WorkersComponent},
    {path:"bank",component:BankComponent},
    {path:"collection",component:CollectionComponent},
    {path:"amountGiven",component:AmountGivenComponent},
    {path:"rawmaterial",component:RawmaterialspaymentsComponent},
    {path:"chat",component:ChatComponent},
    {path:"dailyvisit",component:DialyvisitComponent},
    {path:"dashboard",component:DashboardnewComponent},
    {path:"Clients",component:AllClientsComponent},
    {path:"salesPersons",component:AllSalespersonsComponent},
    {path:"VendorPersons",component:AllVendorsComponent},
    {path:"clientorders", component:ClientordersComponent},
    {path:"requirments", component:RequirmentsComponent},
    {path:"expenses", component:ExpensesComponent},
    {path:"closingbal",component:ClosingBalanceSheetComponent},
    {path:"sales", component:SalesComponent},
    {path:"reports",component:AllReportsComponent},
    {path:"addnewItem",component:AddnewItemComponent},
    {path:"requirmentsinsert",component:RequirmentsinsertComponent},
    {path:"deposit", component:DispatchApproveComponent},
    {path:"procurement", component:ProcurementsComponent},
    {path:"Clientorderedit/:id", component:ClientorderseditComponent},
    {path:"productionperson",component:AllproductPersonsComponent},
    {path:"Clientview/:id",component:ClientordersviewComponent},
    {path:"viewreports/:id",component:ViewReportComponent},
    {path:"products", component:ProductsComponent},   
    {path:"addnewBranch", component:AddBranchComponent},
    {path:"addnewProductInfo", component:AddBrandInfoComponent},
    {path:"addbrand",component:AddNewBrandsComponent},
    {path:"viewClientInfo/:id",component:AddClientViewComponent},
    {path:"viewSalesInfo/:id",component:SalespersonViewComponent},
    {path:"viewProductionpersonInfo/:id",component:ProductionpersonViewComponent},
    {path:"addnewproduct",component:AddBrandInfoComponent},
    {path:"b2b",component:B2BComponent},
    {path:"clsview/:id",component:ClosingBalanceViewComponent},
    {path:"productView/:id",component:ProductEditComponent},
    {path:"AddAdmin",component:AddAdminComponent},
    {path:"**",redirectTo:"dashboard",pathMatch:"full" }
  ]},
  {path:"AllReports",component:AllmobileReportComponent},
  {path:"ViewReports/:id",component:ViewmobileReportComponent},
  {path:"BGReports",component:AllReportsViewComponent},
  {path:"AddNewItem/:id",component:MbaddnewitemComponent},
  {path:"**",redirectTo:"login",pathMatch:"full"}
 
 
];
@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
