import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from 'src/app/admin.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-requirmentsinsert',
  templateUrl: './requirmentsinsert.component.html',
  styleUrls: ['./requirmentsinsert.component.css']
})
export class RequirmentsinsertComponent implements OnInit {
  setrequirmentForm: FormGroup;

  constructor( private fb: FormBuilder,private service:AdminService,private snackBar:MatSnackBar) { }

  ngOnInit() {
    this.setrequirmentForm = this.fb.group({
    
      userID: ["",Validators.required],
      Quantity: ["", Validators.required],
      item: ["", [Validators.required]],
      Price: ["",Validators.required],
      Description: ["", [Validators.required,]],
    });
  }

  onsubmit(){
    console.log(this.setrequirmentForm);
    this.service.requirmentsinsert(this.setrequirmentForm.value).subscribe(
      (result) => {
        if(result.response==3){
          console.log("All IS WELL")
          console.log(this.setrequirmentForm);
          this.openSnackBar(result.message, "");
        }
      })
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
      verticalPosition: "top",
    });
  }

  
}
