import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatInputModule, MatButtonModule, MatIconModule, MatStepperModule, MatDialogModule, 
  MatMenuModule, MatDatepickerModule, MatNativeDateModule, MatRippleModule, MatButtonToggleModule,
   MatCheckboxModule, MatSnackBarModule, MatSelectModule, MatAutocompleteModule, MatChipsModule,MatTooltipModule,
    MatCardModule,MatTabsModule} from '@angular/material'
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ChartsModule, ThemeService } from 'ng2-charts';
import { AdminComponent } from './components/admin/admin.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { HttpClientModule } from '@angular/common/http';
import {NgbDatepickerModule, NgbDatepickerMonth, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ReportsComponent } from './components/reports/reports.component'
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BsDatepickerConfig, BsDatepickerModule, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { BnNgIdleService } from 'bn-ng-idle';
import {MatListModule} from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { environment } from "../environments/environment";
import { AddClintsComponent } from './components/add-clints/add-clints.component';
import { AllClientsComponent } from './components/all-clients/all-clients.component';
import { AllSalespersonsComponent } from './components/all-salespersons/all-salespersons.component';
import { ProcurementsComponent } from './components/procurements/procurements.component';
import { AddSalesPersonComponent } from './components/add-sales-person/add-sales-person.component';
import { ClientordersComponent } from './components/clientorders/clientorders.component';
import { ExpensesComponent } from './components/expenses/expenses.component';
import { SalesComponent } from './components/sales/sales.component';
import { RequirmentsComponent } from './components/requirments/requirments.component';
import { DeleteDailogComponent } from './components/delete-dailog/delete-dailog.component';
import { RequirmentsinsertComponent } from './components/requirmentsinsert/requirmentsinsert.component';
import { MatBadgeModule } from '@angular/material/badge';
import { DispatchApproveComponent } from './components/dispatch-approve/dispatch-approve.component';
import { ClientorderseditComponent } from './components/clientordersedit/clientordersedit.component';
import { SignoutComponent } from './components/signout/signout.component';
import { ProductPersonsComponent } from './components/product-persons/product-persons.component';
import { AllproductPersonsComponent } from './components/allproduct-persons/allproduct-persons.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ClientordersviewComponent } from './components/clientordersview/clientordersview.component';
import { ProductsComponent } from './components/products/products.component';
import { DeleteBgpersonsComponent } from './components/delete-bgpersons/delete-bgpersons.component';
import { ClosingBalanceSheetComponent } from './components/closing-balance-sheet/closing-balance-sheet.component';
import { ClosingBalanceViewComponent } from './components/closing-balance-view/closing-balance-view.component';
import { AddNewBrandsComponent } from './components/add-new-brands/add-new-brands.component';
import { AddBranchComponent } from './components/add-branch/add-branch.component';
import { AddClientViewComponent } from './components/add-client-view/add-client-view.component';
import { ExpdeleteComponent } from './components/expdelete/expdelete.component';
import { ProcurementdeleteComponent } from './components/procurementdelete/procurementdelete.component';
import { AllReportsComponent } from './components/all-reports/all-reports.component';
import { ViewReportComponent } from './components/view-report/view-report.component';
import { AddnewItemComponent } from './components/addnew-item/addnew-item.component';
import { AddBrandInfoComponent } from './components/add-brand-info/add-brand-info.component';
import { AllmobileReportComponent } from './components/allmobile-report/allmobile-report.component';
import { ViewmobileReportComponent } from './components/viewmobile-report/viewmobile-report.component';
import { ProductEditComponent } from './components/product-edit/product-edit.component';
import { ProductDeleteComponent } from './components/product-delete/product-delete.component';
import { SalespersonViewComponent } from './components/salesperson-view/salesperson-view.component';
import { ProductionpersonViewComponent } from './components/productionperson-view/productionperson-view.component';
import { BranchDeleteComponent } from './components/branch-delete/branch-delete.component';
import { B2BComponent } from './components/b2-b/b2-b.component';
import { ItemDeleteComponent } from './components/item-delete/item-delete.component';
import { AdminDemoComponent } from './components/admin-demo/admin-demo.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { AdminDemo2Component } from './components/admin-demo2/admin-demo2.component';
import { MatToolbarModule } from '@angular/material/toolbar'
import { B2BdeleteComponent } from './components/b2-bdelete/b2-bdelete.component';
import { UpdateClientComponent } from './components/update-client/update-client.component';
import { AddAdminComponent } from './components/add-admin/add-admin.component';
import { AddAdminInsertComponent } from './components/add-admin-insert/add-admin-insert.component';
import { UpdateProductionPersonComponent } from './components/update-production-person/update-production-person.component';
import { UpdateSalesPersonComponent } from './components/update-sales-person/update-sales-person.component';
import { AllVendorsComponent } from './components/all-vendors/all-vendors.component';
import { AddVendorsComponent } from './components/add-vendors/add-vendors.component';
import { AllReportsViewComponent } from './components/all-reports-view/all-reports-view.component';
import { DashboardnewComponent } from './components/dashboardnew/dashboardnew.component';
import { HeaderComponent } from './components/header/header.component';
import { AdminDemo3Component } from './components/admin-demo3/admin-demo3.component';
import { BankComponent } from './components/bank/bank.component';
import { CollectionComponent } from './components/collection/collection.component';
import { AmountGivenComponent } from './components/amount-given/amount-given.component';

import { ChatComponent } from './components/chat/chat.component';
import { DialyvisitComponent } from './components/dialyvisit/dialyvisit.component';
import { RawmaterialspaymentsComponent } from './components/rawmaterialspayments/rawmaterialspayments.component';
import { WorkersComponent } from './components/workers/workers.component';
import { WarehouseComponent } from './components/warehouse/warehouse.component';
import { MbaddnewitemComponent } from './components/mbaddnewitem/mbaddnewitem.component';
import { UpdateVendorComponent } from './components/update-vendor/update-vendor.component';
import { ViewVendorComponent } from './components/view-vendor/view-vendor.component';
import { AdminDemo4Component } from './components/admin-demo4/admin-demo4.component';
import { AllReprtsViewnewComponent } from './components/all-reprts-viewnew/all-reprts-viewnew.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AdminComponent,
    DashboardComponent,    
    ReportsComponent,
    AddClintsComponent,
    AllClientsComponent,
    DeleteDailogComponent,
    AllSalespersonsComponent,
    ProcurementsComponent,
    AddSalesPersonComponent,
    ClientordersComponent,
    ExpensesComponent,
    SalesComponent,
    RequirmentsComponent,  
    DispatchApproveComponent,
    SignoutComponent,
    RequirmentsinsertComponent,
    DispatchApproveComponent,
    ClientorderseditComponent,
    ProductPersonsComponent,
    AllproductPersonsComponent,
    SidebarComponent,
    ClientordersviewComponent,
    ProductsComponent,
    DeleteBgpersonsComponent,
    ClosingBalanceSheetComponent,
    ClosingBalanceViewComponent,
    AddNewBrandsComponent,
    AddBranchComponent,
    AddClientViewComponent,
    ExpdeleteComponent,
    ProcurementdeleteComponent,
    AllReportsComponent,
    ViewReportComponent,
    AddnewItemComponent,
    AddBrandInfoComponent,
    AllmobileReportComponent,
    ViewmobileReportComponent,
    ProductEditComponent,
    ProductDeleteComponent,
    SalespersonViewComponent,
    ProductionpersonViewComponent,
    BranchDeleteComponent,
    B2BComponent,
    ItemDeleteComponent,
    AdminDemoComponent,
    MenuItemComponent,
    AdminDemo2Component,
    B2BdeleteComponent,
    UpdateClientComponent,
    AddAdminComponent,
    AddAdminInsertComponent,
    UpdateProductionPersonComponent,
    UpdateSalesPersonComponent,
    AllVendorsComponent,
    AddVendorsComponent,
    AllReportsViewComponent,
    DashboardnewComponent,
    HeaderComponent,
    AdminDemo3Component,
    BankComponent,
    CollectionComponent,
    AmountGivenComponent,
    
    ChatComponent,
    DialyvisitComponent,
    RawmaterialspaymentsComponent,
    WorkersComponent,
    WarehouseComponent,
    MbaddnewitemComponent,
    UpdateVendorComponent,
    ViewVendorComponent,
    AdminDemo4Component,
    AllReprtsViewnewComponent,
  ],
  entryComponents:[
    AddClintsComponent,
    AddSalesPersonComponent,
    RequirmentsComponent,
    DeleteDailogComponent,
    SignoutComponent,
    ProductPersonsComponent,
    DeleteBgpersonsComponent,
    ExpdeleteComponent,
    ProcurementdeleteComponent,
    BranchDeleteComponent,
    ItemDeleteComponent,
    B2BdeleteComponent,
    UpdateClientComponent,
    AddAdminInsertComponent,
    UpdateProductionPersonComponent,
    UpdateSalesPersonComponent,
    AddVendorsComponent,
    UpdateVendorComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatInputModule,
    NgbModule,
    MatButtonModule,
    MatDialogModule,
    MatStepperModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRippleModule,
    MatTooltipModule,
    MatCardModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatChipsModule,
    NgbDatepickerModule,
    ScrollingModule,
    MatAutocompleteModule,
    BsDatepickerModule.forRoot(),
    ChartsModule,
    MatBadgeModule,
    MatListModule,
    MatSidenavModule,
    MatTabsModule,
    MatExpansionModule,
    MatToolbarModule,
   
  ],
  providers: [BsDatepickerConfig,BsDaterangepickerConfig,ThemeService,BnNgIdleService,
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
