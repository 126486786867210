import { Component, OnInit } from '@angular/core';

import { ClosingBalanceSheetComponent } from '../closing-balance-sheet/closing-balance-sheet.component';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { AddSalesPersonComponent } from '../add-sales-person/add-sales-person.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientordersComponent } from '../clientorders/clientorders.component';
import { AdminService } from 'src/app/admin.service';
import { stringify } from 'querystring';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';


@Component({
  selector: 'app-closing-balance-view',
  templateUrl: './closing-balance-view.component.html',
  styleUrls: ['./closing-balance-view.component.css']
})
export class ClosingBalanceViewComponent implements OnInit {
  disCount: number;
  totalAmount: number;
  timestamp: number;
  productsSubcribe: any;
  serverResponse: any;
  pay: any; 
  customerName: string;
  salesmanName: String;
  lastPaidAmount: number;
  closingbalance: number;
  Quantity: number;
  baseUrl: any;
  sub: any;
  userID: any;
  ordersList : Array<any> = [];
  PaymentTable: Array<any> =  [];
  id: any;

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private services: AdminService,
    private activatedRoute: ActivatedRoute,
    private router:Router,
    private snackBar:MatSnackBar,
  ) {
   
   }

  ngOnInit() {
    this.sub = this.activatedRoute.params.subscribe(params => {
      this.userID = params['id'];
      this.get_CB_Data();
    })
    console.log("ID",this.userID)

    this.baseUrl = "https://dev-api.bhavgroup.com"
   
  }
  get_CB_Data(){
    let obj = {
      userID: this.userID
    }
    this.services.ClosingBalanceSheet(obj).subscribe((posRes) => {
      this.serverResponse = posRes;
      console.log(this.serverResponse)
      if (this.serverResponse.response == 3) {
        this.PaymentTable = this.serverResponse.usersInfo
        console.log(this.PaymentTable);   
      }
      else{
        this.openSnackBar(posRes.message, "");
      }
    })
  }
  backclosingorderview(){
    this.router.navigateByUrl('/admin/closingbal')
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }

}
