import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormGroupDirective,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatSnackBar,
  MatDialog,
} from "@angular/material";
import { AllproductPersonsComponent } from '../allproduct-persons/allproduct-persons.component';
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import { AdminService } from '../../admin.service';

@Component({
  selector: 'app-update-production-person',
  templateUrl: './update-production-person.component.html',
  styleUrls: ['./update-production-person.component.css']
})
export class UpdateProductionPersonComponent implements OnInit {
  productionpersonForm: FormGroup;
  previewUrl: any = "https://dev-api.bhavgroup.com/images/user.png";
  loading: boolean = false;
  sucessInfo: boolean = false;
  suceessDataInfo: string = "";
  hide = true;
  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private dialogRef: MatDialogRef<AllproductPersonsComponent>,
    private cd: ChangeDetectorRef,
    private adminservice: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.productionpersonForm = this.fb.group({
      profilePic: [""],
      userID: ["", Validators.required],
      customerName: ["", Validators.required],
      PhoneNumber: ["", [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]],
      Address: ["", Validators.required],
      Dispatch: [false],
      CortonsClosingStock: [false],
      Expense: [false],
      Warehouse: [false],
      Rawmaterial: [false],
      RawMaterialClosingStock: [false],
      ATTENDANCE: [false],
      Requirement: [false],
      ReturnStock: [false]
    });
    console.log(this.data);
    if (this.data.userID) {
      this.updateProductionPersonValues();
    }
  }

  //File Upload
  fileProgress(event) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.previewUrl = reader.result;
        this.productionpersonForm.get("profilePic").setValue(file);
      };
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }

  updateProductionPersonValues() {
    let clientInfo = this.data;
    if (clientInfo.profilePic !== "") {
      this.previewUrl = "https://dev-api.bhavgroup.com" + this.data.profilePic;
      // console.log(this.data.NDAAttachment);
      this.http.get("https://dev-api.bhavgroup.com" + this.data.profilePic, {
        responseType: "blob",
      }).subscribe((file) => {
        let filename = "https://dev-api.bhavgroup.com" + this.data.profilePic;
        let spfname1 = this.data.profilePic.substring(this.data.profilePic.lastIndexOf("/") + 1);
        // let docName1 = spfname1.substring(spfname1.lastIndexOf("_")+1);
        let imgFile = new File([file], spfname1);
        this.productionpersonForm.get("profilePic").setValue(imgFile);
        // this.docsList = imgFile;

        // let spfname = this.data.profilePic.substring(this.data.profilePic.lastIndexOf("/") + 1);
        // this.docName = spfname.substring(spfname.lastIndexOf("_")+1);
      })
    }

    this.productionpersonForm.patchValue({
      userID: clientInfo.userID,
      customerName: clientInfo.customerName,
      PhoneNumber: clientInfo.PhoneNumber,
      Address: clientInfo.Address
    })
    let plist = clientInfo.productionlist;
    plist.filter(el =>{
      switch(el.title){
        case "Dispatch":
          this.productionpersonForm.patchValue({
            Dispatch: true
          })
        break;
        case "CortonsClosingStock":
          this.productionpersonForm.patchValue({
            CortonsClosingStock: true
          })
        break;
        case "Requirement":
          this.productionpersonForm.patchValue({
            Requirement: true
          })
        break;
        case "Expense":
          this.productionpersonForm.patchValue({
            Expense: true
          })
        break;
        case "Warehouse":
          this.productionpersonForm.patchValue({
            Warehouse: true
          })
        break;
        case "procurement":
          this.productionpersonForm.patchValue({
            Rawmaterial: true
          })
        break;
        case "RawMaterialClosingStock":
          this.productionpersonForm.patchValue({
            RawMaterialClosingStock: true
          })
        break;
        case "ReturnStock":
          this.productionpersonForm.patchValue({
            ReturnStock: true
          })
        break;
        case "ATTENDANCE":
          this.productionpersonForm.patchValue({
            ATTENDANCE: true
          })
        break;
      }
    })
    console.log(clientInfo);
  }
  numericOnly(event) {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }
  AddproductPerson() {
    this.loading = true;
    let payLoad = { ...this.productionpersonForm.value };
    payLoad.RegisterType = 2;
    delete payLoad.profilePic;
    let { Dispatch, CortonsClosingStock, Expense, Warehouse, Rawmaterial, RawMaterialClosingStock, ATTENDANCE, Requirement, ReturnStock } = this.productionpersonForm.value;
    let productionlist = []
    if (Dispatch) {
      productionlist.push({ Tag: 1, title: "Dispatch", imgPath: "/Production/Dispatch.png" })
    }
    if (CortonsClosingStock) {
      productionlist.push({ Tag: 2, title: "CortonsClosingStock", imgPath: "/Production/cortonsClosingStock.png" })
    }
    if (Requirement) {
      productionlist.push({ Tag: 3, title: "Requirement", imgPath: "/Production/Requirement.png" })
    }
    if (Expense) {
      productionlist.push({ Tag: 4, title: "Expense", imgPath: "/Production/Expense.png" })
    }
    if (Warehouse) {
      productionlist.push({ Tag: 5, title: "Warehouse", imgPath: "/Production/Warehouse.png" })
    }
    if (Rawmaterial) {
      productionlist.push({ Tag: 6, title: "Rawmaterial", imgPath: "/Production/RawMaterials.png" })
    }
    if (RawMaterialClosingStock) {
      productionlist.push({ Tag: 7, title: "RawMaterialClosingStock", imgPath: "/Production/RawMaterialClosingStock.png" })
    }
    if (ReturnStock) {
      productionlist.push({ Tag: 8, title: "ReturnStock", imgPath: "/Production/ReturnStock.png" })
    }
    if (ATTENDANCE) {
      productionlist.push({ Tag: 9, title: "ATTENDANCE", imgPath: "/Production/Dispatch.png" })
    }

    delete payLoad.Dispatch;
    delete payLoad.CortonsClosingStock;
    delete payLoad.Requirement;
    delete payLoad.Expense;
    delete payLoad.ReturnStock;
    delete payLoad.Warehouse;
    delete payLoad.Rawmaterial;
    delete payLoad.RawMaterialClosingStock;
    delete payLoad.ATTENDANCE;
    payLoad.productionlist = productionlist;
    console.log(payLoad);
    // if (this.productionpersonForm.value.profilePic != "") {
    let formData = new FormData();
    formData.append("image", this.productionpersonForm.get("profilePic").value);
    formData.append("userInfo", JSON.stringify(payLoad));
    this.adminservice.UpdateClient(formData).subscribe(
      (posRes) => {
        console.log(posRes);
        if (posRes.response == 3) {
          this.loading = false;
          this.openSnackBar(posRes.message, "");
          this.dialogRef.close(true);
          this.suceessDataInfo = "Production Person Data Inserted Sucess Fully";
        } else {
          this.loading = false;
          this.openSnackBar(posRes.message, "");
          console.log(posRes);
          //this.dialogRef.close(false);
        }
      },
      (err: HttpErrorResponse) => {
        this.loading = false;
        // console.log("Set Profile", err);
        if (err.error instanceof Error) {
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
    // } else {
    //   this.openSnackBar("Please Upload a Profile pic ", "");
    //   this.loading = false;
    // }
  }

  closeTab() {
    this.dialogRef.close(false);
  }
  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
      verticalPosition: "top",
    });
  }

}
