import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormGroupDirective,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatSnackBar,
  MatDialog,
} from "@angular/material";
import { AllSalespersonsComponent } from '../all-salespersons/all-salespersons.component';
import { AdminService } from '../../admin.service';
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
@Component({
  selector: 'app-add-sales-person',
  templateUrl: './add-sales-person.component.html',
  styleUrls: ['./add-sales-person.component.css']
})
export class AddSalesPersonComponent implements OnInit {
  salespersonProfileForm: FormGroup;
  previewUrl: any = "https://dev-api.bhavgroup.com/images/user.png";
  loading: boolean = false;
  suceessDataInfo : string ="";
  hide = true;
  allbranchInfo: any;
  AllBranchNames: any[];
  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private dialogRef: MatDialogRef<AllSalespersonsComponent>,
    private cd: ChangeDetectorRef,
    private adminservice : AdminService
  ) { }

  ngOnInit() {
    this.getBranches();
    this.salespersonProfileForm = this.fb.group({
      profilePic: [""],
      userID: ["",Validators.required],
      customerName: ["", Validators.required],
      PhoneNumber: ["", [Validators.required,Validators.pattern("^[0-9]*$"),Validators.minLength(10),Validators.maxLength(10)]],
      Address: ["",Validators.required],
      branch : ["",Validators.required],
      Password: ["", [Validators.required, Validators.minLength(6)]],
    });
  }
  //File Upload

  fileProgress(event) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.previewUrl = reader.result;
        this.salespersonProfileForm.get("profilePic").setValue(file);
      };
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }
  getBranches(){
    this.adminservice.getAllbranch().subscribe(
      (posRes) => {
        //console.log(posRes);
        if (posRes.response == 3) {
         this.allbranchInfo = posRes.branchList;
         let pInfo =[];
         this.allbranchInfo.filter(function (el){
           pInfo.push(el.branchName);
         })
         this.AllBranchNames = pInfo;
        } else {
          this.openSnackBar(posRes.message, "");
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
  }

  closeTab() {
    this.dialogRef.close();
  }
  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
      verticalPosition: "top",
    });
  }
  numericOnly(event) {    
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  AddSalesPerson(){
    this.loading = true;
    let payLoad = {...this.salespersonProfileForm.value};
    payLoad.RegisterType = 3;
    delete payLoad.profilePic;
    //console.log(payLoad);
    // if (this.salespersonProfileForm.value.profilePic != "") {
      let formData = new FormData();
      formData.append("image", this.salespersonProfileForm.get("profilePic").value);
      formData.append("userInfo", JSON.stringify(payLoad));
      
      this.adminservice.InsertSalesPerson(formData).subscribe(
        (posRes) => {
          //console.log(posRes);
          if (posRes.response == 3) {
            this.loading = false;
            this.openSnackBar(posRes.message, "");
            this.dialogRef.close(true);
            this.suceessDataInfo = "Clients Data Inserted Sucess Fully";
          }else{
            this.loading = false;
            this.openSnackBar(posRes.message, "");
            //console.log(posRes);
            // this.dialogRef.close(false);
          }
        },
        (err: HttpErrorResponse) => {
          this.loading = false;
          // console.log("Set Profile", err);
          if (err.error instanceof Error) {
            this.openSnackBar(err.message, "Client Error");
            console.log(err.message);
          } else {
            this.openSnackBar(err.message, "Server Error");
            console.log(err.message);
          }
        })
    // }else{
    //   this.openSnackBar("please Upload Profile pic","");
    //   this.loading=false;
    // }
    
  }

}
