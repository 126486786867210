import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css']
})
export class MenuItemComponent implements OnInit {
  showSubMenu : boolean = false;
  showRightArrow : boolean = false;
  menuItems = [  {menuItem: 'Item 1', subMenuItems: ['Sub-item 1.1', 'Sub-item 1.2']},
  {menuItem: 'Item 2', subMenuItems: ['Sub-item 2.1', 'Sub-item 2.2']},
  {menuItem: 'Item 3', subMenuItems: ['Sub-item 3.1', 'Sub-item 3.2']},
];
  constructor() { }

  ngOnInit() {
  }

}
