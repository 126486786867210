import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/admin.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar,MatDialog } from '@angular/material';
@Component({
  selector: 'app-dashboardnew',
  templateUrl: './dashboardnew.component.html',
  styleUrls: ['./dashboardnew.component.css']
})

export class DashboardnewComponent implements OnInit {

  AllModules = [
    { imgpath : "../../../assets/dashboard/bank.png" , link : "/admin/dashboard"},
    { imgpath : "../../../assets/dashboard/raw_material.png" , link : "/admin/dashboard"},
    { imgpath : "../../../assets/dashboard/New Order.png" , link : "/admin/sales"},
    { imgpath : "../../../assets/dashboard/expenses.png" , link : "/admin/expences"},
    { imgpath : "../../../assets/dashboard/Procurement.png" , link : "/admin/procurement"},
    { imgpath : "../../../assets/dashboard/closing balance.png" , link : "/admin/closingbal"},
    { imgpath : "../../../assets/dashboard/collction.png" , link : "/admin/collection"},
    { imgpath : "../../../assets/dashboard/amount_givne.png" , link : "/admin/amountGiven"},
    { imgpath : "../../../assets/dashboard/Deposit_approve.png" , link : "/admin/deposit"},
    { imgpath : "../../../assets/dashboard/sales_approve.png" , link : "/admin/clientorders"},
    { imgpath : "../../../assets/dashboard/production_requirment.png" , link : "/admin/requirments"},
    { imgpath : "../../../assets/dashboard/messages.png" , link : "/admin/chat"},
    { imgpath : "../../../assets/dashboard/daily_visit.png" , link : "/admin/dailyvisit"},
  ]

  pOpening : string ="0";
  pProduction : string ="0";
  pStockOut : string ="0";
  pclosingStock : string ="0";
  cOpening : string ="0";
  cCollection : string ="0";
  cExpenses : string ="0";
  cClosingBalance : string ="0";
  sOpening : string ="0";
  sSales : string ="0";
  sDiscount : string ="0";
  sCollection : string ="0";
  sClosingCash : string ="0";
  
  constructor(
    private adminService: AdminService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    let dt = new Date().getTime();
    let payload = {
      date : ""+dt
    }
    // console.log(dt);
    //this.getDashboardApi(payload)
  }
  getDashboardApi(payload){
    this.adminService.showLoader.next(true);
    this.adminService.dashboard(payload).subscribe((res) => {
      if (res.response == 3) {
        this.pOpening = res.openStock;
        this.pProduction = res.Production;
        this.pStockOut = res.stockOut;
        this.pclosingStock = res.closingStock;
        this.cOpening =res.collectionopen;
        this.cCollection=res.collection;
        this.cExpenses=res.expensescash;
        this.cClosingBalance =res.collectionclose;
        this.sOpening = res.CustomersOpeningCash;
        this.sSales = res.Sales;
        this.sDiscount = res.Discount;
        this.sCollection = res.collectioncash;
        this.sClosingCash = res.closeBalance
        // //Badges
        // this.depositbadge = res.DepositeApproveNotificationCounts;
        // this.salesbadge = res.salesApproveNotificationCounts;
        // this.productreqbadge = res.RequirementNotificationCounts;
        // this.refresh_btn = false;
        // // 
        // this.CustomersOpeningCash = res.CustomersOpeningCash;
       
        localStorage.setItem("dropdownitems",JSON.stringify(res.DropDownItems[0]));
        this.adminService.showLoader.next(false);
        //console.log(JSON.parse(localStorage.getItem('dropdownitems')));
        // //this.recivedCash = res.
        // console.log("data",res.openStock,res.Production,res.stockOut,res.closingStock,this.depositbadge,this.salesbadge, this.productreqbadge);
        // // this.openSnackBar(res.message, "")       
      }
      else {
        this.adminService.showLoader.next(false);
        this.openSnackBar(res.message, "")        
      }
    }, (err: HttpErrorResponse) => {
      this.adminService.showLoader.next(false);     
      if (err.error instanceof Error) {
        console.warn("CSError", err.error)
      } else {
        console.warn("SSError", err.error)
      }
    })
  }

   //message alerts showing
   openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }

}
