import { Component, OnInit,Inject } from '@angular/core';
import { B2BComponent } from '../b2-b/b2-b.component';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatSnackBar,
  MatDialog,
} from "@angular/material";

@Component({
  selector: 'app-b2-bdelete',
  templateUrl: './b2-bdelete.component.html',
  styleUrls: ['./b2-bdelete.component.css']
})
export class B2BdeleteComponent implements OnInit {
  clientInfo : any;
  constructor(
    private dialogRef: MatDialogRef<B2BComponent>,

    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.clientInfo = this.data;
    //console.log(this.clientInfo)
  }

  ondelete() {
    let obj ={
      B2bID : this.clientInfo.B2bID,
      isDelete : false
    }
    this.dialogRef.close(obj);
  
  }
  onSubmit() {
    let obj ={
      B2bID : this.clientInfo.B2bID,
      isDelete : true
    }
    this.dialogRef.close(obj);
    
  }

}
