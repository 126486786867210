import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormGroupDirective } from "@angular/forms";
import { AdminService } from '../../admin.service';
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatDialog } from "@angular/material";
import { BranchDeleteComponent } from "../branch-delete/branch-delete.component";

@Component({
  selector: 'app-add-branch',
  templateUrl: './add-branch.component.html',
  styleUrls: ['./add-branch.component.css']
})
export class AddBranchComponent implements OnInit {
  addBranchForm: FormGroup;
  allbranchInfo : any;
  loading: boolean = false;
  isUpdated  : boolean = false;
  BranchID : string ="";
  user : any;
  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private adminservice: AdminService
  ) { }

  ngOnInit() {
    this.addBranchForm = this.fb.group({
      branchName: ["", Validators.required],
      userID : ["", Validators.required]
    });
    this.user = JSON.parse(sessionStorage.getItem('user'));
    this.addBranchForm.patchValue({
      userID : this.user.userID
    })
    this.getAllBranch()
  }

  DeleteBranchInfo(brabch){
    // console.log(brabch);
    let dailogRef = this.dialog.open(BranchDeleteComponent, {
      panelClass: "col-md-6",
      hasBackdrop: true,
      disableClose: true,
      data : {bname : brabch.branchName, bid : brabch.branchID, msg:"Branch" }
    });
    dailogRef.afterClosed().subscribe((res) => {
      if(res.isDelete){
        let payload = {
          branchID: res.bid,
          userID : this.user.userID
        }
        this.adminservice.DeleteBranch(payload).subscribe(
          (posRes) => {
           // console.log(posRes);
            if (posRes.response == 3) {
              this.loading = false;
              this.openSnackBar(posRes.message, "");
              this.getAllBranch();
            } else {
              this.loading = false;
              this.openSnackBar(posRes.message, "");
            }
          },
          (err: HttpErrorResponse) => {
            this.loading = false;
            // console.log("Set Profile", err);
            if (err.error instanceof Error) {
              this.openSnackBar(err.message, "Client Error");
              console.log(err.message);
            } else {
              this.openSnackBar(err.message, "Server Error");
              console.log(err.message);
            }
          })
      }else{

      }
    })
  }
  viewBranchInfo(binfo){
    this.isUpdated = true;
    this.BranchID = binfo.branchID;
    this.addBranchForm.patchValue({
      branchName : binfo.branchName
    })
  }

  AddNewBranch(){
    this.loading = true;
    let payload = { ...this.addBranchForm.value}
   // console.log(payload);
    if(this.isUpdated){
      this.loading = false;
      this.isUpdated = false;
      payload.branchID = this.BranchID;
      this.adminservice.UpdateBranch(payload).subscribe(
        (posRes) => {
         // console.log(posRes);
          if (posRes.response == 3) {
            this.loading = false;
            this.openSnackBar(posRes.message, "");
            this.getAllBranch();
            this.addBranchForm.patchValue({
              branchName : ""
            })
          } else {
            this.loading = false;
            this.openSnackBar(posRes.message, "");
          }
        },
        (err: HttpErrorResponse) => {
          this.loading = false;
          // console.log("Set Profile", err);
          if (err.error instanceof Error) {
            this.openSnackBar(err.message, "Client Error");
            console.log(err.message);
          } else {
            this.openSnackBar(err.message, "Server Error");
            console.log(err.message);
          }
        })
    }else{
      this.adminservice.InsertBranch(payload).subscribe(
        (posRes) => {
          //console.log(posRes);
          if (posRes.response == 3) {
            this.loading = false;
            this.openSnackBar(posRes.message, "");
            this.addBranchForm.patchValue({
              branchName : ""
            })
            this.getAllBranch();
          } else {
            this.loading = false;
            this.openSnackBar(posRes.message, "");
          }
        },
        (err: HttpErrorResponse) => {
          this.loading = false;
          // console.log("Set Profile", err);
          if (err.error instanceof Error) {
            this.openSnackBar(err.message, "Client Error");
            console.log(err.message);
          } else {
            this.openSnackBar(err.message, "Server Error");
            console.log(err.message);
          }
        })
    }
  }

  getAllBranch(){
    this.adminservice.getAllbranch().subscribe(
      (posRes) => {
       // console.log(posRes);
        if (posRes.response == 3) {
         this.allbranchInfo = posRes.branchList;
        } else {
          this.openSnackBar(posRes.message, "");
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
  }

   //message alerts showing
   openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
      verticalPosition: "top",
    });
  }

}
