import { Component, OnInit,Inject } from '@angular/core';
import { ProcurementsComponent } from '../procurements/procurements.component';

import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatSnackBar,
  MatDialog,
} from "@angular/material";

@Component({
  selector: 'app-procurementdelete',
  templateUrl: './procurementdelete.component.html',
  styleUrls: ['./procurementdelete.component.css']
})
export class ProcurementdeleteComponent implements OnInit {

  clientInfo : any;
  constructor(
    private dialogRef: MatDialogRef<ProcurementsComponent>,

    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.clientInfo = this.data;
    console.log(this.clientInfo)
  }

  ondelete() {
    let obj ={
      procurementID : this.clientInfo.procurementID,
      isDelete : false
    }
    this.dialogRef.close(obj);
  
  }
  onSubmit() {
    let obj ={
      procurementID : this.clientInfo.procurementID,
      isDelete : true
    }
    this.dialogRef.close(obj);
    
  }


}
