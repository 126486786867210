import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AddClintsComponent } from '../add-clints/add-clints.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../admin.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DeleteBgpersonsComponent } from '../delete-bgpersons/delete-bgpersons.component';
import { Router } from '@angular/router';
import { UpdateClientComponent } from '../update-client/update-client.component';

@Component({
  selector: 'app-all-clients',
  templateUrl: './all-clients.component.html',
  styleUrls: ['./all-clients.component.css']
})
export class AllClientsComponent implements OnInit {
  searchByNameForm: FormGroup;
  clientObj: any = null;
  pageNo: number = 1;
  isSearchByName: boolean = false;
  filterdClients: Array<any> = [];
  customerSubscribe: any;
  message: string = "Finding Clients..";
  clients: Array<any> = [];
  totalPageCount: number = 1;
  pageNumbers: Array<any> = [];
  isTotalCountReached: boolean = false;
  isFetchingClients: boolean = false;
  baseUrl: string = "";
  isSuperAdmin: boolean = false;
  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private adminService: AdminService,
    private snackBar: MatSnackBar,
    private router: Router
  ) { }

  ngOnInit() {
    this.baseUrl = "https://dev-api.bhavgroup.com"
    this.clientObj = {
      type: "4",
      pageNo: "" + this.pageNo,
      size: "15",
    }
    this.searchByNameForm = this.fb.group({
      searchText: ["", Validators.required],
      type: ["4"],
      pageNo: ["1"],
      size: ["15"]
    })
    let adminType = sessionStorage.getItem('isMainAdmin');
    this.isSuperAdmin = adminType == "1" ? false : true;
    this.fetchAllClients();
  }

  clientview(client) {
    this.router.navigate(['/admin/viewClientInfo', btoa(JSON.stringify(client.userID))])
  }


  fetchAllClients() {
    this.clientObj.pageNo = "" + this.pageNo;
    this.isSearchByName = false;
    this.filterdClients = [];
    this.pageNumbers = [];
    this.adminService.showLoader.next(true);
    //console.log(this.clientObj);
    this.customerSubscribe = this.adminService.fetchAllClients(this.clientObj).subscribe((posRes) => {
     // console.log("All Users", posRes);
      this.message = "No Clients Found.."
      this.adminService.showLoader.next(false);
      if (posRes.response == 3) {
        this.clients = posRes.info;
        this.totalPageCount = posRes.pages;
        for (let i: number = 0; i < this.totalPageCount; i++) {
          this.pageNumbers.push(i + 1);
        }
        if (this.totalPageCount <= this.pageNo) {
          this.isTotalCountReached = true;
        } else {
          this.isTotalCountReached = false;
        }
        // let parentElm = document.getElementById('main-wrap');
        //let  currentScrollPositio = parentElm.pageYOffset;
        //  let currentScrollPosition = parentElm.scrollTop;
        //// console.log("Yposition",currentScrollPosition);
        // this.filterdCustomers = this.filterdCustomers.concat(this.customers);
        this.filterdClients = this.clients
        this.isFetchingClients = false;
        //  parentElm.scrollTop = currentScrollPosition;
        // parentElm.scrollTop = 0;
      } else {
        this.openSnackBar(posRes.message, "");
        this.pageNumbers.push(1);
        this.totalPageCount = 0;
        this.isTotalCountReached = true;
        this.pageNo = 1;
        this.isFetchingClients = false;
      }
    }, (err: HttpErrorResponse) => {
      this.message = "No clients Found.."
      this.openSnackBar(err.message, "")
      this.pageNo = this.pageNo - 1;
      this.isFetchingClients = false;
      this.adminService.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })

  }
  searchByName() {
    if (this.searchByNameForm.valid) {
      this.filterdClients = []
      this.pageNo = 1;
      this.isSearchByName = true;
      this.getClientsSearchByName();
    } else {
      this.fetchAllClients();
      //this.openSnackBar("Enter First Name..", "")
    }
  }

  getClientsSearchByName() {
    this.adminService.showLoader.next(true);
    this.message = 'Finding Clients..'
    this.isFetchingClients = true;
    this.searchByNameForm.patchValue({
      pageNo: "" + this.pageNo
    })
    this.isSearchByName = true;
    //let token = sessionStorage.getItem('token');
    let payload = { ...this.searchByNameForm.value };
    // payload.type="Users";
    //console.log(this.searchByNameForm.value);
    this.adminService.searchByName(payload).subscribe((posRes) => {
      // console.log("All Users", posRes);
      this.pageNumbers = [];
      this.adminService.showLoader.next(false);
      this.message = "No Clients Found.."
      if (posRes.response == 3) {
        this.clients = posRes.FetchData;
        if (this.clients.length > 0) {
          this.totalPageCount = posRes.pages;
          for (let i: number = 0; i < this.totalPageCount; i++) {
            this.pageNumbers.push(i + 1);
          }
          if (this.totalPageCount <= this.pageNo) {
            this.isTotalCountReached = true;
          }
          else {
            this.isTotalCountReached = false;
          }
          this.filterdClients = this.clients;
          // this.filterdCustomers = this.filterdCustomers.concat(this.customers);
          this.isFetchingClients = false;
        } else {
          this.totalPageCount = 1;
          this.filterdClients = this.clients;
          this.isTotalCountReached = true;
        }


      } else {
        this.openSnackBar(posRes.message, "")
        this.pageNo = this.pageNo - 1;
        this.isFetchingClients = false;
      }
    }, (err: HttpErrorResponse) => {
      this.message = "No Clients Found.."
      this.openSnackBar(err.message, "")
      this.pageNo = this.pageNo - 1;
      this.isFetchingClients = false;
      this.adminService.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }

  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }


  clientInsert() {
    let dialogRef = this.dialog.open(AddClintsComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        // this.filterdCustomers = [];
        // this.customerObj = res;
        // console.log(this.customerObj);
        this.pageNo = 1;
        this.fetchAllClients();
      }
    })
  }
  EditClientInfo(client) {
    let dialogRef = this.dialog.open(UpdateClientComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true,
      data: client
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        // this.filterdCustomers = [];
        // this.customerObj = res;
        // console.log(this.customerObj);
        this.fetchAllClients();
      }
    })
  }
  DeleteClientInfo(client) {
    let dialogRef = this.dialog.open(DeleteBgpersonsComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true,
      data: client
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res.isDelete) {
        this.deleteAccountUser(res);
        //this.fetchAllClients();
      }
    })
  }
  deleteAccountUser(res) {
    let payload = {
      userID: res.userID
    }
    this.adminService.DeleteByUser(payload).subscribe((posRes) => {
      // console.log("All Users", posRes);
      if (posRes.response == 3) {
        if (this.isSearchByName) {
          this.searchByName();
        } else {
          this.fetchAllClients();
        }
      } else {
        this.openSnackBar(posRes.message, "");
      }
    }, (err: HttpErrorResponse) => {

      this.openSnackBar(err.message, "");
      this.adminService.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }
  gotoSelectedPage(num) {
    this.pageNo = num;
    if (!this.isSearchByName) {
      this.fetchAllClients();
    } else {
      this.getClientsSearchByName();
      // this.ProfileNotUpdateUserNext_prev()
    }
  }
  previousPage() {
    this.pageNo = this.pageNo - 1;
    if (this.totalPageCount <= this.pageNo) {
      this.isTotalCountReached = true;
    } else {
      this.isTotalCountReached = false;
    }
    if (!this.isSearchByName) {
      this.fetchAllClients();
    } else {
      this.getClientsSearchByName();
      // this.ProfileNotUpdateUserNext_prev();
    }

  }
  loadMore() {
    this.pageNo = this.pageNo + 1;
    if (this.totalPageCount <= this.pageNo) {
      this.isTotalCountReached = true;
    } else {
      this.isTotalCountReached = false;
    }
    if (!this.isSearchByName) {
      this.fetchAllClients()
    } else {
      this.getClientsSearchByName();
      // this.getUsersByName()
    }
  }

  ngOnDestroy() {
    this.customerSubscribe.unsubscribe();
  }

}
