import { Component, OnInit,Inject } from '@angular/core';
import { AllClientsComponent } from '../all-clients/all-clients.component';
import { ExpensesComponent } from '../expenses/expenses.component';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatSnackBar,
  MatDialog,
} from "@angular/material";

@Component({
  selector: 'app-delete-bgpersons',
  templateUrl: './delete-bgpersons.component.html',
  styleUrls: ['./delete-bgpersons.component.css']
})
export class DeleteBgpersonsComponent implements OnInit {

  clientInfo : any;
  constructor(
    private dialogRef: MatDialogRef<AllClientsComponent>,

    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.clientInfo = this.data;
  }

  close() {
    let obj ={
      userID : this.clientInfo.userID,
      isDelete : false
    }
    this.dialogRef.close(obj);
  }
  submit() {
    let obj ={
      userID : this.clientInfo.userID,
      isDelete : true
    }
    this.dialogRef.close(obj);
  }
}
