import { Component, OnInit,Inject, ChangeDetectorRef } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  FormGroupDirective,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatSnackBar,
  MatDialog,
} from "@angular/material";
import { AllSalespersonsComponent } from '../all-salespersons/all-salespersons.component';
import { AdminService } from '../../admin.service';
import { HttpErrorResponse, HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-update-sales-person',
  templateUrl: './update-sales-person.component.html',
  styleUrls: ['./update-sales-person.component.css']
})
export class UpdateSalesPersonComponent implements OnInit {
  salespersonProfileForm: FormGroup;
  previewUrl: any = "https://dev-api.bhavgroup.com/images/user.png";
  loading: boolean = false;
  suceessDataInfo: string = "";
  hide = true;
  allbranchInfo: any;
  AllBranchNames: any[];
  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private dialogRef: MatDialogRef<AllSalespersonsComponent>,
    private cd: ChangeDetectorRef,
    private adminservice: AdminService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.getBranches();
    this.salespersonProfileForm = this.fb.group({
      profilePic: [""],
      userID: ["", Validators.required],
      customerName: ["", Validators.required],
      PhoneNumber: ["", [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]],
      Address: ["", Validators.required],
      branch: ["", Validators.required]
    });
    console.log(this.data);
    if (this.data.userID) {
      this.updateSalesPersonValues();
    }
  }

  //File Upload

  fileProgress(event) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.previewUrl = reader.result;
        this.salespersonProfileForm.get("profilePic").setValue(file);
      };
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }
  updateSalesPersonValues() {
    let clientInfo = this.data;
    if (clientInfo.profilePic !== "") {
      this.previewUrl = "https://dev-api.bhavgroup.com" + this.data.profilePic;
      // console.log(this.data.NDAAttachment);
      this.http.get("https://dev-api.bhavgroup.com" + this.data.profilePic, {
        responseType: "blob",
      }).subscribe((file) => {
        let filename = "https://dev-api.bhavgroup.com" + this.data.profilePic;
        let spfname1 = this.data.profilePic.substring(this.data.profilePic.lastIndexOf("/") + 1);
        // let docName1 = spfname1.substring(spfname1.lastIndexOf("_")+1);
        let imgFile = new File([file], spfname1);
        this.salespersonProfileForm.get("profilePic").setValue(imgFile);
        // this.docsList = imgFile;

        // let spfname = this.data.profilePic.substring(this.data.profilePic.lastIndexOf("/") + 1);
        // this.docName = spfname.substring(spfname.lastIndexOf("_")+1);
      })
    }

    this.salespersonProfileForm.patchValue({
      userID: clientInfo.userID,
      customerName: clientInfo.customerName,
      PhoneNumber: clientInfo.PhoneNumber,
      Address: clientInfo.Address,
      branch:clientInfo.branch
    })
  }
  getBranches() {
    this.adminservice.getAllbranch().subscribe(
      (posRes) => {
        console.log(posRes);
        if (posRes.response == 3) {
          this.allbranchInfo = posRes.branchList;
          let pInfo = [];
          this.allbranchInfo.filter(function (el) {
            pInfo.push(el.branchName);
          })
          this.AllBranchNames = pInfo;
        } else {
          this.openSnackBar(posRes.message, "");
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
  }

  closeTab() {
    this.dialogRef.close();
  }
  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
      verticalPosition: "top",
    });
  }
  numericOnly(event) {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  AddSalesPerson() {
    this.loading = true;
    let payLoad = { ...this.salespersonProfileForm.value };
    payLoad.RegisterType = 3;
    delete payLoad.profilePic;
    console.log(payLoad);
    // if (this.salespersonProfileForm.value.profilePic != "") {
    let formData = new FormData();
    formData.append("image", this.salespersonProfileForm.get("profilePic").value);
    formData.append("userInfo", JSON.stringify(payLoad));

    this.adminservice.UpdateClient(formData).subscribe(
      (posRes) => {
        console.log(posRes);
        if (posRes.response == 3) {
          this.loading = false;
          this.openSnackBar(posRes.message, "");
          this.dialogRef.close(true);
          this.suceessDataInfo = "Production Person updated Sucess Fully";
        } else {
          this.loading = false;
          this.openSnackBar(posRes.message, "");
          console.log(posRes);
          // this.dialogRef.close(false);
        }
      },
      (err: HttpErrorResponse) => {
        this.loading = false;
        // console.log("Set Profile", err);
        if (err.error instanceof Error) {
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
    // }else{
    //   this.openSnackBar("please Upload Profile pic","");
    //   this.loading=false;
    // }

  }

}
