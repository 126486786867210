import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatDialog } from "@angular/material";
import { FormGroup, FormBuilder, Validators, AbstractControl, FormGroupDirective } from "@angular/forms";
import { ProductsComponent } from '../products/products.component';
import { AdminService } from '../../admin.service';
import { HttpErrorResponse, HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-dispatch-approve',
  templateUrl: './dispatch-approve.component.html',
  styleUrls: ['./dispatch-approve.component.css']
})
export class DispatchApproveComponent implements OnInit {
  Dispathcapp: Array<any> = [];
  fetchdepost: Array<any> = [];
  Adminstatus: boolean = false;

  constructor(
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private adminservice: AdminService
  ) { }

  ngOnInit() {
    this.getDepositData();
  }

  getDepositData() {
    this.adminservice.fetchtransferamount().subscribe(
      (posRes) => {
        console.log(posRes);
        if (posRes.response == 3) {         
          let bookingsData = posRes.Info;
          let filterData = [];
          bookingsData.filter(word => {
            if (!word.Adminstatus)
              filterData.push(word)
          })
          this.Dispathcapp = filterData;
          console.log("data", this.Dispathcapp);
          // this.openSnackBar(posRes.message, "");
        } else {
          this.openSnackBar(posRes.message, "");
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
  }
  updateDisposit(dispatch){
    let payLoad ={
      "transferAmountID" : dispatch.transferAmountID,
      "adminstatus" : true
    }
    this.adminservice.updateDipositStatus(payLoad).subscribe(
      (posRes) => {
        console.log(posRes);
        if (posRes.response == 3) {
          this.openSnackBar(posRes.message, "");
          this.getDepositData();
        } else {
          this.openSnackBar(posRes.message, "");
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
  }
  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
      verticalPosition: "top",
    });
  }

}
