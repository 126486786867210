import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from "@angular/forms";
import { AdminService } from '../../admin.service';
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import { MatSnackBar } from "@angular/material";
import { Router } from '@angular/router';
@Component({
  selector: 'app-add-brand-info',
  templateUrl: './add-brand-info.component.html',
  styleUrls: ['./add-brand-info.component.css']
})
export class AddBrandInfoComponent implements OnInit {
  previewUrl: any;
  addBrandForm: FormGroup;
  submitted: boolean = false;
  AllProductInfo: Array<any> = [];
  AllProductNames = [];
  loading: boolean = false;
  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private adminservice: AdminService,
    private snackBar: MatSnackBar,
    private router:Router,
  ) { }

  ngOnInit() {
    this.previewUrl = "../../../assets/logo.png";
    this.addBrandForm = this.fb.group({
      brandImage: [""],
      brandname: ["", Validators.required],
      productName: ["", Validators.required],
      productPrice: ["", Validators.required],
      sprit: ["", Validators.required],
      bottles: ["", Validators.required],
      flavors: ["", Validators.required],
      caramel: ["", Validators.required],
      caps: ["", Validators.required],
      labels: ["", Validators.required],
      emptybox: ["", Validators.required],
      particans: ["", Validators.required]
    })
    this.getAllBrands();
  }
  getAllBrands() {
    this.adminservice.getproducts().subscribe(
      (posRes) => {
        //console.log(posRes);
        if (posRes.response == 3) {
          this.AllProductInfo = posRes.Info;
          let pInfo = [];
          this.AllProductInfo.filter(function (el) {
            let obj = {
              "BrandName": el.BrandName,
              "Brandimage": el.Brandimage
            }
            pInfo.push(obj);
          })
          this.AllProductNames = pInfo;
          // this.openSnackBar(posRes.message, "");
        } else {
          this.openSnackBar(posRes.message, "");
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
  }

  get f(): { [key: string]: AbstractControl } {
    return this.addBrandForm.controls;
  }
  //File Upload
  fileProgress(event) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);
      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.previewUrl = reader.result;
        this.addBrandForm.get("brandImage").setValue(file);
        // this.isUploadShow = true;
        // this.isFileUploaded = true;
      };
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }
  onSubmit() {
    this.submitted = true;
    this.loading = true;
    let payLoad = { ...this.addBrandForm.value };
   // console.log(payLoad);
    let apiPayLoad = {
      "BrandName": payLoad.brandname,
      "ProductName": payLoad.productName,
      "Price": Number(payLoad.productPrice),
      "spritinliters": Number(payLoad.sprit),
      "Bottles": Number(payLoad.bottles),
      "Flavours": Number(payLoad.flavors),
      "Caramel": Number(payLoad.caramel),
      "Caps": Number(payLoad.caps),
      "lables": Number(payLoad.labels),
      "EmptyBox": Number(payLoad.emptybox),
      "Particans": Number(payLoad.particans)
    }
    if (this.addBrandForm.valid) {
      if (this.addBrandForm.value.brandImage != "") {
        let formData = new FormData();
        formData.append("productimage", this.addBrandForm.get("brandImage").value);
        formData.append("productInfo", JSON.stringify(apiPayLoad));
        this.adminservice.InsertProduct(formData).subscribe(
          (posRes) => {
           // console.log(posRes);
            if (posRes.response == 3) {
              this.loading = false;
              this.submitted = false;
              this.openSnackBar(posRes.message, "");
              this.router.navigateByUrl('admin/products')
            } else {
              this.loading = false;
              this.submitted = false;
              this.openSnackBar(posRes.message, "");
            }
          },
          (err: HttpErrorResponse) => {
            this.loading = false;
            // console.log("Set Profile", err);
            if (err.error instanceof Error) {
              this.openSnackBar(err.message, "Client Error");
              console.log(err.message);
            } else {
              this.openSnackBar(err.message, "Server Error");
              console.log(err.message);
            }
          })
      } else {
        this.openSnackBar("Please upload Product Image ", "");
        this.submitted = false;
        this.loading = false;
      }
    } else {
      this.submitted = false;
      this.loading = false;
     // console.log(JSON.stringify(this.addBrandForm.value))
    }
  }
  numericOnly(event) {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }
  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
      verticalPosition: "top",
    });
  }
}
