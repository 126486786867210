import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-demo4',
  templateUrl: './admin-demo4.component.html',
  styleUrls: ['./admin-demo4.component.css']
})
export class AdminDemo4Component implements OnInit {
  isMainAdmin: boolean = false;
  isDropdownOpen = false;
  isDropdownOpen1 =false;
  showMenu = false;
  isSideNavOpen: boolean = false;
  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    let token = sessionStorage.getItem('token');
    if (token == null) {
      // // console.log("Token",token);
      this.router.navigateByUrl('/login');
    };
    let adminType = sessionStorage.getItem('isMainAdmin');
   // console.log(adminType);
   // console.log(sessionStorage.getItem('isMainAdmin'));
    this.isMainAdmin = adminType == "1" ? true : false;
  }
  toggleDropdown() {
    this.isDropdownOpen1 = false;
    this.isDropdownOpen = !this.isDropdownOpen;
  }
  toggleDropdown1(){
    this.isDropdownOpen = false;
    this.isDropdownOpen1 = !this.isDropdownOpen1;
  }
  AddAdmin() {
    this.router.navigateByUrl('/admin/AddAdmin');
  }
  reports() {
    this.router.navigateByUrl('/BGReports');
  }
  
  toggleMenu() {
    this.showMenu = !this.showMenu;
  }
  openSideNav() {
    this.isSideNavOpen = !this.isSideNavOpen;
  }
 
}
