// import { ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef } from '@angular/core';
// import { AdminService } from './admin.service';
// import { BnNgIdleService } from 'bn-ng-idle';
// import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
// import { MatSidenav } from "@angular/material";

// @Component({
//   selector: 'app-root',
//   templateUrl: './app.component.html',
//   styleUrls: ['./app.component.css']
// })
// export class AppComponent implements OnInit {
//   title = 'BHAV_GROUP_Admin';
//   loading: boolean = false;
//   isLoggedIn = false;



//   @ViewChild("sidenav", { static: true }) sideNav: MatSidenav;
//   @ViewChild("sideNavContainer", { static: true }) sideNavContainer: ElementRef;





//   constructor(private adminService: AdminService,
//     private activatedRoute: ActivatedRoute,
//     private cd: ChangeDetectorRef,
//     private bnIdle: BnNgIdleService,
//     private router: Router) {
//     this.adminService.showLoader.subscribe((flag: boolean) => {
//       if (this.loading !== flag) {
//         this.loading = flag;
//       }
//     });
//     this.adminService.checkIsLoggedIn.subscribe((val) => {
//       if (val == true) {
//         this.isLoggedIn = true;
//       } else {
//         this.isLoggedIn = false;
//       }
//     });
//     this.router.events.subscribe((evt) => {
//       if (!(evt instanceof NavigationEnd)) {
//         return;
//       }
//       window.scrollTo(0, 0);
//     });
//     window.onscroll = () => {
//       this.scrollCheck();
//     };
//   }
//   ngOnInit() {
//     console.log("second");
//     this.bnIdle.startWatching(60 * 60).subscribe((isTimedOut: boolean) => {
//       if (isTimedOut) {
//         if ((this.router.url != '/login')) {
//           sessionStorage.clear();
//           this.router.navigateByUrl('/login');
//         }
//       }
//     });
//   }
//   closedStart() {
//     document.body.style.overflow = "auto";
//   }
//   initializeSmartAppBanner() { }
//   openedStart() {
//     document.body.style.overflow = "hidden";
//   }
//   toggleSideNav() {
//     this.sideNav.toggle();
//     setTimeout(() => {
//       // const sideNavContainer = document.getElementById('sideNavContainer');
//       // if (sideNavContainer && sideNavContainer.classList.contains('mat-drawer-container-has-open')) {
//       //   document.body.style.overflow = 'hidden';
//       // } else {
//       //   document.body.style.overflow = 'auto';
//       // }
//     }, 2000);
//   }
//   scrollCheck() {
//     let mybutton = document.getElementById("myBtn");
//     if (
//       document.body.scrollTop > 300 ||
//       document.documentElement.scrollTop > 300
//     ) {
//       mybutton.style.opacity = "1";
//     } else {
//       mybutton.style.opacity = "0";
//     }
//   }
//   ngAfterContentChecked(): void {
//     // this.loading = true;
//     this.cd.detectChanges();
//   }
//   }
import { ChangeDetectorRef, Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AdminService } from './admin.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MatSidenav } from "@angular/material";
import { MatDialog, MatSnackBar } from "@angular/material";
import { SignoutComponent } from './components/signout/signout.component';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'BHAV_GROUP_Admin';
  loading: boolean = false;
  isLoggedIn = false;
  isUrl: string = "";
  Findurl: boolean = false;

  @ViewChild("sidenav", { static: true }) sideNav: MatSidenav;
  @ViewChild("sideNavContainer", { static: true }) sideNavContainer: ElementRef;





  constructor(private adminService: AdminService,
    private activatedRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private bnIdle: BnNgIdleService,
    private router: Router,
    private dialog: MatDialog ) {
    this.adminService.showLoader.subscribe((flag: boolean) => {
      if (this.loading !== flag) {
        this.loading = flag;
      }
    });
    this.adminService.checkIsLoggedIn.subscribe((val) => {
      if (val == true) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    });
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      if (evt instanceof NavigationEnd) {
        this.isUrl = "" + evt.url;
        if (this.isUrl == '/AllReports' || this.isUrl.match('ViewReports') || this.isUrl.match('AddNewItem')) {
          this.Findurl = true;
        } else {
          this.Findurl = false;
        }
      }
      window.scrollTo(0, 0);
    });
    window.onscroll = () => {
      this.scrollCheck();
    };
  }
  ngOnInit() {
    // console.log("second");
    this.bnIdle.startWatching(60 * 60).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        if ((this.router.url != '/login')) {
          sessionStorage.clear();
          this.router.navigateByUrl('/login');
        }

      }
    });
  }
  ngAfterContentChecked(): void {
    // this.loading = true;
    this.cd.detectChanges();
  }
  closedStart() {
    document.body.style.overflow = "auto";
  }
  initializeSmartAppBanner() { }
  openedStart() {
    document.body.style.overflow = "hidden";
  }
  toggleSideNav() {
    this.sideNav.toggle();
    setTimeout(() => {
      // const sideNavContainer = document.getElementById('sideNavContainer');
      // if (sideNavContainer && sideNavContainer.classList.contains('mat-drawer-container-has-open')) {
      //   document.body.style.overflow = 'hidden';
      // } else {
      //   document.body.style.overflow = 'auto';
      // }
    }, 2000);
  }
  scrollCheck() {
    let mybutton = document.getElementById("myBtn");
    if (
      document.body.scrollTop > 300 ||
      document.documentElement.scrollTop > 300
    ) {
      mybutton.style.opacity = "1";
    } else {
      mybutton.style.opacity = "0";
    }
  }
  scroll() {
    window.scroll(0, 0);
  }
  toggle() {
    this.sideNav.toggle();
  }
  logOut() {
   // this.toggleSideNav();
    //this.adminService.callLogOut.next(true);
    let dailogRef = this.dialog.open(SignoutComponent, {
      panelClass: "col-md-5",
      hasBackdrop: true,
      disableClose: true,
    });
    dailogRef.afterClosed().subscribe((res) => {
      if (res == true) {
        sessionStorage.clear();
        //this.adminService.callLogOut.subscribe(false);
        this.toggleSideNav();
       this.router.navigateByUrl('/login')
      }
    });
  }
}
