import { Component, OnInit,Inject } from '@angular/core';
import { AllClientsComponent } from '../all-clients/all-clients.component';
import { ExpensesComponent } from '../expenses/expenses.component';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatSnackBar,
  MatDialog,
} from "@angular/material";

@Component({
  selector: 'app-expdelete',
  templateUrl: './expdelete.component.html',
  styleUrls: ['./expdelete.component.css']
})
export class ExpdeleteComponent implements OnInit {

  clientInfo : any;
  constructor(
    private dialogRef: MatDialogRef<ExpensesComponent>,

    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.clientInfo = this.data;
    console.log(this.clientInfo)
  }

  ondelete() {
    let obj ={
      userID : this.clientInfo.userID,
      isDelete : false
    }
    this.dialogRef.close(obj);
  
  }
  onSubmit() {
    let obj ={
      userID : this.clientInfo.userID,
      isDelete : true
    }
    this.dialogRef.close(obj);
    
  }

}
