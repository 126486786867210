import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-admin-demo3',
  templateUrl: './admin-demo3.component.html',
  styleUrls: ['./admin-demo3.component.css']
})
export class AdminDemo3Component implements OnInit {
  isMainAdmin: boolean = false;
  showMenu = false;
  isSideNavOpen: boolean = false;
  items: boolean = true;
  itemss: boolean = true;
  imageoff: boolean = true
  SideBarMenu = [
    {
      id: 'dashboard',
      title: 'Dashboard',
      icon: 'icon-grid menu-icon',
      url: '',
    },
    {
      id: 'task',
      title: 'Task',
      icon: 'icon-layout menu-icon',
      url: '',
      subMenu: [
        {
          id: 'task1',
          title: 'Task 1',
          icon: '',
          url: '',
        },
        {
          id: 'task2',
          title: 'Task 2',
          icon: '',
          url: '',
        },
      ],
    },
    {
      id: 'scheduler',
      title: 'Scheduler',
      icon: 'icon-layout menu-icon',
      url: '',
      subMenu: [
        {
          id: 'calendar',
          title: 'Calendar',
          icon: '',
          url: '',
        },
        {
          id: 'scheduler',
          title: 'Scheduler',
          icon: '',
          url: '',
        },
        {
          id: 'orders',
          title: 'Orders',
          icon: '',
          url: '',
        },
        {
          id: 'users',
          title: 'Users',
          icon: '',
          url: '',
        },
        {
          id: 'sales',
          title: 'Sales',
          icon: '',
          url: '',
        },
      ],
    },
  ];
  image: boolean = true;

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    let token = sessionStorage.getItem('token');
    if (token == null) {
      // // console.log("Token",token);
      this.router.navigateByUrl('/login');
    };
    let adminType = sessionStorage.getItem('isMainAdmin');
   // console.log(adminType);
   // console.log(sessionStorage.getItem('isMainAdmin'));
    this.isMainAdmin = adminType == "1" ? true : false;
  }
  workers() {
    this.router.navigateByUrl("/admin/worker")
  }
  client() {
    this.router.navigateByUrl("/admin/Clients")
  }
  Production() {
    this.router.navigateByUrl("/admin/productionperson")
  }
  salesperson() {
    this.router.navigateByUrl("/admin/salesPersons")
  }
  branch() {
    this.router.navigateByUrl("/admin/addnewBranch")
  }

  Warehouse() {
    this.router.navigateByUrl("/admin/warehouse")
  }
  Brand() {
    this.router.navigateByUrl("/admin/addbrand")
  }
  Product() {
    this.router.navigateByUrl("/admin/products")
  }
  Dropdowns() {
    this.router.navigateByUrl("/admin/addnewItem")
  }
  Vendors(){
    this.router.navigateByUrl("/admin/VendorPersons")
  }
  imageclick() {
    this.router.navigateByUrl("/BGReports")
  }

  toggleSubItems(menuItem) {
    menuItem.showSubItems = !menuItem.showSubItems;
  }
  toggleMenu() {
    this.showMenu = !this.showMenu;
  }
  AddAdmin() {
    this.router.navigateByUrl('/admin/AddAdmin');
  }
  openSideNav() {
    this.isSideNavOpen = !this.isSideNavOpen;
  }

  openitems() {
    if (this.items == true) {
      this.itemss = true;
      this.items = false;
      this.image = true;
     // console.log("opened");
    } else {
      this.items = true;


      //console.log("not opened")
    }
  }

  openitemss() {
    if (this.itemss == true) {
      this.items = true;
      this.image = true;
      this.itemss = false;
      //console.log("opened");
    } else {

      this.itemss = true;
     // console.log("not opened")
    }
  }

  onandoffimg() {
    if (this.imageoff == true) {
      this.items = true;
      this.itemss = true;
      this.imageoff = false;
     // console.log("opened");
    } else {

      this.imageoff = true;
      //console.log("not opened")
    }
  }



}
