import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/admin.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IproductsData } from '../../Model/productReport';
import { ItotalProductsInfo } from '../../Model/totalProductInfo';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-all-reports-view',
  templateUrl: './all-reports-view.component.html',
  styleUrls: ['./all-reports-view.component.css'],
  providers: [DatePipe]
})
export class AllReportsViewComponent implements OnInit {
  allReports = ["Ledger/Day Book", "Production, Dispatch, closing stock", "Raw Material", "Sales & Collection", "Cash Flow", "Daily Report", "Monthly Report"]
  selectedReport = "Ledger/Day Book";
  selectReport = "";
  styles = {};
  viewReport: string = "LEDGER";
  apiurl: string = "";
  serverResponse: any;
  isMainAdmin: boolean = false;
  isAdminLoggedIn: boolean = false;
  loading: boolean = false;
  isSideNavOpen: boolean = false;
  //LEDGER REPORT VARIABLES
  ledgerReport = [];
  ledgerExpenseData: any;
  ledgerCollectionData: any;
  finalLedgerReport: any;
  selectedOption: string = "";
  ledgerDateRange: string;
  ledgertodayForm: FormGroup;
  maxDate1: any = new Date();
  FinalledgerArray = [];
  dropdownItems: any;
  dropdownExpense: any;
  selectedUpdateExpenseItem: string;
  ExpenseitemForm: FormGroup;
  lastdayCloseData : number;

  //sales & collection params
  Collection_SalesData: any;
  salesClientReport: boolean = false;
  salesBranchReport: any;
  clientBalanceInfo: any;
  salesClientName: string;
  salesClientID: string = "";
  allSalesMonthView = "thisMonth";

  // Product and Dispatch closing stock params
  AllBandsList = [];
  productionRepots = [];
  dispatchReports = [];
  closingReports = [];
  warehouseReports = [];
  TotalBrandReports = [];
  selectedbrand: string;
  user: any;
  selectProduct = "";
  BrandName: string = "";
  colspandata: number;
  productnames = [];
  allProductInfo: any;
  TotalProductInfo: any;
  brandProductToatlInfo: ItotalProductsInfo[] = []
  selectedMonthDate : number;
  pdcdivwidth :string = "1100";

  // rawmaterial report params

  rawmaterialsdatainfo: any;

  // cash flow
  totalitems = []
  cashflowinfo = [];
  cashflownames = [];
  cash = [];
  cashflowname = [];
  cashFlowDate : Date;
  TotalAmt = [];


  //Daily Reports params
  dialyReportobj: any
  dailycloseBalance: number =0;
  gtotdayexpenses: number =0;
  gtotdayrawmat: number =0;
  dayCloseCredits: number=0;
  daytotalExpense: number=0;
  dailysalesValue : number =0;

  //Monthly Report parmas
  todayDate: Date;
  monthReportobj: any;
  monthcloseBalance: number =0;
  gtotmonthexpenses: number =0;
  gtotmonthrawmat: number =0;
  monthCloseCredits: number=0;
  monthtotalExpense : number=0;
  monthRawMExpense : boolean = false;
  monthsalesValue : number =0;


  constructor(
    private adminservice: AdminService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private router: Router,
    private datePipe: DatePipe
  ) {
    this.adminservice.isAdminLoggedIn.subscribe((val) => {
      if (val === false) {
        this.openLogindialog();
      }
    });
  }

  ngOnInit() {
    this.getExpenseItems();
    this.ledgertodayForm = this.fb.group({
      selectedOption: ["", Validators.required],
    })
    this.adminservice.isAdminLoggedIn.next(true);
    this.displayReports("Ledger/Day Book")
  }
  openLogindialog() {
    this.router.navigateByUrl('/login')
  }
  displayReports(report) {
    this.selectedReport = report;
    switch (report) {
      case "Ledger/Day Book":
        this.viewReport = "LEDGER";
        report = "reportledgerbookapi?";
        // var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        // var firstDay = new Date(y, m, 1).setHours(0, 0, 0, 0);
        // var lastDay = new Date(y, m + 1, 0).setHours(23, 59, 59, 999);
        //firstDay = date.getTime();
        //  this.apiurl = report + "date=" + firstDay + "," + lastDay + "&downloadReport=false";
        // console.log(this.apiurl);
        // console.log(new Date(y, m + 1, 0).getDate());
        var date = new Date();
        let ledgerDay1 = date.getTime();
        this.apiurl = report + "date=" + ledgerDay1 + "&downloadReport=false";
        this.getLedgerReport();
        break;
      case "Sales & Collection":
        this.viewReport = "Sales";
        report = "totalsalesandcollectionReport?"
        var date = new Date();
        let firstDay1 = date.getTime();
        this.apiurl = report + "date=" + firstDay1 + "&downloadReport=false";
        this.salesCollectionReport();
        break;
      case "Production, Dispatch, closing stock":
        this.viewReport = "PRODU";
        report = "ProductionandDispatchReport?"
        this.apiurl = report + "&downloadReport=false";
        this.productionAndDispatchReport();
        break;
      case "Raw Material":
        this.viewReport = "RawMaterial";
        var rmdt = new Date().getTime()
        report = "rawmaterialsReport?"
        this.apiurl = report + "date=" + rmdt + "&downloadReport=false";
        this.RawMaterialReport();
        break;
      case "Cash Flow":
        this.viewReport = "CashFlow";
        report = "cashflowreport?"
        this.cashFlowDate = new Date();
        var date = new Date();
        let firstDay2 = date.getTime();
        this.apiurl = report + "date=" + firstDay2;
        this.cashFlowReport();
        break;
      case "Daily Report":
        this.viewReport = "DailyReport";
        report = "monthreport?"
        this.todayDate = new Date();
        this.apiurl = report + "date=today";
        this.DailyReportData();
        break;
      case "Monthly Report":
        this.viewReport = "MonthlyReport";
        report = "monthreport?"
        this.todayDate = new Date();
        var date = new Date();
        let firstDay3 = date.getTime();
        this.apiurl = report + "date=" + firstDay3;
        this.MonthlyReportData();
        break;
    }

  }

  // Ledger Day Report methods
  getLedgerReport() {
    this.adminservice.showLoader.next(true);
    this.FinalledgerArray = [];
    this.ledgerExpenseData = [];
   // console.log(this.apiurl);
    this.adminservice.getReportsInfo(this.apiurl).subscribe((posRes) => {
      this.serverResponse = posRes;
      console.log(posRes);
      if (posRes.response == 3) {
        this.adminservice.showLoader.next(false);
        let FinalReport = [];
        //this.FinalledgerArray = posRes.Ledgerdata;

        let allledgerArr = posRes.Ledgerdata;
        let currentDate = new Date();
        let formattedDate = this.datePipe.transform(currentDate, 'dd-MM-yyyy');

        let allledger =[];
        for(var pro of allledgerArr){
           if(""+formattedDate == pro.timestamp){
              allledger.push(pro);
              break
           }else{
            allledger.push(pro);
           }
        }
        this.FinalledgerArray = allledger;
        console.log(this.FinalledgerArray);
        this.lastdayCloseData = 0;
        for (let objValue of this.FinalledgerArray) {
          // console.log(objValue);
          let loop1 = 1;
          if (objValue.Collection.length > 0) {
            objValue.Collection.filter((item) => {
              // console.log(loop1);
              let objdata = {};
              if(loop1 == 1){
                objdata["timestamp"] = item.timestamp;
                loop1++;
              }else{
                objdata["timestamp"] = "";
                loop1++;
              }
              objdata["category"]= "";
              objdata["Description"] = "";
              objdata["Expenses"] = "";
              objdata["userID"] = item.customeruserID;
              objdata["ExpenseID"] = "",
              objdata["Collection"] = item.totalAmount;
              objdata["procument"] = "";
              objdata["procureItem"] =""
              objdata["closingAmount"] = "";
              FinalReport.push(objdata);
            })
          }
          if (objValue.Expenses.length > 0) {
            objValue.Expenses.filter((item) => {
              let objdata = {};
              if(loop1 == 1){
                objdata["timestamp"] = item.timestamp;
                loop1++;
              }else{
                objdata["timestamp"] = "";
                loop1++;
              }
             
              objdata["category"] = item.Expenses;
              objdata["Description"] = item.Description;
              objdata["Expenses"] = item.Price;
              objdata["ExpenseID"] = item.expenseID,
              objdata["userID"] = item.userID;
              objdata["Collection"] = "";
              objdata["procument"] = "";
              objdata["procureItem"] ="";
              objdata["closingAmount"] = "";
              FinalReport.push(objdata);
            })
          }
          if (objValue.Procurement.length > 0) {
            objValue.Procurement.filter((item) => {
              // console.log(loop1);
              let objdata = {};
              if(loop1 == 1){
                objdata["timestamp"] = item.timestamp;
                loop1++;
              }else{
                objdata["timestamp"] = "";
                loop1++;
              }
              objdata["category"]= "";
              objdata["Description"] = "";
              objdata["Expenses"] = "";
              objdata["userID"] = item.userID;
              objdata["ExpenseID"] = "",
              objdata["Collection"] = "";
              objdata["procument"] = item.totalAmount;
              objdata["procureItem"] =item.item;
              objdata["closingAmount"] = "";
              FinalReport.push(objdata);
            })
          }
          let objdataa = {}
          if(loop1 == 1){
            objdataa["timestamp"] = objValue.timestamp;
            loop1++;
          }else{
            objdataa["timestamp"] = "";
            loop1++;
          }
          //objdataa["timestamp"] = objValue.timestamp;
          this.lastdayCloseData = this.lastdayCloseData + objValue.ClosingAmount;
          objdataa["category"]= "";
          objdataa["Description"] = "";
          objdataa["Expenses"] = "";
          objdataa["Collection"] = "";
          objdataa["userID"] = "";
          objdataa["ExpenseID"] = "";
          objdataa["procument"] = "";
          objdataa["procureItem"] ="";
          objdataa["closingAmount"] = this.lastdayCloseData;
         
          FinalReport.push(objdataa);
        }
       // console.log(FinalReport);
        this.ledgerExpenseData = FinalReport;

        // this.ledgerExpenseData = posRes.ExpensesData;
        // this.ledgerCollectionData = posRes.CollectionData;
        // let lexpanse = [];
        // let lcollection = [];
        // this.ledgerExpenseData.filter(el => {
        //   const dt = new Date(Number(el.timestamp));
        //   let dt1 = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate()).getTime()
        //   let obj = {
        //     "Description": el.Description,
        //     "Expenses": el.Expenses,
        //     "Price": el.Price,
        //     "expenseID": el.expenseID,
        //     "userID": el.userID,
        //     "timestamp": dt1
        //   }
        //   lexpanse.push(obj);
        // })
        // this.ledgerCollectionData.filter(el => {
        //   const dt = new Date(Number(el.timestamp));
        //   let dt1 = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate()).getTime()
        //   let obj = {
        //     "collectionID": el.collectionID,
        //     "customerName": el.customerName,
        //     "customeruserID": el.customeruserID,
        //     "totalAmount": el.totalAmount,
        //     "timestamp": dt1
        //   }
        //   lcollection.push(obj);
        // })
        // var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        // var lastDay = new Date(y, m + 1, 0);

        // let lexresult = lexpanse.reduce(function (r, a) {
        //   r[a.timestamp] = r[a.timestamp] || [];
        //   r[a.timestamp].push(a);
        //   return r;
        // }, Object.create(null));

        // let lecollect = lcollection.reduce(function (r, a) {
        //   r[a.timestamp] = r[a.timestamp] || [];
        //   r[a.timestamp].push(a);
        //   return r;
        // }, Object.create(null));

        // let finalArray = [];
        // for (var i in lexresult) {
        //   for (var j in lecollect) {
        //     if (i == j) {
        //       let lexpnse = lexresult[i];
        //       let lcollc = lecollect[j];
        //       let exAmount = 0;
        //       let ecAmount = 0;
        //       for (let ik = 0; ik < lexpnse.length; ik++) {
        //         let obj = {};
        //         obj["Description"] = lexpnse[ik].Description;
        //         obj["timestamp"] = lexpnse[ik].timestamp;
        //         obj["Expenses"] = lexpnse[ik].Expenses;
        //         obj["ExpensesPrice"] = lexpnse[ik].Price;
        //         obj["CollectionPrice"] = "";
        //         obj["closingAmount"] = "";
        //         exAmount = + lexpnse[ik].Price;
        //         finalArray.push(obj);
        //       }
        //       for (let ik = 0; ik < lcollc.length; ik++) {
        //         let obj1 = {};
        //         obj1["Description"] = "";
        //         obj1["timestamp"] = lcollc[ik].timestamp;
        //         obj1["Expenses"] = "";
        //         obj1["ExpensesPrice"] = "";
        //         obj1["CollectionPrice"] = lcollc[ik].totalAmount;
        //         obj1["closingAmount"] = "";
        //         ecAmount = + lcollc[ik].totalAmount;
        //         finalArray.push(obj1);
        //       }
        //       let amt = ecAmount - exAmount;
        //       let obj3 = {
        //         "Description": "",
        //         "timestamp": i,
        //         "Expenses": "",
        //         "ExpensesPrice": "",
        //         "CollectionPrice": "",
        //         "closingAmount": amt
        //       }
        //       finalArray.push(obj3);
        //       this.FinalledgerArray = finalArray;
        //       //console.log(lexresult[i]);
        //       //console.log(lecollect[j]);
        //     }
        //   }
        // }
        //console.log(lexresult);
        //console.log(lecollect);

        // console.log(finalArray);

      } else {
        this.ledgerExpenseData = [];
        this.lastdayCloseData = 0;
        this.ledgerCollectionData = [];
        this.adminservice.showLoader.next(false);
        this.openSnackBar("there is no transactions this Month", "");
      }
    }, (err: HttpErrorResponse) => {
      this.openSnackBar(err.message, "");
      this.adminservice.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }
  getExpenseItems() {
    this.adminservice.showLoader.next(true);
    var date = new Date(), y = date.getFullYear(), m = date.getMonth(), dd = date.getDate();
    let payload = {
      "date": dd + "-" + m + "-" + y
    }
    //console.log(payload);
    this.adminservice.dashboard(payload).subscribe((posRes) => {
      this.serverResponse = posRes;
      if (posRes.response == 3) {
        this.adminservice.showLoader.next(false);
        this.dropdownItems = posRes.DropDownItems;
        this.dropdownExpense = this.dropdownItems[0].Expenses;
        // console.log(this.dropdownExpense);
        // this.Collection_SalesData = posRes.SalesandCollectionData;
        //this.dd = posRes.dd;
        // this.productDispatchData = posRes.data;
        //this.produExpenseData = posRes.data;
      } else {
        this.adminservice.showLoader.next(false);
        this.openSnackBar(posRes.message, "");
      }
    }, (err: HttpErrorResponse) => {
      this.openSnackBar(err.message, "");
      this.adminservice.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }
  updateSelectedLedgerItem(items) {
    // console.log(items);
    let payload = {
      expenseID: items.ExpenseID,
      userID: items.userID,
      Description: items.Description,
      Expenses: items.category,
      Price: "" + items.Expenses
    }
    // console.log(payload);
    this.adminservice.showLoader.next(true);
    this.adminservice.updateexpenses(payload).subscribe((posRes) => {
      this.serverResponse = posRes;
      if (posRes.response == 3) {
        this.adminservice.showLoader.next(false);
        this.getLedgerReport();
      } else {
        this.adminservice.showLoader.next(false);
        this.openSnackBar(posRes.message, "");
      }
    }, (err: HttpErrorResponse) => {
      this.openSnackBar(err.message, "");
      this.adminservice.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
    //console.log(this.selectedUpdateExpenseItem);
  }
  selectLedgerReportDay(event: any) {
    const selectedValue = event.target.value;
    var firstDay;
    var lastDay;
    if (selectedValue == "Today") {
      // const today = new Date();
      // const yesterday = new Date(today.getTime());
      // yesterday.setHours(0, 0, 0, 0);
      // firstDay = yesterday.getTime();
      // yesterday.setHours(23, 59, 59, 999);
      // lastDay = yesterday.getTime();
      let report1 = "reportledgerbookapi?";
     // firstDay = "today"
      this.apiurl = report1 + "date=today&downloadReport=false";
      this.getTodayLedgerReport();
    }
    if (selectedValue == "yester Day") {
      let report1 = "reportledgerbookapi?";
      const today = new Date();
      const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
      yesterday.setHours(0, 0, 0, 0);
      firstDay = yesterday.getTime();
      yesterday.setHours(23, 59, 59, 999);
      lastDay = yesterday.getTime();
      this.apiurl = report1 + "date=" + firstDay + "," + lastDay + "&downloadReport=false";
      this.getLedgerReport();
    }
    if(selectedValue == "ThisMonth"){
      let report1 = "reportledgerbookapi?";
      var date = new Date();
      let ledgerDay1 = date.getTime();
      this.apiurl = report1 + "date=" + ledgerDay1 + "&downloadReport=false";
      this.getLedgerReport();
    }
    if(selectedValue == "LastMonth"){
      let report1 = "reportledgerbookapi?";
      var date = new Date();
      date.setMonth(date.getMonth() - 1);
      let ledgerDay1 = date.getTime();
      this.apiurl = report1 + "date=" + ledgerDay1 + "&downloadReport=false";
      this.getLedgerReport();
    }
    // console.log(firstDay + "  " + lastDay);
    // let report1 = "reportledgerbookapi?";
    // this.apiurl = report1 + "date=" + firstDay + "," + lastDay + "&downloadReport=false";
   // this.getLedgerReport();
    //this.openSnackBar(selectedValue + "Working This Method ..", "")
  }
  getTodayLedgerReport(){
    this.adminservice.showLoader.next(true);
    this.FinalledgerArray = [];
    this.ledgerExpenseData = [];
    //console.log(this.apiurl);
    this.adminservice.getReportsInfo(this.apiurl).subscribe((posRes) => {
      this.serverResponse = posRes;
      // console.log(posRes);
      if (posRes.response == 3) {
        this.adminservice.showLoader.next(false);
        let FinalReport = [];
        this.FinalledgerArray = [posRes.Ledgerdata];
        for (let objValue of this.FinalledgerArray) {
          let loop1 =1;
          if (objValue.Collection.length > 0) {
            objValue.Collection.filter((item) => {
              let objdata = {};
              if(loop1 == 1){
                objdata["timestamp"] = item.timestamp;
                loop1++;
              }else{
                objdata["timestamp"] = "";
                loop1++;
              }
              // objdata["timestamp"] = item.timestamp;
              objdata["category"]= "";
              objdata["Description"] = "";
              objdata["Expenses"] = "";
              objdata["userID"] = item.customeruserID;
              objdata["ExpenseID"] = "",
              objdata["Collection"] = item.totalAmount;
              objdata["procument"] = "";
              objdata["procureItem"] ="";
              objdata["closingAmount"] = "";
              FinalReport.push(objdata);
            })
          }
          if (objValue.Expenses.length > 0) {
            objValue.Expenses.filter((item) => {
              let objdata = {};
              if(loop1 == 1){
                objdata["timestamp"] = item.timestamp;
                loop1++;
              }else{
                objdata["timestamp"] = "";
                loop1++;
              }
              // objdata["timestamp"] = item.timestamp;
              objdata["category"] = item.Expenses;
              objdata["Description"] = item.Description;
              objdata["Expenses"] = item.Price;
              objdata["userID"] = item.userID;
              objdata["Collection"] = "";
              objdata["closingAmount"] = "";
              objdata["procument"] = "";
              objdata["procureItem"] ="";
              objdata["ExpenseID"] = item.expenseID,
              FinalReport.push(objdata);
            })
          }
          if (objValue.Procurement.length > 0) {
            objValue.Procurement.filter((item) => {
              // console.log(loop1);
              let objdata = {};
              if(loop1 == 1){
                objdata["timestamp"] = item.timestamp;
                loop1++;
              }else{
                objdata["timestamp"] = "";
                loop1++;
              }
              objdata["category"]= "";
              objdata["Description"] = "";
              objdata["Expenses"] = "";
              objdata["userID"] = item.userID;
              objdata["ExpenseID"] = "",
              objdata["Collection"] = "";
              objdata["procument"] = item.totalAmount;
              objdata["procureItem"] =item.item;
              objdata["closingAmount"] = "";
              FinalReport.push(objdata);
            })
          }
          let objdataa = {}
          if(loop1 == 1){
            objdataa["timestamp"] = objValue.timestamp;
            loop1++;
          }else{
            objdataa["timestamp"] = "";
            loop1++;
          }
          // objdataa["timestamp"] = objValue.timestamp;
          this.lastdayCloseData = this.lastdayCloseData + objValue.ClosingAmount;
          objdataa["category"]= "";
          objdataa["Description"] = "";
          objdataa["Expenses"] = "";
          objdataa["Collection"] = "";
          objdataa["userID"] = "";
          objdataa["ExpenseID"] = "",
          objdataa["procument"] = "";
          objdataa["procureItem"] ="";
          objdataa["closingAmount"] = this.lastdayCloseData;
          FinalReport.push(objdataa);
        }
        this.ledgerExpenseData = FinalReport;
      } else {
        this.ledgerExpenseData = [];
        this.ledgerCollectionData = [];
        this.lastdayCloseData =0;
        this.adminservice.showLoader.next(false);
        this.openSnackBar("There is No Transaction Today", "");
      }
    }, (err: HttpErrorResponse) => {
      this.openSnackBar(err.message, "");
      this.adminservice.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }
  onLedgerDateRangeChange(event: any) {
    var lfirstDay;
    var llastDay;

    this.ledgerDateRange = event;
    let firstday = new Date(this.ledgerDateRange[0]);
    let lastDay = new Date(this.ledgerDateRange[1]);
    lfirstDay = new Date(firstday.getFullYear(), firstday.getMonth(), firstday.getDate()).setHours(0, 0, 0, 0);
    llastDay = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate()).setHours(23, 59, 59, 999);
    // console.log(lfirstDay + "  " + llastDay);
    let report1 = "reportledgerbookapi?";
    this.apiurl = report1 + "date=" + lfirstDay + "," + llastDay + "&downloadReport=false";
    this.getLedgerReport();
    //this.openSnackBar(this.ledgerDateRange + "It continue to calendor date picker", "")
  }


  // sales & collection Report API and Methods
  backtoSales(){
    this.viewReport = "Sales";
    let report = "totalsalesandcollectionReport?"
    var date = new Date();
    let firstDay1 = date.getTime();
    this.apiurl = report + "date=" + firstDay1 + "&downloadReport=false";
    this.salesCollectionReport();
  }
  salesCollectionReport() {
    this.adminservice.showLoader.next(true);
    this.adminservice.getReportsInfo(this.apiurl).subscribe((posRes) => {
      this.serverResponse = posRes;
      if (posRes.response == 3) {
        this.Collection_SalesData = posRes.SalesandCollectionData;
        this.adminservice.showLoader.next(false);
        // console.log(this.Collection_SalesData);
        //this.dd = posRes.dd;
        // this.productDispatchData = posRes.data;
        //this.produExpenseData = posRes.data;
      } else {
        this.adminservice.showLoader.next(false);
        this.openSnackBar(posRes.message, "");
      }
    }, (err: HttpErrorResponse) => {
      this.openSnackBar(err.message, "");
      this.adminservice.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }
  DisplaySalesBrachReport(exp) {
    this.viewReport = "AllSalesClient";
    this.salesClientReport = false;
    let firstDay1;
    if(this.allSalesMonthView == "thisMonth"){
      var date = new Date();
      firstDay1 = date.getTime();
    }else{
      var date = new Date();
      date.setMonth(date.getMonth() - 1);
      firstDay1 = date.getTime();
    }
    let payloade = "branchsalespersoncollection?date=" + firstDay1 + "&branchName=" + exp.branchName;
    this.adminservice.showLoader.next(true);
    this.salesBranchReport =[];
    this.adminservice.getSalesBranchReportsInfo(payloade).subscribe((posRes) => {
      this.serverResponse = posRes;
      if (posRes.response == 3) {
        this.salesBranchReport = posRes.totalbranchclientsData;
        this.adminservice.showLoader.next(false);
        //this.dd = posRes.dd;
        // this.productDispatchData = posRes.data;
        //this.produExpenseData = posRes.data;
      } else {
        this.adminservice.showLoader.next(false);
        this.openSnackBar(posRes.message, "");
      }
    }, (err: HttpErrorResponse) => {
      this.openSnackBar(err.message, "");
      this.adminservice.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }
  ViewSalesClientReport(sales) {
    this.salesClientReport = true;
    this.salesClientID = sales.userID;
    this.salesClientName = sales.userName;
    let payload = {
      userID: sales.userID
    }
    this.adminservice.showLoader.next(true);
    this.adminservice.getSalesClientBalance(payload).subscribe((posRes) => {
      this.serverResponse = posRes;
      if (posRes.response == 3) {  
        this.clientBalanceInfo = posRes.Info.clientbalancelist;
        this.adminservice.showLoader.next(false);
        //this.dd = posRes.dd;
        // this.productDispatchData = posRes.data;
        //this.produExpenseData = posRes.data;
      } else {
        this.adminservice.showLoader.next(false);
        this.openSnackBar(posRes.message, "");
      }
    }, (err: HttpErrorResponse) => {
      this.openSnackBar(err.message, "");
      this.adminservice.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })

  }
  setStyleSelected(sales) {
    let style;
    if (this.salesClientID == "") {
      let style = {
        "color": "#144158",
        "text-align": "center",
        "padding": "10px"
      }
    } else {
      if (this.salesClientID == sales.userID) {
        style = {
          "color": "#f9b320",
          "text-align": "center",
          "padding": "10px"
        }
      } else {
        style = {
          "color": "#144158",
          "text-align": "center",
          "padding": "10px"
        }
      }
    }

    return style;
  }
  backtoHome() {
    this.router.navigateByUrl("admin/dashboard");
  }
  selectSalesCollectionReportDay(event: any) {
    const selectedValue = event.target.value;
    if (selectedValue == "thisMonth") {
      this.allSalesMonthView = "thisMonth";
      this.viewReport = "Sales";
      let report = "totalsalesandcollectionReport?"
      var date = new Date();
      let firstDay1 = date.getTime();
      this.apiurl = report + "date=" + firstDay1 + "&downloadReport=false";
      this.salesCollectionReport();
    }
    if(selectedValue == "LastMonth"){
      this.allSalesMonthView = "LastMonth";
      this.viewReport = "Sales";
      let report = "totalsalesandcollectionReport?"
      var date = new Date();
      date.setMonth(date.getMonth() - 1);
      let firstDay1 = date.getTime();
      this.apiurl = report + "date=" + firstDay1 + "&downloadReport=false";
      this.salesCollectionReport();
    }
    
  }

  // production Dispatch and closing Reports Methods
  productionAndDispatchReport() {
    this.adminservice.showLoader.next(true);
    this.AllBandsList = [];
    this.adminservice.getproducts().subscribe((posRes) => {
      this.serverResponse = posRes;
      if (posRes.response == 3) {
        let serverInfo = posRes.Info;
        serverInfo.filter(el => {
          this.loading = false;
          this.AllBandsList.push(el.BrandName);
        })
        this.selectedbrand = this.AllBandsList[0];
        this.BrandName = this.AllBandsList[0];
        this.setImgStyles(this.AllBandsList[0]);
        const today = new Date().getTime();
        this.selectedMonthDate = today;
        this.getFirstBrandProductInfo(this.AllBandsList[0],this.selectedMonthDate);
      } else {
        this.adminservice.showLoader.next(false);
        this.openSnackBar(posRes.message, "");
      }
    }, (err: HttpErrorResponse) => {
      this.adminservice.showLoader.next(false);
      this.openSnackBar(err.message, "");
      this.adminservice.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }

  getFirstBrandProductInfo(bname,dts) {
    this.loading = true;
    // this.adminservice.showLoader.next(true);
    //const today = new Date().getTime();
    this.apiurl += "&BrandName=" + bname + "&date=" + dts;
   // console.log(this.apiurl);
    this.adminservice.getReportsInfo(this.apiurl).subscribe((posRes) => {
      this.serverResponse = posRes;
      if (posRes.response == 3) {
        
        this.TotalBrandReports = posRes.Total;
        let allProductInfo1 = posRes.info;
        let currentDate = new Date();
        let formattedDate = this.datePipe.transform(currentDate, 'dd-MM-yyyy');
        let allpros =[];
        for(var i=0;i<allProductInfo1.length;i++){
           if(""+formattedDate == allProductInfo1[i].timestamp){
                allpros.push(allProductInfo1[i]);
                break
           }else{
            allpros.push(allProductInfo1[i]);
           }
        }

        this.allProductInfo = allpros;
      // this.allProductInfo = posRes.info;
        // console.log(this.allProductInfo);
        // this.productionRepots = posRes.info.dataresult;
        // this.dispatchReports = posRes.info.dispatchData;
        // this.closingReports = posRes.info.closingdatafetch;
        // console.log(this.productionRepots[0]);
        console.log(this.allProductInfo);
        let dd = this.allProductInfo[0].Dispatch;
        var ddd = Object.keys(dd);
        this.productnames = ddd;
       // console.log(this.proucdtnames);
        //this.productnames.sort((one, two) => (one > two ? 1 : -1));
        // const index = 2;
        // const replacementString = 'Total'; // new string to replace with
        // this.productnames.splice(index, 2, replacementString);
        this.colspandata = this.productnames.length;
        console.log( this.productnames);
        console.log(this.colspandata);
        // this.pdcdivwidth = ""+ Number(this.colspandata * 215);
        this.brandProductToatlInfo = this.TotalBrandReports;
        // console.log(this.productnames.length);
        // var date = new Date(), y = date.getFullYear(), m = date.getMonth();
        // var firstDay = new Date(y, m, 1).setHours(0, 0, 0, 0);
        // var lastDay = new Date(y, m + 1, 0);
        // let dt = lastDay.getDate();
        //this.allProductInfo =[];
        // this.productionRepots.map(function(obj) {
        //   delete obj.timestamp;
        //   return obj;
        // });
        // this.dispatchReports.map(function(obj) {
        //   delete obj.timestamp;
        //   return obj;
        // });
        // this.closingReports.map(function(obj) {
        //   delete obj.timestamp;
        //   return obj;
        // });


        //   for(var i=1;i<=dt;i++){
        //     let obj = {
        //       date : i+"-"+(m+1)+"-"+y,
        //       Production : this.productionRepots[i-1],
        //       Dispatch : this.dispatchReports[i-1],
        //       Closingstock : this.closingReports[i-1]
        //   }
        //   this.TotalProductInfo =[this.TotalBrandReports]
        //   this.allProductInfo.push(obj);
        //   console.log(this.allProductInfo)
        // }
        this.adminservice.showLoader.next(false);
        this.loading =false;
      } else {
        
        this.productnames = [];
        this.brandProductToatlInfo = [];
        this.allProductInfo = [];
        this.TotalBrandReports = [];
        this.adminservice.showLoader.next(false);
        this.openSnackBar(posRes.message, "");
      }
    }, (err: HttpErrorResponse) => {
      this.openSnackBar(err.message, "");
      this.adminservice.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })

  }

  getbrandpdcreport(bname) {
    this.selectedbrand = bname;
    this.BrandName = bname;
    let report1 = "ProductionandDispatchReport?"
    this.apiurl = report1 + "&downloadReport=false";
    //const today = new Date().getTime();
    this.adminservice.showLoader.next(true);
    this.getFirstBrandProductInfo(bname,this.selectedMonthDate);
  }

  selectPDCReportDay(event: any){
    const selectedValue = event.target.value;
    let report1 = "ProductionandDispatchReport?"
    if (selectedValue == "thisMonth") {
      const today = new Date().getTime();
      this.selectedMonthDate = today;
      this.apiurl = report1 + "&downloadReport=false";
      //this.apiurl += "&BrandName=" + this.BrandName + "&date=" + today;
      this.adminservice.showLoader.next(true);
      this.getFirstBrandProductInfo(""+this.BrandName,today);
    }
    if (selectedValue == "LastMonth") {
      this.apiurl = report1 + "&downloadReport=false";
      const date = new Date();
      //date.setMonth(date.getMonth() - 1);
      const lastMonthDate = new Date(date.getFullYear(), date.getMonth() - 1, 10);
      let formattedDate = this.datePipe.transform(lastMonthDate, 'MM-dd-yyyy');
      let dts = new Date(""+formattedDate).getTime();
      this.selectedMonthDate = dts;
      this.adminservice.showLoader.next(true);
      this.getFirstBrandProductInfo(""+this.BrandName,dts);
    }
  }

  setImgStyles(bname) {
    this.selectProduct = bname;
    let style;
    if (this.BrandName == bname) {
      style = {
        "color": "#F9B320",
        "opacity": 1,
        "background-color": "#144158"
      }
    } else {
      style = {
        "background-color": "#144158",
        "color": "#F9B320",
        "opacity": 0.5
      }
    }
    return style;
  }


  // raw material report api

  RawMaterialReport() {
    this.adminservice.showLoader.next(true);
    console.log(this.apiurl);
    this.adminservice.getReportsInfo(this.apiurl).subscribe((posRes) => {
      this.serverResponse = posRes;
      if (posRes.response == 3) {
        this.rawmaterialsdatainfo = posRes.RawMaterialsData;
        this.adminservice.showLoader.next(false);
      } else {
        this.openSnackBar(posRes.message, "");
        this.adminservice.showLoader.next(false);
      }
    }, (err: HttpErrorResponse) => {
      this.openSnackBar(err.message, "");
      this.adminservice.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })

  }
  selectRawMateriaReportDay(event: any) {
    const selectedValue = event.target.value;
   
    if(selectedValue == "ThisMonth"){
      this.viewReport = "RawMaterial";
      var rmdt = new Date().getTime()
      let report = "rawmaterialsReport?"
      this.apiurl = report + "date=" + rmdt + "&downloadReport=false";
      this.RawMaterialReport();
    }
    if(selectedValue == "LastMonth"){
      this.viewReport = "RawMaterial";
      let report1 = "rawmaterialsReport?";
      var date = new Date();
      date.setMonth(date.getMonth() - 1);
      let ledgerDay1 = date.getTime();
      this.apiurl = report1 + "date=" + ledgerDay1 + "&downloadReport=false";
      this.RawMaterialReport();
    }
  }

  //cash flow Methods

  cashFlowReport() {
    this.adminservice.showLoader.next(true);
    this.adminservice.getReportsInfo(this.apiurl).subscribe((posRes) => {
      this.serverResponse = posRes;
      if (posRes.response == 3) {
        this.adminservice.showLoader.next(false);
        this.totalitems = posRes.Info;
        // console.log(this.totalitems)
        let dd = this.totalitems;
        var ddd = Object.keys(dd[0]);
        this.cashflowinfo = ddd;
        // console.log(this.cashflowinfo);

        this.cashflownames = posRes.Info;
        // const valuesArray = this.cashflownames.map(obj => obj.value);
        let yy = this.cashflownames
        let xx = Object.values(yy[0])
        this.cashflowname = xx;
        let cashobjnames ={}
        this.TotalAmt =[];
        for(var i of this.cashflowinfo) {
          cashobjnames[i] =0;
        }
        for(var j of this.totalitems){
          for (const key in j) {
            if(key != 'timestamp'){
              cashobjnames[key]+=j[key];
            }
          }
          // for(var k=0;k<j.length;k++){
          //   console.log(j[k]);
          // }
        }
       // console.log(cashobjnames);
        this.TotalAmt.push(cashobjnames);
        // console.log(this.cashflowname);
      } else {
        this.adminservice.showLoader.next(false);
        this.openSnackBar(posRes.message, "");
      }
    }, (err: HttpErrorResponse) => {
      this.adminservice.showLoader.next(false);
      this.openSnackBar(err.message, "");
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })

  }
  selectCashFlowReportDay(event: any){
    const selectedValue = event.target.value;
    let report1 = "cashflowreport?"
    if (selectedValue == "thisMonth") {
      this.cashFlowDate = new Date();
      const today = new Date().getTime();
      // this.selectedMonthDate = today;
      this.apiurl = report1 + "date=" + today;
      //this.apiurl += "&BrandName=" + this.BrandName + "&date=" + today;
      this.cashFlowReport();
    }
    if (selectedValue == "LastMonth") {
      this.apiurl = report1 + "&downloadReport=false";
      const date = new Date();
      //this.cashFlowDate = new Date(date.setMonth(date.getMonth() - 1));
      //date.setMonth(date.getMonth() - 1);
      const lastMonthDate = new Date(date.getFullYear(), date.getMonth() - 1, 10);
      this.cashFlowDate = new Date(lastMonthDate);
      let formattedDate = this.datePipe.transform(lastMonthDate, 'MM-dd-yyyy');
      let dts = new Date(""+formattedDate).getTime();
      // this.selectedMonthDate = dts;
      this.apiurl = report1 + "date=" + dts;
      this.cashFlowReport();
    }
  }
// Daily Report menthods
  DailyReportData() {
    this.adminservice.showLoader.next(true);
    this.adminservice.getReportsInfo(this.apiurl).subscribe((posRes) => {
      this.serverResponse = posRes;
      console.log(posRes);
      if (posRes.response == 3) {
        this.adminservice.showLoader.next(false);
        this.dialyReportobj = posRes.Info;
        //this.dailycloseBalance = Number(Number(this.dialyReportobj.cortonsclosingdataAmount) + Number(this.dialyReportobj.TotalCollectionAmount)) - Number(this.dialyReportobj.Totalexpenses)
        let totRawAmt = 0;
        this.dialyReportobj.procurementsdata.map((item) => {
          totRawAmt += Number(item.totalamountprocurement);
        })
        this.gtotdayrawmat = totRawAmt;
        let totAmt = 0;
        this.dialyReportobj.expensesdata.filter((item) => {
          totAmt += item.totalamountexpenses;
        })
        this.gtotdayexpenses = totAmt;
        this.daytotalExpense = this.gtotdayexpenses+this.gtotdayrawmat;
        this.dailysalesValue = this.dialyReportobj.Salesvalueamount.totalSalesvalueAmount + this.dialyReportobj.Salesvalueamount.totalSalesDiscount;

        this.dailycloseBalance = Number(Number(this.dialyReportobj.OpeningBalance) + Number(this.dialyReportobj.TotalCollectionAmount)) - Number(this.daytotalExpense)
       // this.dailycloseBalance = Number(Number(this.dialyReportobj.cortonsclosingdataAmount) + Number(this.dialyReportobj.TotalCollectionAmount)) - Number( this.daytotalExpense );
        this.dayCloseCredits = Number(Number(Number(this.dialyReportobj.openCredits) + Number(this.dialyReportobj.Salesvalueamount.totalSalesvalueAmount))
                                  -(Number(
                                    Number(this.dialyReportobj.breakagesamount)
                                   +Number(this.dialyReportobj.TotalCollectionAmount)
                                  )))
       // this.dayCloseCredits =  (this.dialyReportobj.Salesvalueamount.totalSalesvalueAmount) -(this.dialyReportobj.Salesvalueamount.totalSalesDiscount) - (this.dialyReportobj.breakagesamount) -(this.dialyReportobj.TotalCollectionAmount);
       // (this.dialyReportobj.Totalpreviousclosingdata + this.dialyReportobj.Salesvalueamount.totalSalesvalueAmount) - (this.dialyReportobj.Salesvalueamount.totalSalesDiscount + this.dialyReportobj.breakagesamount);
      } else {
        this.adminservice.showLoader.next(false);
        this.openSnackBar(posRes.message, "");
      }
    }, (err: HttpErrorResponse) => {
      this.openSnackBar(err.message, "");
      this.adminservice.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }
  MonthlyReportData() {
    this.adminservice.showLoader.next(true);
    this.adminservice.getReportsInfo(this.apiurl).subscribe((posRes) => {
      this.serverResponse = posRes;
      console.log(posRes);
      if (posRes.response == 3) {
        this.adminservice.showLoader.next(false);
        this.monthReportobj = posRes.Info;
        
        let totAmt = 0;
        if(this.monthReportobj.expensesdata.length>0){
          this.monthRawMExpense = true;
          this.monthReportobj.expensesdata.filter((item) => {
            totAmt += item.totalamountexpenses;
          })
        }
       
        this.gtotmonthexpenses = totAmt;
        let totRawAmt = 0;
        this.monthReportobj.procurementsdata.filter((item) => {
          totRawAmt += item.totalamountprocurement;
        })
        this.gtotmonthrawmat = totRawAmt;
        this.monthtotalExpense = this.gtotmonthrawmat+this.gtotmonthexpenses;
        this.monthsalesValue = this.monthReportobj.Salesvalueamount.totalSalesvalueAmount + this.monthReportobj.Salesvalueamount.totalSalesDiscount;
        this.monthcloseBalance = Number(Number(this.monthReportobj.OpeningBalance) + Number(this.monthReportobj.TotalCollectionAmount)) - Number(this.monthtotalExpense)
        //this.monthCloseCredits = (this.monthReportobj.Salesvalueamount.totalSalesvalueAmount) -(this.monthReportobj.Salesvalueamount.totalSalesDiscount) - (this.monthReportobj.breakagesamount) -(this.monthReportobj.TotalCollectionAmount);
        //this.monthReportobj.openCredits =0;
        this.monthCloseCredits = Number(Number(Number(this.monthReportobj.openCredits) + 
                                        Number(this.monthReportobj.Salesvalueamount.totalSalesvalueAmount)  
                                        )
                                  -(Number(
                                    
                                   +Number(this.monthReportobj.breakagesamount)
                                   +Number(this.monthReportobj.TotalCollectionAmount)
                                  )))
      } else {
        this.adminservice.showLoader.next(false);
        this.openSnackBar(posRes.message, "");
      }
    }, (err: HttpErrorResponse) => {
      this.openSnackBar(err.message, "");
      this.adminservice.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }



  downloadledgerExcel(){
    const ws = XLSX.utils.json_to_sheet(this.ledgerExpenseData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const blob = new Blob([XLSX.write(wb, { bookType: 'xlsx', type: 'array' })], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'ledger.xlsx';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);


  }

  prepareExcelprData() {
    const excelData = [];

  // Construct header row
  const mainHeaderRow = ['Dates', 'Production                                            ', null,null,null, 'Dispatch                                            ',null,null,null, 'Closing Stock                                 ',null,null,null, 'WarehouseStock                                 '];
  this.productnames.forEach(brand => {
    // mainHeaderRow.push(brand,);
  });
  excelData.push(mainHeaderRow);

  // Construct subheader row
 // const subHeaderRow = ['', 'BW1', 'BG1', 'B1P', 'Total', 'BW1', 'BG1', 'B1P', 'Total', 'BW1', 'BG1', 'B1P', 'Total', 'BW1', 'BG1', 'B1P', 'Total'];
 let subHeaderRow =[''];
 this.productnames.filter((item)=>{
   subHeaderRow.push(item);
 })
 this.productnames.filter((item)=>{
   subHeaderRow.push(item);
 })
 this.productnames.filter((item)=>{
   subHeaderRow.push(item);
 })
 this.productnames.filter((item)=>{
   subHeaderRow.push(item);
 })
  excelData.push(subHeaderRow);

  // Loop through each data row and extract the values
  for (const data of this.allProductInfo) {
    const row = [data.timestamp];

    this.productnames.forEach(brand => {
      row.push(data.Production[brand] || 0, data.Dispatch[brand] || 0,  data.closing[brand] || 0,  data.WarehouseStock[brand] || 0, );
    });

    excelData.push(row);
  }

  return excelData;
  }
  

  downloadproduExcel() {
    const excelData = this.prepareExcelprData();
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(excelData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Production Report');
  
    const blob = new Blob([XLSX.write(wb, { bookType: 'xlsx', type: 'array' })], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
  
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'production_report.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  prepareCashflowExcelData() {
    const excelData = [];
    
    // Add header row
    const headerRow = ['Month', 'Total'];
    for (const item of this.totalitems) {
      headerRow.push(item.timestamp);
    }
    excelData.push(headerRow);
    
    // Loop through each row in the table and extract the data
    const tableRows = document.querySelectorAll('table tbody tr');
    tableRows.forEach((row) => {
      const rowData = [];
      const rowCells = row.querySelectorAll('td');
      rowCells.forEach((cell) => {
        rowData.push(cell.textContent.trim());
      });
      excelData.push(rowData);
    });
    
    return excelData;
  }

  downloadcashflowExcel(){
    const cashflowData = this.prepareCashflowExcelData();
  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    
    // Create the sheet for cashflowinfo
    const cashflowWS: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(cashflowData);
    XLSX.utils.book_append_sheet(wb, cashflowWS, 'CashFlow Data');
    
    // Create the Blob and trigger the download
    const blob = new Blob([XLSX.write(wb, { bookType: 'xlsx', type: 'array' })], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
  
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'cashflow_data.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  prepareExcelData() {
    const excelData = [];
  
    // Add header row
    excelData.push([
      'Item Name', 'Opening Quantity', 'Opening Price', 
      'Procurement Quantity', 'Procurement Price',
      'Total Quantity', 'Total Price',
      'Production Quantity', 'Production Price',
      'Closing Quantity', 'Closing Price',
      'Raw Material Closing Quantity', 'Raw Material Closing Price',
      'Damages Quantity', 'Damages Price'
    ]);
  
    // Loop through each item in rawmaterialsdatainfo and add its data to the Excel data
    for (const rmdt of this.rawmaterialsdatainfo) {
      excelData.push([
        rmdt.itemName,
        rmdt.Opening.Quantity, rmdt.Opening.Price,
        rmdt.Procurement.Quantity, rmdt.Procurement.Price,
        rmdt.Total.Quantity, rmdt.Total.Price,
        rmdt.Production.Quantity, rmdt.Production.Price,
        rmdt.Closing.Quantity, rmdt.Closing.Price,
        rmdt.RawMaterialClosing.Quantity, rmdt.RawMaterialClosing.Price,
        rmdt.Damages.Quantity, rmdt.Damages.Price
      ]);
    }
  
    return excelData;
  }
  
  
    

  downloadrawExcel(){

    const excelData = this.prepareExcelData();
    const ws = XLSX.utils.json_to_sheet(excelData, { skipHeader: true });
    // const ws = XLSX.utils.json_to_sheet(this.TotalAmt);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const blob = new Blob([XLSX.write(wb, { bookType: 'xlsx', type: 'array' })], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);

  }


  prepareSalesExcelData() {
    const excelData = [];
    
    // Add header row
    excelData.push(['Branch Name', 'Opening', 'Sales', 'Discount', 'Collection', 'Closing']);
    
    // Loop through each sales entry and add its data to the Excel data
    for (const exp of this.Collection_SalesData) {
      excelData.push([exp.branchName, exp.Opening, exp.Sales, exp.DisCount, exp.Collection, exp.Closing]);
    }
    
    return excelData;
  }
  
  prepareSalesClientExcelData() {
    const excelData = [];
    
    // Add header row
    excelData.push(['Client Name', 'Opening', 'Sales', 'Collections', 'Closing']);
    
    // Loop through each sales client entry and add its data to the Excel data
    for (const sales of this.salesBranchReport) {
      excelData.push([sales.userName, sales.Opening, sales.Sales, sales.Collection, sales.Closing]);
    }
    
    return excelData;
  }
  

  downloadsalesExcel(){

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
  let excelData: any[];
  let sheetName: string;
  
  if (this.viewReport === 'Sales') {
    excelData = this.prepareSalesExcelData();
    sheetName = 'Sales Report';
  } else if (this.viewReport === 'AllSalesClient' && this.salesClientReport) {
    excelData = this.prepareSalesClientExcelData();
    sheetName = 'Sales Client Report';
  } else {
    return; // Exit if no data to export
  }
  
  // Create the sheet
  const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(excelData);
  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  
  // Create the Blob and trigger the download
  const blob = new Blob([XLSX.write(wb, { bookType: 'xlsx', type: 'array' })], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });

  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'sales_report.xlsx';
  a.click();
  window.URL.revokeObjectURL(url);

  }

  prepareDailyExcelData() {
    const excelData = [];
    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    // This arrangement can be altered based on how we want the date's format to appear.
    let currentDate = `${day}-${month}-${year}`;
    
    excelData.push(['Account Statement for the Day of '+currentDate, 'REVISED']);
    excelData.push(['Opening Balance', this.dialyReportobj.OpeningBalance]);
    excelData.push(['Total Collection', this.dialyReportobj.TotalCollectionAmount]);
    excelData.push(['Total', this.dialyReportobj.cortonsclosingdataAmount + this.dialyReportobj.TotalCollectionAmount]);
    excelData.push(['Total Expenses', this.daytotalExpense]);
    excelData.push(['Closing Balance', this.dailycloseBalance]);
    excelData.push(['', '']);
    excelData.push(['Sales Figures', '']);
    excelData.push(['Opening Credit', this.dialyReportobj.openCredits]);
    excelData.push(['Sales', (this.dialyReportobj.Salesvalueamount.totalSalesvalueAmount+this.dialyReportobj.Salesvalueamount.totalSalesDiscount)]);
    excelData.push(['Discounts', this.dialyReportobj.Salesvalueamount.totalSalesDiscount]);
    excelData.push(['Samples & Breakages', this.dialyReportobj.breakagesamount]);
    excelData.push(['Closing Credit', this.dayCloseCredits]);
    excelData.push(['', '']);
    excelData.push(['Details Expenses', '']);
    
    for (const procure of this.dialyReportobj.procurementsdata) {
      excelData.push([procure._id, procure.totalamountprocurement]);
    }
    
    excelData.push(['Total', this.gtotdayrawmat]);
    excelData.push(['', '']);
    excelData.push(['Detailed Expenses', '']);
    
    for (const expens of this.dialyReportobj.expensesdata) {
      excelData.push([expens._id, expens.totalamountexpenses]);
    }
    
    excelData.push(['Total', this.gtotdayexpenses]);
    
    return excelData;
  }

  downloaddailyExcel() {
    const dailyExcelData = this.prepareDailyExcelData();
    
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(dailyExcelData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Daily Report');
  
    const blob = new Blob([XLSX.write(wb, { bookType: 'xlsx', type: 'array' })], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
  
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'daily-report.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
  }
  
  prepareMonthlyReportExcelData() {
    const excelData = [];
  
    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

    const d = new Date();
    
    let name = month[d.getMonth()];
    let name1 = d.getFullYear();
    // Add header row
    excelData.push(['Account Statement for the Month of '+name+" "+name1, 'REVISED']);
  
    // Loop through each row in the table and add its data to the Excel data
    const rows = document.querySelectorAll('table tr:not(:first-child)');
    rows.forEach(row => {
      const rowData = [];
      const cells = row.querySelectorAll('td');
      cells.forEach(cell => {
        rowData.push(cell.textContent.trim());
      });
      excelData.push(rowData);
    });
  
    return excelData;
  }
  
  downloadmonthlyExcel() {
    const excelData = this.prepareMonthlyReportExcelData();
  
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(excelData);
  
    XLSX.utils.book_append_sheet(wb, ws, 'Monthly Report');
  
    const blob = new Blob([XLSX.write(wb, { bookType: 'xlsx', type: 'array' })], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
  
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'monthly_report.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
  }
  
  openSideNav() {
    this.isSideNavOpen = !this.isSideNavOpen;
  }

  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }

}

