import { Component, OnInit,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from "@angular/material";
import { AddnewItemComponent } from "../addnew-item/addnew-item.component";
@Component({
  selector: 'app-item-delete',
  templateUrl: './item-delete.component.html',
  styleUrls: ['./item-delete.component.css']
})
export class ItemDeleteComponent implements OnInit {

  itemName : string ="";
  itemObj : any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddnewItemComponent>
  ) { }

  ngOnInit() {
    this.itemName = this.data.itemName;
    this.itemObj = this.data.apidata;
  }
  close(){
    let obj = {
      bid : this.data.apidata,
      isDelete : false
    }
    this.dialogRef.close(obj)
  }
  submit(){
    let obj = {
      bid : this.data.apidata,
      isDelete : true
    }
    this.dialogRef.close(obj)
  }

}
