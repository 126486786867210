import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { AdminService } from 'src/app/admin.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-view-report',
  templateUrl: './view-report.component.html',
  styleUrls: ['./view-report.component.css']
})
export class ViewReportComponent implements OnInit {
  sub: any;
  id: any;
  serverResponse: any;
  ledgerExpenseData : any;
  produExpenseData:any
  providerForm: FormGroup;
  selectedStartDate : string;
  viewReport :string = "";
  apiurl : string ="";
  fileName : string = "";
 
  //ledger & daily report book
  ledgerReport = [];

  // produ & Dispatch varibles
  productDispatchData : any;
  BrandNames : any;
  AllProductsInfoArray : any;
  AddProductInfoArray : any;
  //sales collections Variables
  Collection_SalesData : any;
  dd : Array<any>

  countries = [
    { id: "1", name: "United States" },
    { id: "2", name: "Australia" },
    { id: "3", name: "Canada" },
    { id: "4", name: "Brazil" },
    { id: "5", name: "England" }
  ];
  constructor(
    private adminservice: AdminService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.getBrandsInfo();
    this.sub = this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      this.getReprotsData();
    })
    this.providerForm = this.fb.group({
      dateRange: ["", Validators.required]
    })
    console.log(this.selectedStartDate);
  }
  getBrandsInfo(){
    this.adminservice.getproducts().subscribe((posRes) => {
      if(posRes.response == 3){
        let apinfo = [];
        this.AllProductsInfoArray = posRes.Info;
        this.AllProductsInfoArray.filter(pinfo => {
          let bname = pinfo.BrandName;
          let bimage = pinfo.Brandimage;
          let plist = [];
          pinfo.Productslist.filter(spinfo => {
            let obj = {
              ProductID: spinfo.ProductID,
              ProductName: spinfo.ProductName,
              Price: spinfo.Price,
              Productimage: spinfo.Productimage,
              Quantity: "0",
              BrandName: bname,
            }
            plist.push(obj);
          })
          let obj1 = {
            BrandName: bname,
            Brandimage: bimage,
            orderlist: plist
          }
          apinfo.push(obj1);
          // this.checkData.push(new Neworder(pinfo.BrandName,pinfo.Brandimage,plist));
          // this.checkData.BrandName =pinfo.BrandName;
          // this.checkData.Brandimage = pinfo.Brandimage;
          // this.checkData.orderlist = plist;
        })
        this.AddProductInfoArray = apinfo;
      }
    })
  }
  getReprotsData(){
    console.log(this.id);
    let dt = new Date().getTime();
    console.log(dt);
    var report = "reportledgerbookapi?";
    var apiurl = report+"date="+1674632141825+"&downloadReport=false";
    switch (this.id) {
      case "LEDGER / DayBook":
        this.viewReport = "LEDGER";
        report = "reportledgerbookapi?";
        this.apiurl = report + "date=" + 1674632141825 + "&downloadReport=false";
        this.getLedgerReport();
        break;
      case "Sales & Collection":
        this.viewReport = "Sales";
        report = "totalsalesandcollectionReport?"
        this.apiurl = report + "&downloadReport=false";
        this.salesCollectionReport();
        break;
      case "PRODU & DISPATCH":
        this.viewReport = "PRODU";
        report = "ProductionandDispatchReport?"
        this.apiurl = report + "&downloadReport=false";
        this.productionAndDispatchReport();
        break;
      case "Raw Material":
        this.viewReport = "RawMaterial";
        report = "reportledgerbookapi?"
        this.apiurl = report + "date=" + 1674632141825 + "&downloadReport=false";
        this.RawMaterialReport();
        break;
      case "Cash Flow":
        this.viewReport = "CashFlow";
        report = "reportledgerbookapi?"
        this.apiurl = report + "date=" + 1674632141825 + "&downloadReport=false";
        this.cashFlowReport();
        break;
      case "Daily Report":
        this.viewReport = "DailyReport";
        report = "reportledgerbookapi?"
        this.apiurl = report + "date=" + 1674632141825 + "&downloadReport=false";
        this.DailyReport();
        break;
      case "PROFIT & LOSS":
        this.viewReport = "PROFITLOSS";
        report = "reportledgerbookapi?"
        this.apiurl = report + "date=" + 1674632141825 + "&downloadReport=false";
        this.profitLossReport();
        break;
      case "Monthly Report":
        this.viewReport = "MonthlyReport";
        report = "reportledgerbookapi?"
        this.apiurl = report + "date=" + 1674632141825 + "&downloadReport=false";
        this.MonthlyReport();
        break;
      case "Salaries":
        this.viewReport = "Salaries";
        report = "reportledgerbookapi?"
        this.apiurl = report + "date=" + 1674632141825 + "&downloadReport=false";
        this.salariesReport();
        break;
    }
  }
  getLedgerReport(){
    let d1 = {
      date1: "1675209600",
      openingBalance : "147000",
      ClientID : [{clientId : "anji1",branch : "atp" , Collection : "1000000"},
                  {clientId : "anji2",branch : "DMM" , Collection : "2000000"} ]
    }

    // this.adminservice.getReportsInfo(this.apiurl).subscribe((posRes) => {
    //   this.serverResponse = posRes;
    //   if (posRes.response == 3) {
    //     this.ledgerExpenseData = posRes.ExpensesData;
    //     this.produExpenseData = posRes.data;
    //   } else {

    //   }
    //   console.log(this.serverResponse);
    // }, (err: HttpErrorResponse) => {
    //   this.openSnackBar(err.message, "");
    //   this.adminservice.showLoader.next(false);
    //   if (err.error instanceof Error) {
    //     console.warn("Client SIde Error", err.error);
    //   } else {
    //     console.warn("Server Error", err.error);
    //   }
    // })
  }
  salesCollectionReport(){
    this.adminservice.getReportsInfo(this.apiurl).subscribe((posRes) => {
      this.serverResponse = posRes;
      if (posRes.response == 3) {
        this.Collection_SalesData = posRes.Collection_SalesData[0];
        this.dd = posRes.dd;
        // this.productDispatchData = posRes.data;
        //this.produExpenseData = posRes.data;
      } else {

      }
      console.log(this.serverResponse);
    }, (err: HttpErrorResponse) => {
      this.openSnackBar(err.message, "");
      this.adminservice.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }
  productionAndDispatchReport(){
    this.adminservice.getReportsInfo(this.apiurl).subscribe((posRes) => {
      this.serverResponse = posRes;
      if (posRes.response == 3) {
        this.productDispatchData = posRes.data;
        //this.produExpenseData = posRes.data;
      } else {

      }
      console.log(this.serverResponse);
    }, (err: HttpErrorResponse) => {
      this.openSnackBar(err.message, "");
      this.adminservice.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }
  RawMaterialReport(){

  }
  cashFlowReport(){

  }
  DailyReport(){

  }
  profitLossReport(){

  }
  MonthlyReport(){

  }
  salariesReport(){

  }
  backreport(){
    this.router.navigateByUrl('/admin/reports');
  }
  DownloadReport(){

  }
  updateMyDate(newDate) {
    this.selectedStartDate = newDate;
    console.log(newDate);
}
  displayData(){
    let payLoad = { ...this.providerForm.value };
    console.log(payLoad);
  }
  countryChanged(arg) {
    console.log("countryChanged " + arg);
  }
  displayInfo(){
    console.log(this.selectedStartDate);
  }
  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }
  
  DownloadReports(){
    let apiUrlTrue = this.apiurl.replace('false', 'true')
    this.adminservice.downloadExcelReports(apiUrlTrue).subscribe((posRes) => {
      this.downloadFile(posRes);
    })
  }
  downloadFile(data: any){
    this.fileName = ""+this.id+".csv"
    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    const url= window.URL.createObjectURL(blob);
    //window.open(url);
    var a = document.createElement("a");
    document.body.appendChild(a);
    // a.style = "display: none";
    a.href = url;
    a.download = this.fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

}
