import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { AddSalesPersonComponent } from '../add-sales-person/add-sales-person.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientordersComponent } from '../clientorders/clientorders.component';
import { AdminService } from 'src/app/admin.service';
import { stringify } from 'querystring';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-clientordersview',
  templateUrl: './clientordersview.component.html',
  styleUrls: ['./clientordersview.component.css']
})
export class ClientordersviewComponent implements OnInit {

  disCount: number;
  totalAmount: number;
  timestamp: number;
  productsSubcribe: any;
  serverResponse: any;
  clientorders: any;
  clientorderForm:FormGroup;
  customerName: string;
  salesmanName: String;
  lastPaidAmount:number;
  closingbalance:number;
  Quantity:number;
  baseUrl: any;
  sub: any;
  id: any;
  //totalAmount:number;
  
  constructor(
   
    private dialog: MatDialog,
    private fb: FormBuilder,
   private services:AdminService,
   private activatedRoute: ActivatedRoute,
   private router:Router,
   private snackBar:MatSnackBar,
  ) { }

  ngOnInit() {
    this.sub = this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      
      this.getProductsData();
    })
   // console.log(this.id)

this.baseUrl="https://dev-api.bhavgroup.com"
    this.clientorderForm = this.fb.group({
      customerName: new FormControl('', [Validators.required]),
      salesmanName: new FormControl('', [Validators.required]),
      timestamp: new FormControl('', [Validators.required]),
      lastPaidAmount: new FormControl('', [Validators.required]),
      closingbalance: new FormControl('', [Validators.required]),
      Quantity: new FormControl('', [Validators.required]),
      totalAmount: new FormControl('', [Validators.required]),
    })

  
  }

  numericOnly(event) {    
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  increasequantity(client){
    if( client.Quantity != 5){
      client.Quantity += 1;
    //console.log(client.Quantity)
    }
  }
  backClientOrder() {
    this.router.navigateByUrl('/admin/clientorders')
  }

  decreasequantity(client){
    if( client.Quantity != 1){
      client.Quantity -= 1;
      //console.log(client.Quantity)
      }
  }

  editorders(client) {
    this.router.navigate(["/admin/Clientorderedit", this.id])
  }

  UpdateStatus(client, status) {
    //this.services.showLoader.next(true);
    //let token = sessionStorage.getItem('token');
    let payload = {
      orderID: this.id,
      Adminstatus: status
    };

    this.services.updateClientOrderStatus(payload).subscribe((posRes) => {
     // this.services.showLoader.next(false);
      if (posRes.response == 3) {
        this.openSnackBar(posRes.message, "");
       
        this.getProductsData();
        //this.router.navigateByUrl('/clientorders')
      }
      else {
        this.openSnackBar(posRes.message, "");

      }
    }, (err: HttpErrorResponse) => {
      this.openSnackBar(err.message, "")

      this.services.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }

  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }


  getProductsData() {
    this.customerName ;
    this.salesmanName;
    this.timestamp=0;
    this.lastPaidAmount=0;
    this.closingbalance=0;
    this.Quantity=0;
    this.totalAmount=0;
    let obj = {
      type: this.id
    }
   
     this.services.getorders(obj).subscribe((posRes) => {
      this.serverResponse = posRes;
     // console.log(this.serverResponse)
      if (this.serverResponse.response == 3) {
        this.clientorders = this.serverResponse.Info[0];
        let tqt = 0;
        this.clientorders.orderlist.filter(function (el) {
          tqt += Number(el.Quantity);
        });
        this.Quantity = tqt;
       // console.log(this.clientorders);
       // this.openSnackBar(posRes.message, "");
        this.patchvalues();
        // this.clientorders.forEach(element => {
        // this.customerName = stringify(element.customerName);
        // this.salesmanName = stringify(element.salesmanName);
        // this.timestamp = parseFloat(element.timestamp);
        // this.lastPaidAmount = parseFloat(element.lastPaidAmount);
        // this.closingbalance = parseFloat(element.closingbalance);
        // this.Quantity = parseFloat(element.orderlist[0].Quantity);
        // this.totalAmount = parseFloat(element.totalAmount);
        // })
      }
    })
  }

  patchvalues(){
    
  }

  onsubmit(){
    
  }
  

}



