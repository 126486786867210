import { Component, OnInit,Inject, ChangeDetectorRef } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatSnackBar,
} from "@angular/material";
import { AllClientsComponent } from '../all-clients/all-clients.component';
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import { AdminService } from '../../admin.service';

@Component({
  selector: 'app-add-vendors',
  templateUrl: './add-vendors.component.html',
  styleUrls: ['./add-vendors.component.css']
})
export class AddVendorsComponent implements OnInit {
  clientProfileForm: FormGroup;
  previewUrl: any = "https://dev-api.bhavgroup.com/images/user.png";
  loading: boolean = false;
  sucessInfo: boolean = false;
  suceessDataInfo: string = "";
  hide = true;
  allbranchInfo: any;
  BrandName : string ="";
  AllBranchNames: any[];
  AllItemNames : any;
  rawMaterial : any[];
  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    private dialogRef: MatDialogRef<AllClientsComponent>,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private adminservice: AdminService
  ) { }

  ngOnInit() {
    this.AllItemNames = JSON.parse(localStorage.getItem("dropdownitems"));
    console.log(this.AllItemNames);
    this.rawMaterial = this.AllItemNames.RAWMaterials;
    console.log(this.rawMaterial)
    //this.getBranches();
    this.clientProfileForm = this.fb.group({
      profilePic: [""],
      customerName: ["", Validators.required],
      userID: ["", Validators.required],
      taxNumber : ["", Validators.required],
      Address: ["", Validators.required],
      PhoneNumber: ["", [Validators.required, Validators.pattern("^[0-9]*$"),Validators.maxLength(10),Validators.minLength(10)]],
      item : ["", Validators.required],
      Price : ["", Validators.required],
    });
  }

  getBranches(){
    this.adminservice.getAllbranch().subscribe(
      (posRes) => {
        //console.log(posRes);
        if (posRes.response == 3) {
         this.allbranchInfo = posRes.branchList;
         let pInfo =[];
         this.allbranchInfo.filter(function (el){
           pInfo.push(el.branchName);
         })
         this.AllBranchNames = pInfo;
        } else {
          this.openSnackBar(posRes.message, "");
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
  }

  //File Upload

  fileProgress(event) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);

      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.previewUrl = reader.result;
        this.clientProfileForm.get("profilePic").setValue(file);
      };
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }
  numericOnly(event) {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  ClientInsertInfo() {
    this.loading = true;
    let payLoad = { ...this.clientProfileForm.value };
    // payLoad.RegisterType = 5;
    delete payLoad.profilePic;
    //console.log(payLoad);
    // if (this.clientProfileForm.value.profilePic != "") {
      let formData = new FormData();
      console.log(payLoad);
      formData.append("image", this.clientProfileForm.get("profilePic").value);
      formData.append("userInfo", JSON.stringify(payLoad));
      this.adminservice.VendarInsert(formData).subscribe(
        (posRes) => {
          //console.log(posRes);
          if (posRes.response == 3) {
            this.loading = false;
            this.openSnackBar(posRes.message, "");
            this.dialogRef.close(true);
            this.suceessDataInfo = "Clients Data Inserted Sucess Fully";
          } else {
            this.loading = false;
            this.openSnackBar(posRes.message, "");
            //console.log(posRes);
            this.dialogRef.close(false);
          }
        },
        (err: HttpErrorResponse) => {
          this.loading = false;
          // console.log("Set Profile", err);
          if (err.error instanceof Error) {
            this.openSnackBar(err.message, "Client Error");
            console.log(err.message);
          } else {
            this.openSnackBar(err.message, "Server Error");
            console.log(err.message);
          }
        })
    // }else{
      // this.openSnackBar("Please Upload Profile Pic","");
      // this.loading = false;
    // }
    
  }
  updateClientValues(){
    let clientInfo = this.data;
    if (clientInfo.profilePic !== "") {
      // console.log(this.data.NDAAttachment);
      this.http.get("https://dev-api.bhavgroup.com"+this.data.profilePic,{
        responseType :"blob",
      }).subscribe((file)=>{
          let filename ="https://dev-api.bhavgroup.com" + this.data.profilePic;
          let spfname1 = this.data.profilePic.substring(this.data.profilePic.lastIndexOf("/") + 1);
          // let docName1 = spfname1.substring(spfname1.lastIndexOf("_")+1);
          let imgFile = new File([file], spfname1);
          this.clientProfileForm.get("profilePic").setValue(imgFile);
         // this.docsList = imgFile;
          let spfname = this.data.NDAAttachment.substring(this.data.NDAAttachment.lastIndexOf("/") + 1);
          // this.docName = spfname.substring(spfname.lastIndexOf("_")+1);
      })
    }

    this.clientProfileForm.patchValue({
      userID:clientInfo.userID ,
      customerName: clientInfo.customerName,
      PhoneNumber: clientInfo.PhoneNumber,
      Address: clientInfo.Address,
      Password: clientInfo.Password
    })
    //console.log(clientInfo);
  }

  closeTab() {
    this.dialogRef.close(false);
  }
  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
      verticalPosition: "top",
    });
  }

}
