import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AdminService } from '../../admin.service';
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import { MatSnackBar } from "@angular/material";
@Component({
  selector: 'app-add-client-view',
  templateUrl: './add-client-view.component.html',
  styleUrls: ['./add-client-view.component.css']
})
export class AddClientViewComponent implements OnInit {
  routeSub: any;
  id: any;
  queryStr: any;
  clientInfo: any;
  UserDetails : any;
  baseUrl: any;
  previewUrl : string ="";
  constructor(
    private activateRoute: ActivatedRoute,
    private adminservice: AdminService,
    private snackBar: MatSnackBar,
    private router:Router,
  ) { }

  ngOnInit() {
    this.activateRoute.params.subscribe((params) => {
      this.onLoadData();
    });
  }

  onLoadData() {
    this.baseUrl = this.adminservice.baseUrl;
    this.routeSub = this.activateRoute.params.subscribe((params) => {
      //log the entire params object
      this.id = params["id"];
    });
    this.activateRoute.queryParamMap.subscribe((params) => {
      this.queryStr = { ...params };
    });
    if (this.queryStr.params.isEncpt == "y") {
      this.id = atob(this.id);
      this.fetchData(this.id);
    } else {
      this.id = atob(this.id);
      this.fetchData(this.id);
    }
  }

  fetchData(dataInfo) {
    this.clientInfo = JSON.parse(dataInfo);
    let payload = {
      PhoneNumber : ""+this.clientInfo
    }
    this.adminservice.getClientInfo(payload).subscribe(
      (posRes) => {
        //console.log(posRes);
        if (posRes.response == 3) {
          this.UserDetails = posRes.info;
          this.previewUrl = this.baseUrl + this.UserDetails.profilePic;
        } else {
          this.openSnackBar(posRes.message, "");
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
    //console.log(this.clientInfo);

  }
  backAllClients(){
    this.router.navigateByUrl('/admin/Clients');
  }
   //message alerts showing
   openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }
}
