import { Component, OnInit,HostListener  } from '@angular/core';

@Component({
  selector: 'app-admin-demo2',
  templateUrl: './admin-demo2.component.html',
  styleUrls: ['./admin-demo2.component.scss']
})
export class AdminDemo2Component implements OnInit {
  isMobileView = false;
  menuItems = [
    { label: 'Home', link: '/admin/dashboard' },
    { label: 'BGModules', subItems: [
      { label: 'Client', link: '/admin/Clients' },
      { label: 'Production', link: '/admin/productionperson' },
      { label: 'Sales', link: '/admin/salesPersons' }
    ],expanded: false },
    { label: 'SalesModule', subItems: [
      { label: 'New Order', link: '/admin/sales' },
      { label: 'client Orders', link: '/admin/clientorders' }
    ],expanded: false },
    { label: 'Production', subItems: [
      { label: 'Product List', link: '/admin/products' }
    ],expanded: false },
    { label: 'BGAdmin', subItems: [
      { label: 'Add New Brand', link: '/admin/addbrand' },
      { label: 'Add New Branch', link: '/admin/addnewBranch' },
      { label: 'Add New Item', link: '/admin/addnewItem' },
      { label: 'Deposit Approve', link: '/admin/deposit' }
    ],expanded: false },
    { label: 'BG Misllien', subItems: [
      { label: 'Requirements', link: '/admin/requirments' },
      { label: 'Procurements', link: '/admin/procurement' },
      { label: 'Expenses', link: '/admin/expenses' },
      { label: 'closing Balance', link: '/admin/closingbal' }
    ],expanded: false },
    { label: 'BG Reports', subItems: [
      { label: 'Reports', link: '/admin/reports' }
    ],expanded: false }
  ];

  result = [{
    "linkText": "Home",
    "parentLink": "/admin/dashboard",
    "menu": false,
    "submenu": []
  },{
    "linkText": "BGModules",
    "parentLink": "",
    "menu": false,
    "submenu": [
      {
        "childtext": "Client",
        "link": "/admin/Clients"
      },
      {
        "childtext": "Production",
        "link": "/admin/productionperson"
      },
      {
        "childtext": "Sales",
        "link": "/admin/salesPersons"
      }
    ]
  },{
    "linkText": "SalesModule",
    "parentLink": "",
    "menu": false,
    "submenu": [
      {
        "childtext": "New Order",
        "link": "/admin/sales"
      },
      {
        "childtext": "Client Orders",
        "link": "/admin/clientorders"
      }
    ]
  },{
    "linkText": "ProductionModule",
    "parentLink": "",
    "menu": false,
    "submenu": [
      {
        "childtext": "Product List",
        "link": "/admin/products"
      }
    ]
  },
  {
    "linkText": "BGAdmin",
    "parentLink": "",
    "menu": false,
    "submenu": [
      {
        "childtext": "Add New Brand",
        "link": "/admin/addbrand"
      },
      {
        "childtext": "Add New Branch",
        "link": "/admin/addnewBranch"
      },
      {
        "childtext": "Add New Item",
        "link": "/admin/addnewItem"
      },
      {
        "childtext": "Deposit Approve",
        "link": "/admin/deposit"
      }
    ]
  },{
    "linkText": "BG Misllien",
    "parentLink": "",
    "menu": false,
    "submenu": [
      {
        "childtext": "Requirements",
        "link": "/admin/requirments"
      },
      {
        "childtext": "Procurements",
        "link": "/admin/procurement"
      },
      {
        "childtext": "Expenses",
        "link": "/admin/expenses"
      },
      {
        "childtext": "closing Balance",
        "link": "/admin/closingbal"
      }
    ]
  },{
    "linkText": "BG Reports",
    "parentLink": "",
    "menu": false,
    "submenu": [
      {
        "childtext": "Reports",
        "link": "/admin/reports"
      }
    ]
  }]
  newresult = [{
    "linkText": "New Join",
    "parentLink": "",
    "menu": false,
    "submenu": [
      {
        "childtext": "Client",
        "link": "/admin/Clients"
      },
      {
        "childtext": "Production",
        "link": "/admin/productionperson"
      },
      {
        "childtext": "Sales",
        "link": "/admin/salesPersons"
      },
      {
        "childtext": "Worker",
        "link": "/admin/salesPersons"
      },
    ]
  },{
    "linkText": "New Item",
    "parentLink": "",
    "menu": false,
    "submenu": [
      {
        "childtext": "New Branch",
        "link": "/admin/addnewBranch"
      },
      {
        "childtext": "New Warehouse",
        "link": "/admin/addnewBranch"
      },
      {
        "childtext": "Brand List",
        "link": "/admin/addbrand"
      },
      {
        "childtext": "Product List",
        "link": "/admin/products"
      },
      {
        "childtext": "Drop Downs",
        "link": "/admin/addnewItem"
      },
      
      // {
      //   "childtext": "Deposit Approve",
      //   "link": "/admin/deposit"
      // }
    ]
  },
  {
    "linkText": "Reports",
    "parentLink": "",
    "menu": false,
    "submenu": [
      {
        "childtext": "Reports",
        "link": "/admin/reports"
      }
    ]
  }]

  constructor() { }

  ngOnInit() {
    // detect if screen size is considered mobile
    this.isMobileView = window.innerWidth < 768; // adjust this value to your desired mobile breakpoint
  }
  // listen for window resize events and update isMobileView accordingly
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobileView = window.innerWidth < 768; // adjust this value to your desired mobile breakpoint
  }
  ddToggle(i: number) {
    this.newresult[i].menu = !this.newresult[i].menu;
  }
  toggleArrow(target: any) {
    const arrow = target.querySelector('.arrow');
    const menuItem = this.menuItems.find(item => item.label === target.textContent.trim());
    menuItem.expanded = !menuItem.expanded;
    arrow.classList.toggle('rotate');
  }

}
