import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-allmobile-report',
  templateUrl: './allmobile-report.component.html',
  styleUrls: ['./allmobile-report.component.css']
})
export class AllmobileReportComponent implements OnInit {
  allReports = ["LEDGER / DayBook","PRODU & DISPATCH","Raw Material","Sales & Collection","Cash Flow","Daily Report","Monthly Report"]
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }
  displayReports(report){
    this.router.navigate(["/ViewReports", report]);
    // switch (report) {
    //   case "LEDGER / DayBook":
    //     this.router.navigate(["/admin/viewreports", report])
    //     break;
    // }
  }

}
