import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../admin.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { BranchDeleteComponent } from '../branch-delete/branch-delete.component';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  searchByNameForm: FormGroup;
  baseurl: string = ""; 
  AllProductList : Array<any> =[];
  prodocutInfoList : Array<any> =[];
  splitedProducts: Array<any> = [];
  click: boolean = true;
  numberid : any;
  selectProduct : string="";
  BrandName : string ="";
  BrandImage : string ="";
  defaultstyle : boolean = true;
  brandProductInfo: any = [];
  user : any;
  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private adminService : AdminService,
    private snackBar: MatSnackBar,
    private router:Router,
  ) { }  

  ngOnInit() {
    this.baseurl = "https://dev-api.bhavgroup.com";
    this.searchByNameForm = this.fb.group({
      searchText: ["", Validators.required],
      type: ["4"],
      pageNo: ["1"],
      size: ["15"]
    })
    this.user = JSON.parse(sessionStorage.getItem('user'));
    this.getProducts(); 
  }


  getProducts(){
    this.adminService.showLoader.next(true);
   this.adminService.getproducts().subscribe((posRes) => {
      this.adminService.showLoader.next(false);
      if (posRes.response == 3) {
        this.AllProductList = posRes.Info;
        this.displayAllproducts(this.AllProductList[0]);
        console.log(posRes);        
      } else {
        this.openSnackBar(posRes.message, "");
      }
    }, (err: HttpErrorResponse) => {     
      this.openSnackBar(err.message, "");
      this.adminService.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })

  }
   //message alerts showing
   openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
      verticalPosition: "top",
    });
  }
  Displayproduct(product){
    console.log(product);
    this.prodocutInfoList = product.Productslist;
  }
 
  setImgStyles1(product){
    this.selectProduct = product.BrandName;
    let style;
    if (this.BrandName == product.BrandName) {
      style = {
        "border": "3px solid #144158"
      }
    }
    return style;
  }
  AddProductInfo(){
    this.router.navigateByUrl('/admin/addnewProductInfo');
  }

  displayAllproducts(product){
    this.BrandName = product.BrandName;
    this.BrandImage = product.Brandimage;
    this.brandProductInfo = product.Productslist;
  }
  ProductEditInfo(bproduct){
    bproduct.BrandName = this.BrandName;
    this.router.navigate(["/admin/productView",btoa(JSON.stringify(bproduct))]);
  }
  ProductDeleteInfo(bproduct){
    console.log(bproduct);
    let dailogRef = this.dialog.open(BranchDeleteComponent, {
      panelClass: "col-md-6",
      hasBackdrop: true,
      disableClose: true,
      data : { bname : bproduct.ProductName, bid : bproduct.ProductID, msg : "Product" }
    });
    dailogRef.afterClosed().subscribe((res) => {
      if(res.isDelete){
        let payload = {
          ProductID: res.bid,
          userID : this.user.userID,
          BrandName : this.BrandName
        }
        console.log(payload);
        this.adminService.DeleteProduct(payload).subscribe(
          (posRes) => {
            console.log(posRes);
            if (posRes.response == 3) {
              //this.loading = false;
              this.openSnackBar(posRes.message, "");
              this.getProducts();
            } else {
             // this.loading = false;
              this.openSnackBar(posRes.message, "");
            }
          },
          (err: HttpErrorResponse) => {
            //this.loading = false;
            // console.log("Set Profile", err);
            if (err.error instanceof Error) {
              this.openSnackBar(err.message, "Client Error");
              console.log(err.message);
            } else {
              this.openSnackBar(err.message, "Server Error");
              console.log(err.message);
            }
          })
      }else{
        this.openSnackBar("Product Doesn't Deleted", "");
      }
    })
  }
}
