import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-all-reports',
  templateUrl: './all-reports.component.html',
  styleUrls: ['./all-reports.component.css']
})
export class AllReportsComponent implements OnInit {

  allReports = ["LEDGER / DayBook","PRODU & DISPATCH","Raw Material","Sales & Collection","Cash Flow","Daily Report","Monthly Report"]
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }
  displayReports(report){
    this.router.navigate(["/admin/viewreports", report]);
    // switch (report) {
    //   case "LEDGER / DayBook":
    //     this.router.navigate(["/admin/viewreports", report])
    //     break;
    // }
  }

}
