import { Component, OnInit,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from "@angular/material";
import { AddBranchComponent } from '../add-branch/add-branch.component';
@Component({
  selector: 'app-branch-delete',
  templateUrl: './branch-delete.component.html',
  styleUrls: ['./branch-delete.component.css']
})
export class BranchDeleteComponent implements OnInit {

  branchName : string = "";
  branchInfo : any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddBranchComponent>
  ) { }

  ngOnInit() {
    //console.log(this.data);
    this.branchName = this.data.bname;
  }
  close(){
    let obj = {
      bid : this.data.bid,
      isDelete : false
    }
    this.dialogRef.close(obj)
  }
  submit(){
    let obj = {
      bid : this.data.bid,
      isDelete : true
    }
    this.dialogRef.close(obj)
  }
}
