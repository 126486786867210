import { Component, OnInit } from '@angular/core';
import { MatDialog,MatSnackBar } from '@angular/material';
import { AdminService } from 'src/app/admin.service';
import { ClientorderseditComponent } from '../clientordersedit/clientordersedit.component';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-clientorders',
  templateUrl: './clientorders.component.html',
  styleUrls: ['./clientorders.component.css']
})
export class ClientordersComponent implements OnInit {
  productsSubcribe: any;
  serverResponse: any;
  clientorders: any = [];
  constructor(private admin: AdminService, private dialog: MatDialog,
    private router: Router,private snackBar: MatSnackBar,) { }

  ngOnInit() {
    this.getProductsData()
  }

  getProductsData() {
    let obj = {
      type: "All"
    }

    this.admin.getorders(obj).subscribe((posRes) => {
      this.serverResponse = posRes;
      //console.log(this.serverResponse)
      if (this.serverResponse.response == 3) {
        let clorder = this.serverResponse.Info;
        let clordrarr = [];
        clordrarr = clorder.filter(order =>{
          let amt = 0;
          order.orderlist.filter(sproduct =>{
            amt+=Number(sproduct.Quantity)
          })
          order["totalQty"] = amt;
          return order;
        })
        this.clientorders = clordrarr;
        // this.clientorders = this.serverResponse.Info;
        // console.log(clordrarr);      
      }
    })
  }
  UpdateStatus(orders, status) {
    this.admin.showLoader.next(true);
    //let token = sessionStorage.getItem('token');
    let payload = {
      orderID: orders.orderID,
      Adminstatus: status
    };

    this.admin.updateClientOrderStatus(payload).subscribe((posRes) => {
      this.admin.showLoader.next(false);
      if (posRes.response == 3) {
        this.openSnackBar(posRes.message, "");
        this.getProductsData();
      }
      else {
        this.openSnackBar(posRes.message, "");

      }
    }, (err: HttpErrorResponse) => {
      this.openSnackBar(err.message, "")

      this.admin.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }
  numericOnly(event) {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

   //message alerts showing
   openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }

  editorders(orders) {
    this.router.navigate(["/admin/Clientorderedit", orders.orderID])
  }
  vieworder(orders) {

    this.router.navigate(["/admin/Clientview", orders.orderID])
  }
}
