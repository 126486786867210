import { HttpErrorResponse } from '@angular/common/http';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AdminService } from 'src/app/admin.service';
import { B2BdeleteComponent } from '../b2-bdelete/b2-bdelete.component';

@Component({
  selector: 'app-b2-b',
  templateUrl: './b2-b.component.html',
  styleUrls: ['./b2-b.component.css']
})
export class B2BComponent implements OnInit {
  b2bForm:FormGroup;
  isupdated: boolean = false;
  DropDownItems : any;
  B2bID : string;
  b2bnames : any;
  b2bfetch:any;
  serverResponse: any;
  loading: boolean;
  issubmitted: boolean;
  isLoading: boolean;
  constructor(private admin: AdminService, private fb: FormBuilder, 
    private snackBar: MatSnackBar,private dialog:MatDialog) { }

  ngOnInit() {

    this.b2bForm = this.fb.group({
      Name: new FormControl('', [Validators.required]),
    
      items: new FormControl('', [Validators.required]),
      Code: new FormControl('', [Validators.required,Validators.maxLength(6),Validators.minLength(6)]),
      Price:  new FormControl('', [Validators.required]),
      totalAmount:  new FormControl('', [Validators.required]),
      Quantity: new FormControl('', [Validators.required,Validators.maxLength(10),Validators.minLength(10)])
    })
    this.getItems();
    this.getnames();
  }

getnames(){
  let obj = {
    type : "4"
  }
  this.admin.fetchAllClients(obj).subscribe((posRes)=>{
   
    if (posRes.response == 3) {
      this.b2bnames = posRes.info;
      //console.log(posRes);
    }else{
      
    }
  })
}
  getItems(){
    let user = JSON.parse(sessionStorage.getItem('user'));
    let obj = {
      B2bID:"All"
    }
    this.admin.getb2b(obj).subscribe((posRes)=>{
      //console.log(posRes);
      if (posRes.response == 3) {
        this.b2bfetch = posRes.B2BData;
        this.DropDownItems = posRes.DropDownItems[0];
        // this.B2bID = this.DropDownItems.B2bID;
        // this.b2bnames = this.DropDownItems.Name;
       // console.log(this.b2bnames);
      }else{
        this.openSnackBar("No Items get Error","")
      }
    },(err: HttpErrorResponse) => {
      this.openSnackBar(err.message, "");
      this.admin.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }

   //message alerts showing
   openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }

  b2bupdate(b2b){
    //console.log(b2b);
    this.isupdated = true;
    this.b2bnames=this.b2bnames;

    this.B2bID = b2b.B2bID;
    this.b2bForm.patchValue({
      Name: b2b.Name,
      items: b2b.items,
      Code: b2b.Code,
      Price: b2b.Price,
      Quantity: b2b.Quantity,
      totalAmount: b2b.Quantity,
      // timestamp: b2b.Quantity,
    })
    
    document.getElementById("Name").focus();
  }

  changeMaduleTypes(items) {
    //console.log(items);
    if (this.b2bnames.length > 0) {
      this.b2bnames = [];
    }
    this.b2bForm.patchValue({ itemtype: "-- select --" })
    // if (items == "Admin") {
    //   this.b2bnames = this.AdminItems;
    // }
  }

  b2b(){
    this.admin.showLoader.next(true);
    this.loading = true;
    let user = JSON.parse(sessionStorage.getItem('user'));
    let payLoad = { ...this.b2bForm.value };
    if (this.isupdated) {
      let payload = { ...this.b2bForm.value };
      payload.B2bID = this.B2bID;
      //console.log(payload)
      this.admin.updateb2b(payload).subscribe((posRes) => {
        this.serverResponse = posRes;
        //console.log(this.serverResponse)
        if (this.serverResponse.response == 3) {
          this.loading = false;
          this.issubmitted = false;
          this.isupdated = false;
          this.admin.showLoader.next(false);
          this.openSnackBar(posRes.message, "");
          this.getItems();
       
        location.reload()
        }else{
          this.loading =false;
        }
      },(err: HttpErrorResponse) => {
      
        this.openSnackBar(err.message, "");
        this.admin.showLoader.next(false);
        if (err.error instanceof Error) {
          console.warn("Client SIde Error", err.error);
        } else {
          console.warn("Server Error", err.error);
        }
      })
    } else {
      this.admin.b2binsert(payLoad).subscribe((posRes) => {
        this.serverResponse = posRes;
        if (this.serverResponse.response == 3) {
          // this.loading=false;
          this.openSnackBar(posRes.message, "");
          this.getItems();
          this.admin.showLoader.next(false);
        
          location.reload()
        }else{
          this.loading=false;
        }
      },(err: HttpErrorResponse) => {
      
        this.openSnackBar(err.message, "");
        this.admin.showLoader.next(false);
        if (err.error instanceof Error) {
          console.warn("Client SIde Error", err.error);
        } else {
          console.warn("Server Error", err.error);
        }
      })
    }

}


b2bdelete(b2b) {
  //console.log(b2b);
  this.admin.showLoader.next(true);
  let dialogRef = this.dialog.open(B2BdeleteComponent, {
    panelClass: 'col-md-4',
    hasBackdrop: true,
    disableClose: true,
    data: b2b
  })
  dialogRef.afterClosed().subscribe(res => {
   let user = JSON.parse(sessionStorage.getItem('user'));
    //console.log(res)
    if (res.isDelete) {
      //console.log(b2b)
      this.isLoading = true;
      let obj = {
        B2bID: res.B2bID
      }

      this.admin.deleteb2b(obj).subscribe((dele) => {

        //console.log("del", dele);
        if (dele.response === 3) {
          this.admin.showLoader.next(false);
          this.isLoading = false;
          this.getItems();
          location.reload()
          this.openSnackBar(dele.message, "");
         
       
        } else {
          this.isLoading = false;
        }
      })
    } else {
      this.getItems();
    }

  },(err: HttpErrorResponse) => {
    
    this.openSnackBar(err.message, "");
    this.admin.showLoader.next(false);
    if (err.error instanceof Error) {
      console.warn("Client SIde Error", err.error);
    } else {
      console.warn("Server Error", err.error);
    }
  })

}
  }


