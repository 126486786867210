export class Neworder {
    BrandName : string ="";
    Brandimage : string;
    orderlist : Array<any>;
    constructor(brandName: string,bimage:string,orderLists : Array<any>){
        this.BrandName = brandName;
        this.BrandName = bimage;
        this.orderlist = orderLists;

    }
}
