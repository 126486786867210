import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminService } from 'src/app/admin.service';
import { MatSnackBar } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ExpdeleteComponent } from '../expdelete/expdelete.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.css']
})
export class ExpensesComponent implements OnInit {
  serverResponse: any;
  Expenses: any;
  Expensess: Array<any> = []
  expenseForm: FormGroup;
  issubmitted: boolean = true;
  isupdated: boolean = false;
  expenseID: string = "";
  loading: boolean = false;
  clientNamesInfo: any;
  categories: any;
  subCategories: any;
  DropDownItems : any;
  productionID : string;
  expenseItems : any;
  constructor(private admin: AdminService, private snackBar: MatSnackBar,
    private fb: FormBuilder, private dialog: MatDialog) { }

  ngOnInit() {
    this.expenseForm = this.fb.group({
      // userID:new FormControl('', [Validators.required]),
      Expenses: new FormControl('', [Validators.required]),
      Description: new FormControl('', [Validators.required]),
      Price: new FormControl('', [Validators.required]),
    })
    this.getExpenseItem();
    this.getexpenses();
  }

  // setSubCatId(subCat){
  // console.log(subCat)
  // let a = ""+subCat.toString();
  //   let index = a.substring(0,a.lastIndexOf(":"));
  //   console.log(this.Expensess[index]);
  //   let clInfo = this.Expensess[index];
  // }


  setSubCatId(subCat) {
    console.log(subCat)
    let index;
    index = this.subCategories.findIndex(val => {
      return val.Expenses == subCat;
    })
    if (index !== -1) {
      this.expenseForm.patchValue({
        expenseID: this.subCategories[index].expenseID
      })
    }
  }

  getExpenseItem() {
    let user = JSON.parse(sessionStorage.getItem('user'));
    // userID: user.userID
    let obj = {
      
    }
    this.admin.getItems(obj).subscribe((posRes)=>{
      console.log(posRes);
      if (posRes.response == 3) {
        // this.DropDownItems = posRes.DropDownItems[0];
        // this.productionID = this.DropDownItems.productionID;
        this.expenseItems = posRes.AdminList.Expenses;
        console.log(this.expenseItems);
      }else{
        this.openSnackBar("No Items get Error","")
      }
    },(err: HttpErrorResponse) => {
      this.openSnackBar(err.message, "");
      this.admin.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }

  getexpenses() {
    console.log(sessionStorage.getItem('user'))
    let user = JSON.parse(sessionStorage.getItem('user'));
    console.log(user)
    // userID: user.userID,
    let obj = {
      
      type: "1"
    }

    this.admin.getexpenses(obj).subscribe((posRes) => {
      this.serverResponse = posRes;
      console.log(this.serverResponse)
      this.admin.showLoader.next(true);
      if (this.serverResponse.response == 3) {
        this.Expenses = this.serverResponse.ToptenExpenses
        this.admin.showLoader.next(false);
        // this.openSnackBar(posRes.message, "");
      } else {
        this.admin.showLoader.next(false);
        // this.openSnackBar(posRes.message, "");
      }
    }, (err: HttpErrorResponse) => {

      this.openSnackBar(err.message, "");
      this.admin.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }

  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }

  expenseupdate(exp) {
    console.log(exp);
    this.isupdated = true;
    this.expenseID = exp.expenseID;
    this.expenseForm.patchValue({
      Expenses: exp.Expenses,
      Description: exp.Description,
      Price: "" + exp.Price,
    })
    document.getElementById("Expenses").focus();
  }
  FormPatchValues(){
    this.expenseForm.patchValue({
      Price :"",
      Description :"",
      Expenses : ""
    })
  }
  expense(){
    let payLoad = { ...this.expenseForm.value };
    if(payLoad.Quantity == ""){
      this.openSnackBar("Please Enter Quantity","");
    }else {
      if(payLoad.Description == ""){
        this.openSnackBar("Please Enter Description","");
      }else{
        this.expenseSData();
      }
    }
  }

  expenseSData() {
    this.loading = true;
    let user = JSON.parse(sessionStorage.getItem('user'));
    let payLoad = { ...this.expenseForm.value };
    payLoad.userID = user.userID;
    payLoad.type = "0"
    this.admin.showLoader.next(true);
    console.log(payLoad);
    if (this.isupdated) {
      payLoad.expenseID = this.expenseID;
      delete payLoad.type;

      this.admin.updateexpenses(payLoad).subscribe((posRes) => {
        this.serverResponse = posRes;
        console.log(this.serverResponse)
        if (this.serverResponse.response == 3) {
          this.issubmitted = false;
          this.isupdated = false;
          this.loading = false;
          this.admin.showLoader.next(false);
          this.openSnackBar(posRes.message, "");
          this.getexpenses();
          this.FormPatchValues();
          //  this.expenseForm.reset()
          //location.reload()
        }
      }, (err: HttpErrorResponse) => {

        this.openSnackBar(err.message, "");
        this.admin.showLoader.next(false);
        if (err.error instanceof Error) {
          console.warn("Client SIde Error", err.error);
        } else {
          console.warn("Server Error", err.error);
        }
      })
    } else {
      this.admin.expenses(payLoad).subscribe((posRes) => {
        this.serverResponse = posRes;
        console.log(this.serverResponse)

        if (this.serverResponse.response == 3) {
          this.admin.showLoader.next(false);
          this.openSnackBar(posRes.message, "");
          this.getexpenses();
          this.loading = false;
          //this.expenseForm.reset()
          // location.reload()
          this.FormPatchValues();
        }
      }, (err: HttpErrorResponse) => {

        this.openSnackBar(err.message, "");
        this.admin.showLoader.next(false);
        if (err.error instanceof Error) {
          console.warn("Client SIde Error", err.error);
        } else {
          console.warn("Server Error", err.error);
        }
      })

    }

  }

  // DeleteClientInfo(client){
  //   let dialogRef = this.dialog.open(ExpdeleteComponent, {
  //     panelClass: 'col-md-4',
  //     hasBackdrop: true,
  //     disableClose: true,
  //     data : client
  //   })
  //   dialogRef.afterClosed().subscribe(res => {
  //     if (res.isDelete) {
  //       this.expensedelete(res);
  //       //this.fetchAllClients();
  //     }
  //   })
  // }


  expensedelete(exp) {
    this.admin.showLoader.next(true);
    console.log(exp);

    let dialogRef = this.dialog.open(ExpdeleteComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true,
      data: exp
    })
    dialogRef.afterClosed().subscribe(res => {
      let user = JSON.parse(sessionStorage.getItem('user'));

      if (res.isDelete) {
        console.log(exp)

        let obj = {
          userID: user.userID,
          type: exp.expenseID

        }

        this.admin.deleteexpense(obj).subscribe((dele) => {
          this.admin.showLoader.next(true);
          console.log("del", dele);
          if (dele.response === 3) {
            this.admin.showLoader.next(false);
            this.openSnackBar(dele.message, "");
            this.getexpenses();
            // this.expenseForm.reset()
          } else {
            this.loading = false;
          }
        })
      } else {
        this.getexpenses();
      }

    }, (err: HttpErrorResponse) => {

      this.openSnackBar(err.message, "");
      this.admin.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })

  }
}
