// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBFdVn1D9lz9uS5X8X3NtcXjeD8AkMZV7M",
    authDomain: "softexer-aa148.firebaseapp.com",
    // databaseURL: "https://star-tasker-1576141277610.firebaseio.com",
    projectId: "softexer-aa148",
    storageBucket: "softexer-aa148.appspot.com",
    messagingSenderId: "1052659504594",
    appId: "1:1052659504594:web:656075c13e9d39afc7640b",
    measurementId: "G-0K89ZETWZV",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
