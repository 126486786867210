import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AddNewBrandsComponent } from '../add-new-brands/add-new-brands.component'; 
@Component({
  selector: 'app-delete-dailog',
  templateUrl: './delete-dailog.component.html',
  styleUrls: ['./delete-dailog.component.css']
})
export class DeleteDailogComponent implements OnInit {
  BrandData : any;
  message: any;

  constructor(private dialogRef: MatDialogRef<AddNewBrandsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    this.BrandData = this.data;
    this.message = this.BrandData.message;
    console.log(this.BrandData.product.BrandName);
  }

  close() {
    let obj ={
      BrandName : this.BrandData.product.BrandName,
      isDelete : false
    }
    this.dialogRef.close(obj);
  }
  submit() {
    let obj ={
      BrandName : this.BrandData.product.BrandName,
      isDelete : true
    }
    this.dialogRef.close(obj);
  }
}
