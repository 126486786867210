import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/admin.service';
import { MatDialog, MatSnackBar } from "@angular/material";
import { SignoutComponent } from '../signout/signout.component';

@Component({
  selector: 'app-admin-demo',
  templateUrl: './admin-demo.component.html',
  styleUrls: ['./admin-demo.component.scss']
})
export class AdminDemoComponent implements OnInit {
  isdetailsPage:boolean = false;
  isSideNavOpen:boolean = false;
  isMainAdmin:boolean = false;
  isLogin : boolean = false;
  user : any;
  sidebarOpen = false;
  menuItems = [
    { label: 'Home', link: '/admin/dashboard' },
    { label: 'BGModules', subItems: [
      { label: 'Client', link: '/admin/Clients' },
      { label: 'Production', link: '/admin/productionperson' },
      { label: 'Sales', link: '/admin/salesPersons' },
      { label: 'Admin', link: '/admin/AdminPersons' },
      { label: 'vendors', link: '/admin/VendorPersons' }
    ],expanded: false },
    { label: 'SalesModule', subItems: [
      { label: 'New Order', link: '/admin/sales' },
      { label: 'client Orders', link: '/admin/clientorders' }
    ],expanded: false },
    { label: 'Production', subItems: [
      { label: 'Product List', link: '/admin/products' }
    ],expanded: false },
    { label: 'BGAdmin', subItems: [
      { label: 'Add New Brand', link: '/admin/addbrand' },
      { label: 'Add New Branch', link: '/admin/addnewBranch' },
      { label: 'Add New Item', link: '/admin/addnewItem' },
      { label: 'Deposit Approve', link: '/admin/deposit' }
    ],expanded: false },
    { label: 'BG Misllien', subItems: [
      { label: 'Requirements', link: '/admin/requirments' },
      { label: 'Procurements', link: '/admin/procurement' },
      { label: 'Expenses', link: '/admin/expenses' },
      { label: 'closing Balance', link: '/admin/closingbal' }
    ],expanded: false },
    { label: 'BG Reports', subItems: [
      { label: 'Reports', link: '/admin/reports' }
    ],expanded: false }
  ];
  result = [{
    "linkText": "Home",
    "parentLink": "/admin/dashboard",
    "menu": false,
    "submenu": []
  },{
    "linkText": "BGModules",
    "parentLink": "",
    "menu": false,
    "submenu": [
      {
        "childtext": "Client",
        "link": "/admin/Clients"
      },
      {
        "childtext": "Production",
        "link": "/admin/productionperson"
      },
      {
        "childtext": "Sales",
        "link": "/admin/salesPersons"
      },
      {
        "childtext":"vendors", 
        "link" : "/admin/VendorPersons" 
      }
    ]
  },{
    "linkText": "SalesModule",
    "parentLink": "",
    "menu": false,
    "submenu": [
      {
        "childtext": "New Order",
        "link": "/admin/sales"
      },
      {
        "childtext": "Client Orders",
        "link": "/admin/clientorders"
      }
    ]
  },{
    "linkText": "ProductionModule",
    "parentLink": "",
    "menu": false,
    "submenu": [
      {
        "childtext": "Product List",
        "link": "/admin/products"
      }
    ]
  },
  {
    "linkText": "BGAdmin",
    "parentLink": "",
    "menu": false,
    "submenu": [
      {
        "childtext": "Add New Brand",
        "link": "/admin/addbrand"
      },
      {
        "childtext": "Add New Branch",
        "link": "/admin/addnewBranch"
      },
      {
        "childtext": "Add New Item",
        "link": "/admin/addnewItem"
      },
      {
        "childtext": "Deposit Approve",
        "link": "/admin/deposit"
      }
    ]
  },{
    "linkText": "BG Reports",
    "parentLink": "",
    "menu": false,
    "submenu": [
      {
        "childtext": "Reports",
        "link": "/admin/reports"
      }
    ]
  },{
    "linkText": "BG Miscellaneous ",
    "parentLink": "",
    "menu": false,
    "submenu": [
      {
        "childtext": "Requirements",
        "link": "/admin/requirments"
      },
      {
        "childtext": "Procurements",
        "link": "/admin/procurement"
      },
      {
        "childtext": "Expenses",
        "link": "/admin/expenses"
      },
      {
        "childtext": "closing Balance",
        "link": "/admin/closingbal"
      }
    ]
  }]
  constructor(
    private adminService:AdminService, 
    private router:Router,
    private dialog: MatDialog, 
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    let token = sessionStorage.getItem('token');
    if(token == null){
      // // console.log("Token",token);
     this.router.navigateByUrl('/login');
    };
    let adminType = sessionStorage.getItem('isMainAdmin');
    //console.log(adminType);
   // console.log(sessionStorage.getItem('isMainAdmin'));
    this.isMainAdmin = adminType == "1" ? true : false;
  }
  AddAdmin(){
    this.router.navigateByUrl('/admin/AddAdmin');
  }
  logOut(){
    // sessionStorage.clear();
    // this.router.navigateByUrl('/login')
    let dailogRef = this.dialog.open(SignoutComponent, {
      panelClass: "col-md-5",
      hasBackdrop: true,
      disableClose: true,
    });
    dailogRef.afterClosed().subscribe((res) => {
      if (res == true) {
        sessionStorage.clear();
        //this.adminService.callLogOut.subscribe(false);

       this.router.navigateByUrl('/login')
      }
    });

  }
 
  openSideNav() {
    this.isSideNavOpen = !this.isSideNavOpen;
  }
  ddToggle(i: number) {
    this.result[i].menu = !this.result[i].menu;
  }
  toggleSidebar() {
    this.sidebarOpen = !this.sidebarOpen;
  }
  toggleArrow(target: any) {
    const arrow = target.querySelector('.arrow');
    const menuItem = this.menuItems.find(item => item.label === target.textContent.trim());
    menuItem.expanded = !menuItem.expanded;
    arrow.classList.toggle('rotate');
  }
  
  

}
