import { Component, OnInit } from '@angular/core';
import { AdminService } from 'src/app/admin.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IproductsData } from '../../Model/productReport';
import { ItotalProductsInfo } from '../../Model/totalProductInfo';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-all-reprts-viewnew',
  templateUrl: './all-reprts-viewnew.component.html',
  styleUrls: ['./all-reprts-viewnew.component.css'],
  providers: [DatePipe]
})
export class AllReprtsViewnewComponent implements OnInit {
  isSideNavOpen: boolean = false;
  allReports = ["Ledger/Day Book", "Production, Dispatch, closing stock", "Raw Material", "Sales & Collection", "Cash Flow", "Daily Report", "Monthly Report"]
  selectedReport = "Ledger/Day Book";
  selectReport = "";
  styles = {};
  viewReport: string = "LEDGER";
  apiurl: string = "";
  serverResponse: any;
  isMainAdmin: boolean = false;
  isAdminLoggedIn: boolean = false;
  loading: boolean = false;

  //LEDGER REPORT VARIABLES
  ledgerReport = [];
  ledgerExpenseData: any;
  ledgerCollectionData: any;
  finalLedgerReport: any;
  selectedOption: string = "";
  ledgerDateRange: string;
  ledgertodayForm: FormGroup;
  maxDate1: any = new Date();
  FinalledgerArray = [];
  dropdownItems: any;
  dropdownExpense: any;
  selectedUpdateExpenseItem: string;
  ExpenseitemForm: FormGroup;
  lastdayCloseData: number;

  // Product and Dispatch closing stock params
  AllBandsList = [];
  productionRepots = [];
  dispatchReports = [];
  closingReports = [];
  warehouseReports = [];
  TotalBrandReports = [];
  selectedbrand: string;
  user: any;
  selectProduct = "";
  BrandName: string = "";
  colspandata: number;
  productnames = [];
  allProductInfo: any;
  TotalProductInfo: any;
  brandProductToatlInfo: ItotalProductsInfo[] = []
  selectedMonthDate: number;
  pdcdivwidth: string = "1100";

  constructor(
    private adminservice: AdminService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private router: Router,
    private datePipe: DatePipe
  ) {
    this.adminservice.isAdminLoggedIn.subscribe((val) => {
      if (val === false) {
        this.openLogindialog();
      }
    });
  }

  ngOnInit() {

    this.ledgertodayForm = this.fb.group({
      selectedOption: ["", Validators.required],
    })
    // this.adminservice.isAdminLoggedIn.next(true);

    this.getExpenseItems();
  }

  getExpenseItems() {
    this.adminservice.showLoader.next(true);
    var date = new Date(), y = date.getFullYear(), m = date.getMonth(), dd = date.getDate();
    let payload = {
      "date": dd + "-" + m + "-" + y
    }
    this.adminservice.dashboard(payload).subscribe((posRes) => {
      this.serverResponse = posRes;
      if (posRes.response == 3) {
        // this.adminservice.showLoader.next(false);
        this.dropdownItems = posRes.DropDownItems;
        this.dropdownExpense = this.dropdownItems[0].Expenses;
        this.displayReports("Ledger/Day Book");
      } else {
        this.adminservice.showLoader.next(false);
        this.openSnackBar(posRes.message, "");
      }
    }, (err: HttpErrorResponse) => {
      this.openSnackBar(err.message, "");
      this.adminservice.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }

  displayReports(report) {
    this.selectedReport = report;
    switch (report) {
      case "Ledger/Day Book":
        this.viewReport = "LEDGER";
        report = "reportledgerbookapi?";
        var date = new Date();
        let ledgerDay1 = date.getTime();
        this.apiurl = report + "date=" + ledgerDay1 + "&downloadReport=false";
        this.getLedgerReport();
        break;
      case "Production, Dispatch, closing stock":
        this.viewReport = "PRODU";
        report = "ProductionandDispatchReport?"
        this.apiurl = report + "&downloadReport=false";
        this.productionAndDispatchReport();
        break;
      case "Sales & Collection":
        this.viewReport = "Sales";
        report = "totalsalesandcollectionReport?"
        var date = new Date();
        let firstDay1 = date.getTime();
        this.apiurl = report + "date=" + firstDay1 + "&downloadReport=false";
        //this.salesCollectionReport();
        break;

      case "Raw Material":
        this.viewReport = "RawMaterial";
        var rmdt = new Date().getTime()
        report = "rawmaterialsReport?"
        this.apiurl = report + "date=" + rmdt + "&downloadReport=false";
        // this.RawMaterialReport();
        break;
      case "Cash Flow":
        this.viewReport = "CashFlow";
        report = "cashflowreport?"
        // this.cashFlowDate = new Date();
        var date = new Date();
        let firstDay2 = date.getTime();
        this.apiurl = report + "date=" + firstDay2;
        // this.cashFlowReport();
        break;
      case "Daily Report":
        this.viewReport = "DailyReport";
        report = "monthreport?"
        // this.todayDate = new Date();
        this.apiurl = report + "date=today";
        // this.DailyReportData();
        break;
      case "Monthly Report":
        this.viewReport = "MonthlyReport";
        report = "monthreport?"
        // this.todayDate = new Date();
        var date = new Date();
        let firstDay3 = date.getTime();
        this.apiurl = report + "date=" + firstDay3;
        //this.MonthlyReportData();
        break;
    }

  }


  // Ledger Day Report methods
  getLedgerReport() {
    this.adminservice.showLoader.next(true);
    this.FinalledgerArray = [];
    this.ledgerExpenseData = [];
    this.adminservice.getReportsInfo(this.apiurl).subscribe((posRes) => {
      this.serverResponse = posRes;
      console.log(posRes);
      if (posRes.response == 3) {
        let FinalReport = [];
        let allledgerArr = posRes.Ledgerdata;
        let currentDate = new Date();
        let formattedDate = this.datePipe.transform(currentDate, 'dd-MM-yyyy');
        let allledger = [];
        for (var pro of allledgerArr) {
          if ("" + formattedDate == pro.timestamp) {
            allledger.push(pro);
            break
          } else {
            allledger.push(pro);
          }
        }
        this.FinalledgerArray = allledger;
        console.log(this.FinalledgerArray);
        this.lastdayCloseData = 0;
        for (let objValue of this.FinalledgerArray) {
          let loop1 = 1;
          if (objValue.Collection.length > 0) {
            objValue.Collection.filter((item) => {
              let objdata = {};
              if (loop1 == 1) {
                objdata["timestamp"] = item.timestamp;
                loop1++;
              } else {
                objdata["timestamp"] = "";
                loop1++;
              }
              objdata["category"] = "";
              objdata["Description"] = "";
              objdata["Expenses"] = "";
              objdata["userID"] = item.customeruserID;
              objdata["ExpenseID"] = "",
                objdata["Collection"] = item.totalAmount;
              objdata["procument"] = "";
              objdata["procureItem"] = ""
              objdata["closingAmount"] = "";
              FinalReport.push(objdata);
            })
          }
          if (objValue.Expenses.length > 0) {
            objValue.Expenses.filter((item) => {
              let objdata = {};
              if (loop1 == 1) {
                objdata["timestamp"] = item.timestamp;
                loop1++;
              } else {
                objdata["timestamp"] = "";
                loop1++;
              }

              objdata["category"] = item.Expenses;
              objdata["Description"] = item.Description;
              objdata["Expenses"] = item.Price;
              objdata["ExpenseID"] = item.expenseID,
                objdata["userID"] = item.userID;
              objdata["Collection"] = "";
              objdata["procument"] = "";
              objdata["procureItem"] = "";
              objdata["closingAmount"] = "";
              FinalReport.push(objdata);
            })
          }
          if (objValue.Procurement.length > 0) {
            objValue.Procurement.filter((item) => {
              // console.log(loop1);
              let objdata = {};
              if (loop1 == 1) {
                objdata["timestamp"] = item.timestamp;
                loop1++;
              } else {
                objdata["timestamp"] = "";
                loop1++;
              }
              objdata["category"] = "";
              objdata["Description"] = "";
              objdata["Expenses"] = "";
              objdata["userID"] = item.userID;
              objdata["ExpenseID"] = "",
                objdata["Collection"] = "";
              objdata["procument"] = item.totalAmount;
              objdata["procureItem"] = item.item;
              objdata["closingAmount"] = "";
              FinalReport.push(objdata);
            })
          }
          let objdataa = {}
          if (loop1 == 1) {
            objdataa["timestamp"] = objValue.timestamp;
            loop1++;
          } else {
            objdataa["timestamp"] = "";
            loop1++;
          }
          this.lastdayCloseData = this.lastdayCloseData + objValue.ClosingAmount;
          objdataa["category"] = "";
          objdataa["Description"] = "";
          objdataa["Expenses"] = "";
          objdataa["Collection"] = "";
          objdataa["userID"] = "";
          objdataa["ExpenseID"] = "";
          objdataa["procument"] = "";
          objdataa["procureItem"] = "";
          objdataa["closingAmount"] = this.lastdayCloseData;

          FinalReport.push(objdataa);
        }
        this.ledgerExpenseData = FinalReport;
        this.adminservice.showLoader.next(false);
      } else {
        this.ledgerExpenseData = [];
        this.lastdayCloseData = 0;
        this.ledgerCollectionData = [];
        this.adminservice.showLoader.next(false);
        this.openSnackBar("there is no transactions this Month", "");
      }
    }, (err: HttpErrorResponse) => {
      this.openSnackBar(err.message, "");
      this.adminservice.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }
  updateSelectedLedgerItem(items) {
    let payload = {
      expenseID: items.ExpenseID,
      userID: items.userID,
      Description: items.Description,
      Expenses: items.category,
      Price: "" + items.Expenses
    }
    this.adminservice.showLoader.next(true);
    this.adminservice.updateexpenses(payload).subscribe((posRes) => {
      this.serverResponse = posRes;
      if (posRes.response == 3) {
        // this.adminservice.showLoader.next(false);
        this.getLedgerReport();
      } else {
        this.adminservice.showLoader.next(false);
        this.openSnackBar(posRes.message, "");
      }
    }, (err: HttpErrorResponse) => {
      this.openSnackBar(err.message, "");
      this.adminservice.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }
  selectLedgerReportDay(event: any) {
    const selectedValue = event.target.value;
    var firstDay;
    var lastDay;
    if (selectedValue == "Today") {
      let report1 = "reportledgerbookapi?";
      this.apiurl = report1 + "date=today&downloadReport=false";
      this.getTodayLedgerReport();
    }
    if (selectedValue == "yester Day") {
      let report1 = "reportledgerbookapi?";
      const today = new Date();
      const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
      yesterday.setHours(0, 0, 0, 0);
      firstDay = yesterday.getTime();
      yesterday.setHours(23, 59, 59, 999);
      lastDay = yesterday.getTime();
      this.apiurl = report1 + "date=" + firstDay + "," + lastDay + "&downloadReport=false";
      this.getLedgerReport();
    }
    if (selectedValue == "ThisMonth") {
      let report1 = "reportledgerbookapi?";
      var date = new Date();
      let ledgerDay1 = date.getTime();
      this.apiurl = report1 + "date=" + ledgerDay1 + "&downloadReport=false";
      this.getLedgerReport();
    }
    if (selectedValue == "LastMonth") {
      let report1 = "reportledgerbookapi?";
      var date = new Date();
      date.setMonth(date.getMonth() - 1);
      let ledgerDay1 = date.getTime();
      this.apiurl = report1 + "date=" + ledgerDay1 + "&downloadReport=false";
      this.getLedgerReport();
    }
  }
  getTodayLedgerReport() {
    this.adminservice.showLoader.next(true);
    this.FinalledgerArray = [];
    this.ledgerExpenseData = [];
    this.adminservice.getReportsInfo(this.apiurl).subscribe((posRes) => {
      this.serverResponse = posRes;
      if (posRes.response == 3) {

        let FinalReport = [];
        this.FinalledgerArray = [posRes.Ledgerdata];
        for (let objValue of this.FinalledgerArray) {
          let loop1 = 1;
          if (objValue.Collection.length > 0) {
            objValue.Collection.filter((item) => {
              let objdata = {};
              if (loop1 == 1) {
                objdata["timestamp"] = item.timestamp;
                loop1++;
              } else {
                objdata["timestamp"] = "";
                loop1++;
              }
              objdata["category"] = "";
              objdata["Description"] = "";
              objdata["Expenses"] = "";
              objdata["userID"] = item.customeruserID;
              objdata["ExpenseID"] = "",
                objdata["Collection"] = item.totalAmount;
              objdata["procument"] = "";
              objdata["procureItem"] = "";
              objdata["closingAmount"] = "";
              FinalReport.push(objdata);
            })
          }
          if (objValue.Expenses.length > 0) {
            objValue.Expenses.filter((item) => {
              let objdata = {};
              if (loop1 == 1) {
                objdata["timestamp"] = item.timestamp;
                loop1++;
              } else {
                objdata["timestamp"] = "";
                loop1++;
              }
              objdata["category"] = item.Expenses;
              objdata["Description"] = item.Description;
              objdata["Expenses"] = item.Price;
              objdata["userID"] = item.userID;
              objdata["Collection"] = "";
              objdata["closingAmount"] = "";
              objdata["procument"] = "";
              objdata["procureItem"] = "";
              objdata["ExpenseID"] = item.expenseID,
                FinalReport.push(objdata);
            })
          }
          if (objValue.Procurement.length > 0) {
            objValue.Procurement.filter((item) => {
              let objdata = {};
              if (loop1 == 1) {
                objdata["timestamp"] = item.timestamp;
                loop1++;
              } else {
                objdata["timestamp"] = "";
                loop1++;
              }
              objdata["category"] = "";
              objdata["Description"] = "";
              objdata["Expenses"] = "";
              objdata["userID"] = item.userID;
              objdata["ExpenseID"] = "",
                objdata["Collection"] = "";
              objdata["procument"] = item.totalAmount;
              objdata["procureItem"] = item.item;
              objdata["closingAmount"] = "";
              FinalReport.push(objdata);
            })
          }
          let objdataa = {}
          if (loop1 == 1) {
            objdataa["timestamp"] = objValue.timestamp;
            loop1++;
          } else {
            objdataa["timestamp"] = "";
            loop1++;
          }
          this.lastdayCloseData = this.lastdayCloseData + objValue.ClosingAmount;
          objdataa["category"] = "";
          objdataa["Description"] = "";
          objdataa["Expenses"] = "";
          objdataa["Collection"] = "";
          objdataa["userID"] = "";
          objdataa["ExpenseID"] = "",
            objdataa["procument"] = "";
          objdataa["procureItem"] = "";
          objdataa["closingAmount"] = this.lastdayCloseData;
          FinalReport.push(objdataa);
        }
        this.ledgerExpenseData = FinalReport;
        this.adminservice.showLoader.next(false);
      } else {
        this.ledgerExpenseData = [];
        this.ledgerCollectionData = [];
        this.lastdayCloseData = 0;
        this.adminservice.showLoader.next(false);
        this.openSnackBar("There is No Transaction Today", "");
      }
    }, (err: HttpErrorResponse) => {
      this.openSnackBar(err.message, "");
      this.adminservice.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }
  onLedgerDateRangeChange(event: any) {
    var lfirstDay;
    var llastDay;

    this.ledgerDateRange = event;
    let firstday = new Date(this.ledgerDateRange[0]);
    let lastDay = new Date(this.ledgerDateRange[1]);
    lfirstDay = new Date(firstday.getFullYear(), firstday.getMonth(), firstday.getDate()).setHours(0, 0, 0, 0);
    llastDay = new Date(lastDay.getFullYear(), lastDay.getMonth(), lastDay.getDate()).setHours(23, 59, 59, 999);
    let report1 = "reportledgerbookapi?";
    this.apiurl = report1 + "date=" + lfirstDay + "," + llastDay + "&downloadReport=false";
    this.getLedgerReport();
  }


  // production Dispatch and closing Reports Methods
  productionAndDispatchReport() {
    this.adminservice.showLoader.next(true);
    this.AllBandsList = [];
    this.adminservice.getproducts().subscribe((posRes) => {
      this.serverResponse = posRes;
      if (posRes.response == 3) {
        let serverInfo = posRes.Info;
        serverInfo.filter(el => {
          this.loading = false;
          this.AllBandsList.push(el.BrandName);
        })
        this.selectedbrand = this.AllBandsList[0];
        this.BrandName = this.AllBandsList[0];
        this.setImgStyles(this.AllBandsList[0]);
        const today = new Date().getTime();
        this.selectedMonthDate = today;
        this.getFirstBrandProductInfo(this.AllBandsList[0], this.selectedMonthDate);
      } else {
        this.adminservice.showLoader.next(false);
        this.openSnackBar(posRes.message, "");
      }
    }, (err: HttpErrorResponse) => {
      this.adminservice.showLoader.next(false);
      this.openSnackBar(err.message, "");
      this.adminservice.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }

  getFirstBrandProductInfo(bname, dts) {
    this.loading = true;
    this.apiurl += "&BrandName=" + bname + "&date=" + dts;
    this.adminservice.getReportsInfo(this.apiurl).subscribe((posRes) => {
      this.serverResponse = posRes;
      if (posRes.response == 3) {
        this.TotalBrandReports = posRes.Total;
        let allProductInfo1 = posRes.info;
        let currentDate = new Date();
        let formattedDate = this.datePipe.transform(currentDate, 'dd-MM-yyyy');
        let allpros = [];
        for (var i = 0; i < allProductInfo1.length; i++) {
          if ("" + formattedDate == allProductInfo1[i].timestamp) {
            allpros.push(allProductInfo1[i]);
            break
          } else {
            allpros.push(allProductInfo1[i]);
          }
        }
        this.allProductInfo = allpros;
        let dd = this.allProductInfo[0].Dispatch;
        var ddd = Object.keys(dd);
        this.productnames = ddd;
        this.colspandata = this.productnames.length;
        this.brandProductToatlInfo = this.TotalBrandReports;
        this.adminservice.showLoader.next(false);
        this.loading = false;
      } else {
        this.productnames = [];
        this.brandProductToatlInfo = [];
        this.allProductInfo = [];
        this.TotalBrandReports = [];
        this.adminservice.showLoader.next(false);
        this.openSnackBar(posRes.message, "");
      }
    }, (err: HttpErrorResponse) => {
      this.openSnackBar(err.message, "");
      this.adminservice.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })

  }

  getbrandpdcreport(bname) {
    this.selectedbrand = bname;
    this.BrandName = bname;
    let report1 = "ProductionandDispatchReport?"
    this.apiurl = report1 + "&downloadReport=false";
    this.adminservice.showLoader.next(true);
    this.getFirstBrandProductInfo(bname, this.selectedMonthDate);
  }

  selectPDCReportDay(event: any) {
    const selectedValue = event.target.value;
    let report1 = "ProductionandDispatchReport?"
    if (selectedValue == "thisMonth") {
      const today = new Date().getTime();
      this.selectedMonthDate = today;
      this.apiurl = report1 + "&downloadReport=false";
      this.adminservice.showLoader.next(true);
      this.getFirstBrandProductInfo("" + this.BrandName, today);
    }
    if (selectedValue == "LastMonth") {
      this.apiurl = report1 + "&downloadReport=false";
      const date = new Date();
      const lastMonthDate = new Date(date.getFullYear(), date.getMonth() - 1, 10);
      let formattedDate = this.datePipe.transform(lastMonthDate, 'MM-dd-yyyy');
      let dts = new Date("" + formattedDate).getTime();
      this.selectedMonthDate = dts;
      this.adminservice.showLoader.next(true);
      this.getFirstBrandProductInfo("" + this.BrandName, dts);
    }
  }

  setImgStyles(bname) {
    this.selectProduct = bname;
    let style;
    if (this.BrandName == bname) {
      style = {
        "color": "#F9B320",
        "opacity": 1,
        "background-color": "#144158"
      }
    } else {
      style = {
        "background-color": "#144158",
        "color": "#F9B320",
        "opacity": 0.5
      }
    }
    return style;
  }




  openLogindialog() {
    this.router.navigateByUrl('/login')
  }
  openSideNav() {
    this.isSideNavOpen = !this.isSideNavOpen;
  }
  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }
}
