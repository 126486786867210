import { Component, OnInit,ElementRef  } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatDialog } from "@angular/material";
import { AdminService } from '../../admin.service';
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import { ItemDeleteComponent } from "../item-delete/item-delete.component";
@Component({
  selector: 'app-addnew-item',
  templateUrl: './addnew-item.component.html',
  styleUrls: ['./addnew-item.component.css']
})
export class AddnewItemComponent implements OnInit {
  submitted: boolean = false;
  isupdated: boolean = false;
  formSubmit: boolean = true;
  newitemForm: FormGroup;
  moduletype: string;
  listItems = [];
  user: any;
  loading: boolean = false;
  apiPayload: any = {};
  ProductItemsInfo: any;
  AdminItemsInfo: any;
  SalesItemsInfo: any;
  displayItem: string = "Admin";
  adminToken : string="";
  MoudueTypes = ["-- select --", "Admin", "Production", "Sales"];
  AdminItems = [" -- select --", "Expeses", "RAW Materials", "Cash Flow(ALL cash used)"];
  salesItems = ["-- select --", "Expeses", "Transfer Amount to Bank", "Transfer Amount to Cash"];
  ProductItems = ["-- select --", "Expeses", "RAW Materials", "Requirement", "RAW Materials closing stock", "Cortons Closing Stock"];
  AllModuleList = ["Admin", "Production", "Sales"]
  displayModule: string = "Admin";
  selectModule: string = "";
  ItemID: string = "";

  // Admin varibles items
  adminExpessItems: Array<any> = [];
  adminRawmaterialItems: Array<any> = [];
  adminCashFlowItems: Array<any> = [];
  // production variables items
  productionExpessItems: Array<any> = [];
  productionRawmaterialItems: Array<any> = [];
  productionRequirementItems: Array<any> = [];
  productionRMCSItems: Array<any> = [];
  productionCCSlItems: Array<any> = [];
  // sales variables items
  salesExpesesItems: Array<any> = [];
  salesTrAmtBankItems: Array<any> = [];
  salesTrAmtCashItems: Array<any> = [];

  AccountItems: boolean = false;

  qvwItems : boolean = false;

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private adminservice: AdminService,
    private dialog: MatDialog,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    this.newitemForm = this.fb.group({
      moduletype: ["", Validators.required],
      itemtype: ["", Validators.required],
      itemname: ["", Validators.required],
      Quantity: ["0", Validators.required],
      value: ["1", Validators.required],
      water: ["0", Validators.required],
    })
    this.user = JSON.parse(sessionStorage.getItem('user'));
    this.adminToken = sessionStorage.getItem('token');
    //console.log(this.user);
    this.getAllItems()
    //this.newitemForm.patchValue({itemtype : "--select --"});
  }
  get f(): { [key: string]: AbstractControl } {
    return this.newitemForm.controls;
  }

  getAllItems() {
    let PayLoad = {
      userID: this.user.userID
    }
    this.adminservice.showLoader.next(true);
    this.adminservice.getAllUserItems(this.adminToken).subscribe(
      (posRes) => {
       // console.log(posRes);
        if (posRes.response == 3) {
          this.ProductItemsInfo = posRes.Info.ProductionAdminlist[0];
          this.AdminItemsInfo = posRes.Info.Adminlist[0];
          this.SalesItemsInfo = posRes.Info.SalesAdminlist[0]
        
          this.adminExpessItems = this.AdminItemsInfo.Expenses;
          this.adminRawmaterialItems = this.AdminItemsInfo.RAWMaterials;
          this.adminCashFlowItems = this.AdminItemsInfo.CashFlow;

          this.productionExpessItems = this.ProductItemsInfo.Expenses;
          this.productionRawmaterialItems = this.ProductItemsInfo.Rawmaterials;
          this.productionRequirementItems = this.ProductItemsInfo.Requirement;
          this.productionRMCSItems = this.ProductItemsInfo.RAWMaterialsclosingstock;
          this.productionCCSlItems = this.ProductItemsInfo.CortonsClosingStock;

          this.salesExpesesItems = this.SalesItemsInfo.ExpenseSaleslist;
          this.salesTrAmtBankItems = this.SalesItemsInfo.transferAmountToBank;
          this.salesTrAmtCashItems = this.SalesItemsInfo.transferAmountToCash;
          this.adminservice.showLoader.next(false);
          // this.openSnackBar(posRes.message, "");
        } else {
          this.openSnackBar(posRes.message, "");
          this.adminservice.showLoader.next(false);
        }
      },
      (err: HttpErrorResponse) => {
        this.adminservice.showLoader.next(false);
        if (err.error instanceof Error) {
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
  }

  changeMaduleTypes(items) {
    //console.log(items);
    if (this.listItems.length > 0) {
      this.listItems = [];
    }
    this.newitemForm.patchValue({ itemtype: "-- select --" })
    if (items == "Admin") {
      this.listItems = this.AdminItems;
    }
    if (items == "Production") {
      this.listItems = this.ProductItems;
    }
    if (items == "Sales") {
      this.listItems = this.salesItems;
    }
    if (items == "-- select --") {
      this.listItems.push('-- select --');
    }
    //this.listItems 
  }
  changeSubMaduleTypes(items) {
    let payload = {...this.newitemForm.value};
    if(payload.moduletype == "Admin" && items == "RAW Materials"){
      this.qvwItems = true;
    }else{
      this.qvwItems = false;
    }
    if (items == "Transfer Amount to Bank" || items == "Transfer Amount to Cash") {
      this.AccountItems = true;
    } else {
      this.AccountItems = false;
    }
  }
  setImgStyles1(product) {
    this.selectModule = product.BrandName;
    let style;
    if (this.displayModule == "" + product) {
      style = {
        "border": "3px solid #144158"
      }
    }
    return style;
  }
  displayItemInfo(product) {
    this.displayModule = "" + product;
    // this.newitemForm.patchValue({
    //   itemname :item.itemName,
    //   itemtype : TypeItem,
    //   moduletype : this.displayModule
    // })
  }
  viewupdateItem(item, itemType) {
    //console.log(item);
    //console.log(this.isupdated);
    if (!this.isupdated) {
      this.isupdated = true;
    }
    //console.log(this.isupdated);
    let TypeItem = "";

    switch (this.displayModule) {
      case "Admin":
        this.listItems = this.AdminItems;
        if (itemType == 'Expenses') {
          TypeItem = "Expeses";
          this.qvwItems = false;
        }
        if (itemType == 'RAWMaterials') {
          TypeItem = "RAW Materials";
          this.qvwItems = true;
        }
        if (itemType == 'CashFlow') {
          TypeItem = "Cash Flow(ALL cash used)";
          this.qvwItems = false;
        }
        break;
      case "Production":
        this.listItems = this.ProductItems;
        this.qvwItems = false;
        if (itemType == 'Expenses') {
          TypeItem = "Expeses";
        }
        if (itemType == 'Rawmaterials') {
          TypeItem = "RAW Materials";
        }
        if (itemType == 'Requirement') {
          TypeItem = "Requirement";
        }
        if (itemType == 'RAWMaterialsclosingstock') {
          TypeItem = "RAW Materials closing stock";
        }
        if (itemType == 'CortonsClosingStock') {
          TypeItem = "Cortons Closing Stock";
        }
        break;
      case "Sales":
        this.qvwItems = false;
        this.listItems = this.salesItems;
        if (itemType == 'Expenses') {
          TypeItem = "Expeses";
        }
        if (itemType == 'TransferToBank') {
          TypeItem = "Transfer Amount to Bank";
        }
        if (itemType == 'TransferToCash') {
          TypeItem = "Transfer Amount to Cash";
        }
        break;
    }
    if (itemType == 'TransferToBank' || itemType == 'TransferToCash') {
      // console.log(TypeItem);
      this.ItemID = item.accountID;
      this.AccountItems = true;
      this.newitemForm.patchValue({
        itemname: item.accountNumber,
        itemtype: TypeItem,
        moduletype: this.displayModule
      })
    } else {
      this.ItemID = item.itemID;
      this.AccountItems = false;
      // console.log(TypeItem);
      this.newitemForm.patchValue({
        itemname: item.itemName,
        itemtype: TypeItem,
        moduletype: this.displayModule,
        Quantity: item.Quantity,
        value: item.Value,
        water: item.Water,
      })
      document.getElementById("updbtn").focus();
    }

  }

  updateNewItem() {
    // this.openSnackBar("Update Item InProgress ", "");
    // this.isupdated = false;
    let payload = { ...this.newitemForm.value };
    if (this.newitemForm.valid) {
      if (payload.moduletype == "-- select --") {
        this.openSnackBar("Please select Module Types", "");
        this.formSubmit = false;
        this.loading = false;
        this.submitted = false;
      }
      if (payload.itemtype == "-- select --") {
        this.openSnackBar("Please select Items Types", "");
        this.formSubmit = false;
        this.loading = false;
        this.submitted = false;
      }
      if (this.formSubmit) {
        console.log(payload);
        let mtype = payload.moduletype;
        let itype = "" + payload.itemtype;
        let pmtype;
        this.apiPayload = {};
        // this.apiPayload["userID"] = this.user.userID;
        switch (mtype) {
          /* Adimn Module */
          case "Admin":
            pmtype = "Admin";
            this.apiPayload.moduletype = pmtype;
           // console.log(itype);
            switch (itype) {
              case "Expeses":
                let Expenses = [];
                let exobj = {
                  itemID: this.ItemID,
                  itemName: payload.itemname
                }
                Expenses.push(exobj);
                this.apiPayload.Expenses = Expenses;
                break;
              case "RAW\ Materials":
                let Rawmaterials = [];
                let rawobj = {
                  itemID: this.ItemID,
                  itemName: payload.itemname,
                  Quantity: Number(payload.Quantity),
                  Value: Number(payload.value),
                  Water: Number(payload.water)
                }
                Rawmaterials.push(rawobj);
                this.apiPayload.Rawmaterials = Rawmaterials;
                break;
              case "Cash\ Flow(ALL\ cash\ used)":
                let Cashflow = [];
                let cashobj = {
                  itemID: this.ItemID,
                  itemName: payload.itemname
                }
                Cashflow.push(cashobj);
                this.apiPayload.Cashflow = Cashflow;
                break;
            }
            break;

          /* Production Payload */
          case "Production":
            pmtype = "Production";
            this.apiPayload.moduletype = pmtype;
           // console.log(itype);
            switch (itype) {
              case "Expeses":
                let Expenses = [];
                let exobj = {
                  itemID: this.ItemID,
                  itemName: payload.itemname
                }
                Expenses.push(exobj);
                this.apiPayload.Expenses = Expenses;
                break;
              case "RAW\ Materials":
                let Rawmaterials = [];
                let rawobj = {
                  itemID: this.ItemID,
                  itemName: payload.itemname
                }
                Rawmaterials.push(rawobj);
                this.apiPayload.Rawmaterials = Rawmaterials;
                break;
              case "Requirement":
                let Requirement = [];
                let reqobj = {
                  itemID: this.ItemID,
                  itemName: payload.itemname
                }
                Requirement.push(reqobj);
                this.apiPayload.Requirement = Requirement;
                break;
              case "RAW\ Materials\ closing\ stock":
                let RAWMaterialsclosingstock = [];
                let rawMCobj = {
                  itemID: this.ItemID,
                  itemName: payload.itemname
                }
                RAWMaterialsclosingstock.push(rawMCobj);
                this.apiPayload.RAWMaterialsclosingstock = RAWMaterialsclosingstock;
                break;
              case "Cortons\ Closing\ Stock":
                let CortonsClosingStock = [];
                let ccsCobj = {
                  itemID: this.ItemID,
                  itemName: payload.itemname
                }
                CortonsClosingStock.push(ccsCobj);
                this.apiPayload.CortonsClosingStock = CortonsClosingStock;
                break;
            }
            break;

          /* Sales Pay Load */
          case "Sales":
            pmtype = "Sales";
            this.apiPayload.moduletype = pmtype;
            //console.log(itype);
            switch (itype) {
              case "Expeses":
                let Expenses = [];
                let exobj = {
                  itemID: this.ItemID,
                  itemName: payload.itemname
                }
                Expenses.push(exobj);
                this.apiPayload.Expenses = Expenses;
                break;
              case "Transfer Amount to Bank":
                let TransAmtToBank = [];
                let Accobj = {
                  accountID: this.ItemID,
                  accountNumber: payload.itemname
                }
                TransAmtToBank.push(Accobj);
                this.apiPayload.transferAmountToBank = TransAmtToBank;
                break;
              case "Transfer Amount to Cash":
                let TransAmtToCash = [];
                let acc1obj = {
                  accountID: this.ItemID,
                  accountNumber: payload.itemname
                }
                TransAmtToCash.push(acc1obj);
                this.apiPayload.transferAmountToCash = TransAmtToCash;
            }
            break;
        }

        console.log(this.apiPayload);
        this.apiPayload.itemID = this.ItemID;
        this.adminservice.showLoader.next(true);
        this.adminservice.UpdateItems(this.apiPayload).subscribe(
          (posRes) => {
           // console.log(posRes);
            if (posRes.response == 3) {
              this.loading = false;
              this.submitted = false;
              this.isupdated = false;
              this.AccountItems = false;
              this.openSnackBar(posRes.message, "");
              this.formPatchValues();
              this.adminservice.showLoader.next(false);
              this.getAllItems();
            } else {
              this.loading = false;
              this.submitted = false;
              this.openSnackBar(posRes.message, "");
              this.formPatchValues();
              this.adminservice.showLoader.next(false);
            }
          },
          (err: HttpErrorResponse) => {
            this.loading = false;
            this.submitted = false;
            // console.log("Set Profile", err);
            if (err.error instanceof Error) {
              this.openSnackBar(err.message, "Client Error");
              this.adminservice.showLoader.next(false);
              console.log(err.message);
            } else {
              this.openSnackBar(err.message, "Server Error");
              this.adminservice.showLoader.next(false);
              console.log(err.message);
            }
          })
      }

    }
   // console.log(payload);
  }
  addnewitem() {
    if (this.isupdated) {
      this.updateNewItem()
    } else {
      this.submitted = true;
      this.loading = true;
      let payload = { ...this.newitemForm.value }
      //console.log(payload)
      if (this.newitemForm.valid) {
        if (payload.moduletype == "-- select --") {
          this.openSnackBar("Please select Module Types", "");
          this.formSubmit = false;
          this.loading = false;
          this.submitted = false;
        }
        if (payload.itemtype == "-- select --") {
          this.openSnackBar("Please select Items Types", "");
          this.formSubmit = false;
          this.loading = false;
          this.submitted = false;
        }
        if (this.formSubmit) {
         // console.log(payload);
          let mtype = payload.moduletype;
          let itype = "" + payload.itemtype;
          let pmtype;
          this.apiPayload = {};
          // this.apiPayload["userID"] = this.user.userID;
          switch (mtype) {
            /* Adimn Module */
            case "Admin":
              pmtype = "Admin";
              this.apiPayload.moduletype = pmtype;
             // console.log(itype);
              switch (itype) {
                case "Expeses":
                  let Expenses = [];
                  // let its = payload.itemname;
                  // let items = its.split(", ");
                  // //console.log(items);
                  // for(let i=0;i<items.length;i++){
                  //   let exobj = {
                  //     itemID: "Expid@" + Math.random().toString(36).substr(2, 9),
                  //     itemName: items[i]
                  //   }
                  //   Expenses.push(exobj);
                  // }
                  let exobj = {
                    itemID: "Expid@" + Math.random().toString(36).substr(2, 9),
                    itemName: payload.itemname
                  }
                  Expenses.push(exobj);
                  this.apiPayload.Expenses = Expenses;
                  break;
                case "RAW\ Materials":
                  let Rawmaterials = [];
                  let rawobj = {
                    itemID: "Rawmat@" + Math.random().toString(36).substr(2, 9),
                    itemName: payload.itemname,
                    Quantity: Number(payload.Quantity),
                    Value: Number(payload.value),
                    Water: Number(payload.water)
                  }
                  Rawmaterials.push(rawobj);
                  this.apiPayload.Rawmaterials = Rawmaterials;
                  break;
                case "Cash\ Flow(ALL\ cash\ used)":
                  let Cashflow = [];
                  let cashobj = {
                    itemID: "cashflow@" + Math.random().toString(36).substr(2, 9),
                    itemName: payload.itemname
                  }
                  Cashflow.push(cashobj);
                  this.apiPayload.Cashflow = Cashflow;
                  break;
              }
              break;

            /* Production Payload */
            case "Production":
              pmtype = "Production";
              this.apiPayload.moduletype = pmtype;
              //console.log(itype);
              switch (itype) {
                case "Expeses":
                  let Expenses = [];
                  let exobj = {
                    itemID: "Expid@" + Math.random().toString(36).substr(2, 9),
                    itemName: payload.itemname
                  }
                  Expenses.push(exobj);
                  this.apiPayload.Expenses = Expenses;
                  break;
                case "RAW\ Materials":
                  let Rawmaterials = [];
                  let rawobj = {
                    itemID: "Rawmat@" + Math.random().toString(36).substr(2, 9),
                    itemName: payload.itemname
                  }
                  Rawmaterials.push(rawobj);
                  this.apiPayload.Rawmaterials = Rawmaterials;
                  break;
                case "Requirement":
                  let Requirement = [];
                  let reqobj = {
                    itemID: "Requir@" + Math.random().toString(36).substr(2, 9),
                    itemName: payload.itemname
                  }
                  Requirement.push(reqobj);
                  this.apiPayload.Requirement = Requirement;
                  break;
                case "RAW\ Materials\ closing\ stock":
                  let RAWMaterialsclosingstock = [];
                  let rawMCobj = {
                    itemID: "Rawmatcs@" + Math.random().toString(36).substr(2, 9),
                    itemName: payload.itemname
                  }
                  RAWMaterialsclosingstock.push(rawMCobj);
                  this.apiPayload.RAWMaterialsclosingstock = RAWMaterialsclosingstock;
                  break;
                case "Cortons\ Closing\ Stock":
                  let CortonsClosingStock = [];
                  let ccsCobj = {
                    itemID: "ccstock@" + Math.random().toString(36).substr(2, 9),
                    itemName: payload.itemname
                  }
                  CortonsClosingStock.push(ccsCobj);
                  this.apiPayload.CortonsClosingStock = CortonsClosingStock;
                  break;
              }
              break;

            /* Sales Pay Load */
            case "Sales":
              pmtype = "Sales";
              this.apiPayload.moduletype = pmtype;
              //console.log(itype);
              switch (itype) {
                case "Expeses":
                  let Expenses = [];
                  let exobj = {
                    itemID: "Expid@" + Math.random().toString(36).substr(2, 9),
                    itemName: payload.itemname
                  }
                  Expenses.push(exobj);
                  this.apiPayload.Expenses = Expenses;
                  break;
                case "Transfer Amount to Bank":
                  let TransAmtToBank = [];
                  let Accobj = {
                    accountID: "accibd@" + Math.random().toString(36).substr(2, 9),
                    accountNumber: payload.itemname
                  }
                  TransAmtToBank.push(Accobj);
                  this.apiPayload.transferAmountToBank = TransAmtToBank;
                  break;
                case "Transfer Amount to Cash":
                  let TransAmtToCash = [];
                  let acc1obj = {
                    accountID: "accibd@" + Math.random().toString(36).substr(2, 9),
                    accountNumber: payload.itemname
                  }
                  TransAmtToCash.push(acc1obj);
                  this.apiPayload.transferAmountToCash = TransAmtToCash;
              }
              break;
          }
          this.adminservice.showLoader.next(true);
         // console.log(this.apiPayload);
          this.adminservice.InsertItems(this.apiPayload).subscribe(
            (posRes) => {
              console.log(posRes);
              if (posRes.response == 3) {
                this.loading = false;
                this.submitted = false;
                this.openSnackBar(posRes.message, "");
                this.formPatchValues();
                this.adminservice.showLoader.next(false);
                this.getAllItems();
              } else {
                this.loading = false;
                this.submitted = false;
                this.openSnackBar(posRes.message, "");
                this.formPatchValues();
                this.adminservice.showLoader.next(false);
              }
            },
            (err: HttpErrorResponse) => {
              this.loading = false;
              this.submitted = false;
              // console.log("Set Profile", err);
              if (err.error instanceof Error) {
                this.adminservice.showLoader.next(false);
                this.openSnackBar(err.message, "Client Error");
                console.log(err.message);
              } else {
                this.adminservice.showLoader.next(false);
                this.openSnackBar(err.message, "Server Error");
                console.log(err.message);
              }
            })
        } else {
         // console.log(payload);
          this.formSubmit = true;
         // console.log("Please select any type");
        }

      } else {
       // console.log(JSON.stringify(this.newitemForm.value))
      }
    }
  }
  DeleteItem(item, itemType, moduleType) {
    // console.log(item);
    // console.log(itemType);
    // console.log(moduleType);
    let acid = "";
    let itemnames = "";
    if(itemType == 'TransferToBank' || itemType == 'TransferToCash' ){
      acid = item.accountID;
      itemnames = item.accountNumber
    }else{
      acid = item.itemID;
      itemnames = item.itemName
    }
    //userID: this.user.userID,
    let deleteapiPayLoad = {  
      itemID: acid,
      moduletype: moduleType
    }
    switch (moduleType) {
      /* Adimn Module */
      case "Admin":
        switch (itemType) {
          case "Expenses":
            let Expenses = [];
            let exobj = {
              itemID: item.itemID,
              itemName: item.itemName
            }
            Expenses.push(exobj);
            deleteapiPayLoad["Expenses"] = Expenses;
            break;
          case "RAWMaterials":
            let Rawmaterials = [];
            let rawobj = {
              itemID: item.itemID,
              itemName: item.itemName
            }
            Rawmaterials.push(rawobj);
            deleteapiPayLoad["Rawmaterials"] = Rawmaterials;
            break;
          case "CashFlow":
            let Cashflow = [];
            let cashobj = {
              itemID: item.itemID,
              itemName: item.itemName
            }
            Cashflow.push(cashobj);
            deleteapiPayLoad["Cashflow"] = Cashflow;
            break;
        }
        break;

      /* Production Payload */
      case "Production":
        switch (itemType) {
          case "Expenses":
            let Expenses = [];
            let exobj = {
              itemID: item.itemID,
              itemName: item.itemName
            }
            Expenses.push(exobj);
            deleteapiPayLoad["Expenses"] = Expenses;
            break;
          case "Rawmaterials":
            let Rawmaterials = [];
            let rawobj = {
              itemID: item.itemID,
              itemName: item.itemName
            }
            Rawmaterials.push(rawobj);
            deleteapiPayLoad["Rawmaterials"] = Rawmaterials;
            break;
          case "Requirement":
            let Requirement = [];
            let reqobj = {
              itemID: item.itemID,
              itemName: item.itemName
            }
            Requirement.push(reqobj);
            deleteapiPayLoad["Requirement"] = Requirement;
            break;
          case "RAWMaterialsclosingstock":
            let RAWMaterialsclosingstock = [];
            let rawMCobj = {
              itemID: item.itemID,
              itemName: item.itemName
            }
            RAWMaterialsclosingstock.push(rawMCobj);
            deleteapiPayLoad["RAWMaterialsclosingstock"] = RAWMaterialsclosingstock;
            break;
          case "CortonsClosingStock":
            let CortonsClosingStock = [];
            let ccsCobj = {
              itemID: item.itemID,
              itemName: item.itemName
            }
            CortonsClosingStock.push(ccsCobj);
            deleteapiPayLoad["CortonsClosingStock"] = CortonsClosingStock;
            break;
        }
        break;

      /* Sales Pay Load */
      case "Sales":
        switch (itemType) {
          case "Expenses":
            let Expenses = [];
            let exobj = {
              itemID: item.itemID,
              itemName: item.itemName
            }
            Expenses.push(exobj);
            deleteapiPayLoad["Expenses"] = Expenses;
            break;
          case "TransferToBank":
            let TransAmtToBank = [];
            let Accobj = {
              accountID: item.accountID,
              accountNumber: item.accountNumber
            }
            TransAmtToBank.push(Accobj);
            deleteapiPayLoad["transferAmountToBank"] = TransAmtToBank;
            break;
          case "TransferToCash":
            let TransAmtToCash = [];
            let acc1obj = {
              accountID: item.accountID,
              accountNumber: item.accountNumber
            }
            TransAmtToCash.push(acc1obj);
            deleteapiPayLoad["transferAmountToCash"] = TransAmtToCash;
        }
        break;
    }
    let dailogRef = this.dialog.open(ItemDeleteComponent, {
      panelClass: "col-md-6",
      hasBackdrop: true,
      disableClose: true,
      data : { apidata : deleteapiPayLoad, itemName :  itemnames }
    });
    dailogRef.afterClosed().subscribe((res) => {
      //console.log(res.bid);
      if (res.isDelete) {
        this.adminservice.showLoader.next(true);
        this.adminservice.DeletNewItemAdmin(res.bid).subscribe((posRes) => {      
          if (posRes.response == 3) {
            this.openSnackBar(posRes.message, "");  
            this.adminservice.showLoader.next(false);
            this.formPatchValues();
            this.getAllItems();
          } else {
            this.adminservice.showLoader.next(false);
            this.openSnackBar(posRes.message, "");      
          }
        }, (err: HttpErrorResponse) => {
          
          this.openSnackBar(err.message, "");
          this.adminservice.showLoader.next(false);
          if (err.error instanceof Error) {
            console.warn("Client SIde Error", err.error);
          } else {
            console.warn("Server Error", err.error);
          }
        })
      }
    })

   // console.log(deleteapiPayLoad);
  }
  formPatchValues() {
    if(this.qvwItems){
      this.newitemForm.patchValue({
        moduletype: "--select --",
        itemtype: "--select --",
        itemname: "",
        Quantity:"0",
        value:"1",
        water:"0"
      })
    }
    this.newitemForm.patchValue({
      moduletype: "--select --",
      itemtype: "--select --",
      itemname: ""
    })
  }

  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
      verticalPosition: "top",
    });
  }

}
