import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-amount-given',
  templateUrl: './amount-given.component.html',
  styleUrls: ['./amount-given.component.css']
})
export class AmountGivenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
