import { Component, OnInit } from '@angular/core';
import {trigger,transition,style,animate} from '@angular/animations'
import {Subject} from 'rxjs'
import { HttpErrorResponse } from '@angular/common/http';
import { AdminService } from 'src/app/admin.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar,MatDialog } from '@angular/material';
import { NgbCalendar,  NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']  
})
export class DashboardComponent implements OnInit {
   hidden = false;
   loading:boolean=false;
  data:any
  loading$=new Subject<boolean>()
  startDate = new Date(2000, 0, 2);
  maxDate1: any = new Date();
  bookingsForm1: FormGroup;
  DateFilter1: any = []; 
  OpenStock: any =2;
  Production: any=5;
  StockOut: any=2;
  ClosingStock: any=4;
  CustomersOpeningCash : string ="";
  toggleBadgeVisibility() {
    this.hidden = !this.hidden;
  } 
  depositbadge: number;
  salesbadge: number;
  productreqbadge: number;
  refresh_btn:boolean = false;
  

  constructor(private adminService: AdminService, private router: Router, 
    private dialog: MatDialog,
    private fb: FormBuilder, private snackBar: MatSnackBar,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,) { }

  ngOnInit() {    
    this.bookingsForm1 = this.fb.group({
      dateRange1: ["", Validators.required]
    })
    this.getNotificationDate();  
    
  }
  getNotificationDate(){
    let dt = this.formatDate();
    //console.log(dt);
    let payload = {
      date : dt
    }   
    this.getDashboardApi(payload);
   // console.log("22222",payload);
   
  }
  
  private formatDate() {
    const d = new Date();
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }
  clearInputMethod() {
    this.bookingsForm1.setValue({dateRange1: "" });
  }

  getDashboardApi(payload){
    this.adminService.dashboard(payload).subscribe((res) => {
      if (res.response == 3) {
        this.OpenStock = res.openStock;
        this.Production = res.Production;
        this.StockOut = res.stockOut;
        this.ClosingStock = res.closingStock;
        //Badges
        this.depositbadge = res.DepositeApproveNotificationCounts;
        this.salesbadge = res.salesApproveNotificationCounts;
        this.productreqbadge = res.RequirementNotificationCounts;
        this.refresh_btn = false;
        // 
        this.CustomersOpeningCash = res.CustomersOpeningCash;
       
        localStorage.setItem("dropdownitems",JSON.stringify( res.DropDownItems[0]));
        //this.recivedCash = res.
       // console.log("data",res.openStock,res.Production,res.stockOut,res.closingStock,this.depositbadge,this.salesbadge, this.productreqbadge);
        // this.openSnackBar(res.message, "")       
      }
      else {
        this.openSnackBar(res.message, "")        
      }
      this.DateFilter1 = [];
    }, (err: HttpErrorResponse) => {
      this.adminService.showLoader.next(false);     
      if (err.error instanceof Error) {
        console.warn("CSError", err.error)
      } else {
        console.warn("SSError", err.error)
      }
    })
  }
  getBookingByRange1() {    
    let frmDate = this.bookingsForm1.value;   
    console.log("frmdate",frmDate);  
    let yy = frmDate.dateRange1.getFullYear();
    let mm =  frmDate.dateRange1.getMonth();
    let dd = frmDate.dateRange1.getDate();
    //for month
    let m1="";
    if(Number(mm) >9){
      m1= ""+mm;
    }else{
      let m2=Number(mm)+1;
      m1="0"+m2;
    }
     //for date
     let d1="";
     if(Number(dd) >9){
       d1= ""+dd;
     }else{
       let d2=Number(dd);
       d1="0"+d2;
     }
   // console.log(mm);
 //   console.log(dd);
   
    let payload = {
      date : ""+yy+"-"+m1+"-"+d1
    }
   // console.log(payload);
    this.getDashboardApi(payload);
  } 

  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }
  getNotificationDate1(){
    let dt = this.formatDate();
    //console.log(dt);
    let payload = {
      date : dt
    }   
    this.getDashboardApi1(payload);
    //console.log("22222",payload);
   
  }
  
  reset(){
    this.refresh_btn = true;
    this.getNotificationDate1();
   // this.refresh_btn = false;
  }
  Today(){
    this.loading = true;
    this.clearInputMethod();
    this.getNotificationDate1();    
  }

  getDashboardApi1(payload){
    this.adminService.dashboard(payload).subscribe((res) => {
      if (res.response == 3) {        
        this.OpenStock = res.openStock;
        this.Production = res.Production;
        this.StockOut = res.stockOut;
        this.ClosingStock = res.closingStock;
        //Badges
        this.depositbadge = res.DepositeApproveNotificationCounts;
        this.salesbadge = res.salesApproveNotificationCounts;
        this.productreqbadge = res.RequirementNotificationCounts;
        this.refresh_btn = false;

        this.loading = false;
       // console.log("data",res.openStock,res.Production,res.stockOut,res.closingStock,this.depositbadge,this.salesbadge, this.productreqbadge);
        //  this.openSnackBar(res.message, "")       
      }
      else {
        this.openSnackBar(res.message, "")    ;
        this.loading = false;    
      }
      this.DateFilter1 = [];
    }, (err: HttpErrorResponse) => {
      this.adminService.showLoader.next(false);     
      if (err.error instanceof Error) {
        console.warn("CSError", err.error)
      } else {
        console.warn("SSError", err.error)
      }
    })
  }
}
