import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  public baseUrl: string = "https://dev-api.bhavgroup.com";
  stagingUrl: string = "https://dev-api.bhavgroup.com"
  showLoader = new BehaviorSubject(false);
  public categoriesList: any = {};
  public checkIsLoggedIn = new BehaviorSubject(false);
  public callLogOut = new BehaviorSubject(false);
  public isAdminLoggedIn = new BehaviorSubject(false);
  constructor(private http: HttpClient) { }


  // BG Admin  Api Callings starts
  adminLogin(data) {
    return this.http.post(`${this.baseUrl}/api/customer/customerlogin`, data)
  }
  getorders(data): any {
    return this.http.post(`${this.baseUrl}/api/order/ordersfetch`, data)
  }

  getproducts(): any {
    return this.http.get(`${this.baseUrl}/api/adminproducts/fetchProducts`)
  }
  requirmentsinsert(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/requirements/insertrequirements`, data)
  }
  InsertClient(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/customer/customer&adminRegister`, data)
  }
  UpdateClient(data): Observable<any> {
    return this.http.put(`${this.baseUrl}/api/customer/customer&adminRegister`, data)
  }
  UpdateVendor(data): Observable<any> {
    return this.http.put(`${this.baseUrl}/api/vendor/vendorRegister`, data)
  }
  InsertProductionPerson(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/sale/insertproduction`, data)
  }
  updateProductionPerson(data): Observable<any> {
    return this.http.put(`${this.baseUrl}/api/sale/insertproduction`, data)
  }
  fetchAllClients(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/fetchbgdata`, data)
  }
  dashboard(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/admindashboard`, data)
  }
  InsertSalesPerson(data) : Observable<any> {
    return this.http.post(`${this.baseUrl}/api/sale/insertsales`, data)
  }
  searchByName(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/searchclient`, data)
  }
  adminorder(data): Observable<any> {
    return this.http.post(`${this.baseUrl}/api/admin/fetchbgdata`,data)
  }
  updateClientOrderStatus(data) : Observable<any> {
    return this.http.put(`${this.baseUrl}/api/admin/adminapproveclientorders`,data)
  }
  InsertProduct(data) : Observable<any> {
    return this.http.put(`${this.baseUrl}/api/adminproducts/adminproductinsert`,data)
  }
  updateClientOrderupdate(data) : Observable<any> {
    return this.http.put(`${this.baseUrl}/api/order/ordersupdate`,data)
  }
  getrequirments(data):Observable<any> {
    return this.http.post(`${this.baseUrl}/api/requirements/fetchrequirement`,data)
  }
  getexpenses(data):Observable<any> {
    return this.http.post(`${this.baseUrl}/api/expense/fetchexpenses`,data)
  }
  expenses(data):Observable<any> {
    return this.http.post(`${this.baseUrl}/api/expense/insertexpense`,data)
  }
  updateexpenses(data):Observable<any> {
    return this.http.put(`${this.baseUrl}/api/expense/updateexpense`,data)
  }
  deleteexpense(data):any{
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        userID:data.userID,
        type:data.type

      }
    }
    return this.http.delete(`${this.baseUrl}/api/expense/deleteexpense`,options)
  }

  require(data):Observable<any> {
    return this.http.post(`${this.baseUrl}/api/requirements/insertrequirements`,data)
  }
  getSalesClientBalance(data):Observable<any> {
    return this.http.post(`${this.baseUrl}/api/customer/clientbalance`,data)
  }
  updaterequire(data):Observable<any> {
    return this.http.put(`${this.baseUrl}/api/requirements/updaterequirements`,data)
  }

  deleterequire(data) : Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        userID: data.userID,
        type:data.type
      }
    }
    return this.http.delete(`${this.baseUrl}/api/requirements/deleterequirements`, options)
  }

  DeleteByUser(data) : Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        "userID": data.userID
      }
    }
    return this.http.delete(`${this.baseUrl}/api/customer/customerdelete`, options)
  }
  fetchtransferamount(): any {
    return this.http.get(`${this.baseUrl}/api/amountransferoffice/fetchtransferamount`)
  }
  getAllbranch(): any {
    return this.http.get(`${this.baseUrl}/api/sale/fetchbranch`)
  }
  getAllUserItems(token) : any {
    return this.http.get(`${this.baseUrl}/api/production/fetchItems`,{ headers: { bgapp : token } })
  }
  fetchAllVendors(): any {
    return this.http.get(`${this.baseUrl}/api/admin/withvendorsdata`)
  }
  updateDipositStatus(data): any {
    return this.http.put(`${this.baseUrl}/api/admin/adminapprovedeposit`,data)
  }
  ClosingBalanceSheet(data): Observable<any> {
    return  this.http.post(`${this.baseUrl}/api/sale/closebalance`,data)
  }
  adminbrandinsert(data):Observable<any> {
    return this.http.post(`${this.baseUrl}/api/adminproducts/adminbrandinsert`,data)
  }
  InsertBranch(data) :Observable<any> {
    return this.http.post(`${this.baseUrl}/api/sale/insertbranch`,data)
  }
  getprocure(data) :Observable<any> {
    return this.http.post(`${this.baseUrl}/api/procurement/fetchprocurement`,data)
  }
  procurements(data) :Observable<any> {
    return this.http.post(`${this.baseUrl}/api/procurement/insertprocurement`,data)
  }
  updateprocure(data): any {
    return this.http.put(`${this.baseUrl}/api/procurement/updateprocurement`,data)
  }

  deleteprocure(data) : Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        procurementID: data.procurementID,
      }
    }
    return this.http.delete(`${this.baseUrl}/api/procurement/deleteprocurement`, options)
  }
  UpdateClientOrder(data) :Observable<any> {
    return this.http.put(`${this.baseUrl}/api/order/ordersupdate`,data)
  }

  DeleteByBrand(data) : Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        "BrandName": data.BrandName
      }
    }
    return this.http.delete(`${this.baseUrl}/api/adminproducts/deleteBrand`, options)
  }
  InsertClientOrder (data) :Observable<any> {
    return this.http.post(`${this.baseUrl}/api/order/ordersinsert`,data)
  }
  getItems(data):Observable<any> {
    return this.http.post(`${this.baseUrl}/api/adminlist/fetchadminlist`,"")
  }
  getReportsInfo(apiurl): any {
    return this.http.get(`${this.baseUrl}/api/admin/`+apiurl);
  }
  getSalesBranchReportsInfo(apiurl): any {
    console.log(apiurl);
    return this.http.get(`${this.baseUrl}/api/admin/`+apiurl);
  }
  downloadExcelReports(apiurl): any {
    
    return this.http.get(`${this.baseUrl}/api/admin/`+apiurl,{ responseType: 'blob'});
  }
  InsertItems(data) :Observable<any> {
    return this.http.post(`${this.baseUrl}/api/production/itemslistinsert`,data)
  }
  UpdateProduct(data) : Observable<any> {
    return this.http.put(`${this.baseUrl}/api/adminproducts/adminproductupdate`,data)
  }
  getClientInfo(data) : Observable<any> {
    return this.http.post(`${this.baseUrl}/api/customer/customerDatafetch`,data)
  }
  getVendorInfo(data) : Observable<any> {
    return this.http.post(`${this.baseUrl}/api/vendor/vendorfetch`,data)
  }
  UpdateBranch(data)  : Observable<any> {
    return this.http.post(`${this.baseUrl}/api/sale/updatebranch`,data)
  }
  DeleteBranch(data)  : Observable<any> {
    return this.http.post(`${this.baseUrl}/api/sale/deletebranch`,data)
  }
  DeleteProduct(data) : Observable<any> {
    return this.http.put(`${this.baseUrl}/api/adminproducts/deleteproduct`,data)
  }
  getAllItemsinfo(data)  : Observable<any> {
    return this.http.post(`${this.baseUrl}/api/production/itemslistfetch`,data)
  }
  UpdateItems(data) : Observable<any> {
    return this.http.put(`${this.baseUrl}/api/production/itemslistupdate`,data)
  }
  // BG Admin  Api Callings starts
  getb2b(data):Observable<any> {
    return this.http.post(`${this.baseUrl}/api/b2b/fetchb2b`,data)
  }
  
  b2binsert(data):Observable<any> {
    return this.http.post(`${this.baseUrl}/api/b2b/insertb2b`,data)
  }
  updateb2b(data) : Observable<any> {
    return this.http.put(`${this.baseUrl}/api/b2b/updateb2b`,data)
  }
  deleteb2b(data) : Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    }
    return this.http.delete(`${this.baseUrl}/api/production/itemslistdelete`,options)
  }
  DeleteByVendor(data) : Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    }
    return this.http.delete(`${this.baseUrl}/api/vendor/vendordelete`,options)
  }
  DeletItem(data) : Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    }
    return this.http.delete(`${this.baseUrl}/api/b2b/deleteb2b`, options)
  }
  DeletNewItemAdmin(data) : Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: data
    }
    return this.http.delete(`${this.baseUrl}/api/production/itemslistdelete`,options)
  }
  VendarInsert(data):Observable<any> {
    return this.http.post(`${this.baseUrl}/api/vendor/vendorRegister`,data)
  }
}
