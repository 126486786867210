import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AddVendorsComponent } from '../add-vendors/add-vendors.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../admin.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DeleteBgpersonsComponent } from '../delete-bgpersons/delete-bgpersons.component';
import { Router } from '@angular/router';
import { UpdateVendorComponent } from '../update-vendor/update-vendor.component';

@Component({
  selector: 'app-all-vendors',
  templateUrl: './all-vendors.component.html',
  styleUrls: ['./all-vendors.component.css']
})
export class AllVendorsComponent implements OnInit {
  searchByNameForm: FormGroup;
  baseUrl: string = "";
  salesPersonObj: any = null;
  pageNo: number = 1;
  isSearchByName: boolean = false;
  filterdSalesperson : Array<any> = [];
  customerSubscribe: any;
  message: string = "Finding Sales Person";
  salesPerson: Array<any> = [];
  totalPageCount: number = 1;
  pageNumbers: Array<any> = [];
  isTotalCountReached: boolean = false;
  isFetchingSales: boolean = false;
  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private adminService : AdminService,
    private snackBar: MatSnackBar,
    private router : Router
  ) { }

  ngOnInit() {
    this.baseUrl = "https://dev-api.bhavgroup.com"
    this.salesPersonObj = {
      type: "5",
      pageNo: "" + this.pageNo,
      size: "15",
    }
    this.searchByNameForm = this.fb.group({
      searchText: ["", Validators.required],
      type : ["5"],
      pageNo: ["1"],
      size: ["15"]
    })
    this.fetchAllSalesPerson();
  }
  fetchAllSalesPerson(){
    this.salesPersonObj.pageNo = "" + this.pageNo;
    this.isSearchByName = false;
    this.filterdSalesperson =[];
    this.adminService.showLoader.next(true);
     // console.log(this.customerObj);
     this.customerSubscribe = this.adminService.fetchAllVendors().subscribe((posRes) => {
      // console.log("All Users", posRes);
      this.message = "No Vendor Person Found.."
      this.adminService.showLoader.next(false);
      if (posRes.response == 3) {
        this.salesPerson = posRes.withvendorsData;
        this.totalPageCount = 1;
        for (let i: number = 0; i < this.totalPageCount; i++) {
          this.pageNumbers.push(i + 1);
        }
        if (this.totalPageCount <= this.pageNo) {
          this.isTotalCountReached = true;
        } else {
          this.isTotalCountReached = false;
        }
       // let parentElm = document.getElementById('main-wrap');
        //let  currentScrollPositio = parentElm.pageYOffset;
       //  let currentScrollPosition = parentElm.scrollTop;
        //// console.log("Yposition",currentScrollPosition);
        // this.filterdCustomers = this.filterdCustomers.concat(this.customers);
        this.filterdSalesperson = this.salesPerson
        this.isFetchingSales = false;
        //  parentElm.scrollTop = currentScrollPosition;
        // parentElm.scrollTop = 0;
      } else {
        this.openSnackBar(posRes.message, "");
        this.pageNo = 1;
        this.isTotalCountReached = true;
        this.totalPageCount =1;
        // this.pageNo = this.pageNo - 1;
        this.isFetchingSales = false;
      }
    }, (err: HttpErrorResponse) => {
      this.message = "No Vendor Person Found.."
      this.openSnackBar(err.message, "")
      this.pageNo = this.pageNo - 1;
      this.isFetchingSales = false;
      this.adminService.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }

  searchByName() {
    if (this.searchByNameForm.valid) {
      this.filterdSalesperson = []
      this.pageNo = 1;
      this.isSearchByName = true;
      this.getSalesSearchByName();
    } else {
      this.fetchAllSalesPerson();
      //this.openSnackBar("Enter First Name..", "")
    }
  }


  getSalesSearchByName(){
    this.adminService.showLoader.next(true);
    this.message = 'Finding Vendor Person..'
    this.isFetchingSales = true;
    this.searchByNameForm.patchValue({
      pageNo: "" + this.pageNo
    })
    this.isSearchByName = true;
    //let token = sessionStorage.getItem('token');
    let payload = { ...this.searchByNameForm.value };
    // payload.type="Users";
    // console.log(this.searchByNameForm.value);
    this.adminService.searchByName(payload).subscribe((posRes) => {
      // console.log("All Users", posRes);
      this.pageNumbers = [];
      this.adminService.showLoader.next(false);
      this.message = "No Vendor Person Found.."
      
      if (posRes.response == 3) {
        this.salesPerson = posRes.FetchData;
        if(this.salesPerson.length>0){
          this.totalPageCount = posRes.pages;
          for (let i: number = 0; i < this.totalPageCount; i++) {
            this.pageNumbers.push(i + 1);
          }
          if (this.totalPageCount <= this.pageNo) {
            this.isTotalCountReached = true;
          } 
          else {
            this.isTotalCountReached = false;
          }       
          this.filterdSalesperson = this.salesPerson;
          // this.filterdCustomers = this.filterdCustomers.concat(this.customers);
          this.isFetchingSales = false;
        }else{
          this.totalPageCount = 1;
          this.filterdSalesperson = this.salesPerson;
          this.isTotalCountReached = true;
        }
        
      
      } else {
        this.openSnackBar(posRes.message, "")
        this.pageNo = this.pageNo - 1;
        this.isFetchingSales = false;        
      }
    }, (err: HttpErrorResponse) => {
      this.message = "No Users Found.."
      this.openSnackBar(err.message, "")
      this.pageNo = this.pageNo - 1;
      this.isFetchingSales = false;
      this.adminService.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }
  SalesInsert(){
    let dialogRef = this.dialog.open(AddVendorsComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
       // this.filterdCustomers = [];
       //  this.customerObj = res;
        // console.log(this.customerObj);
        this.pageNo = 1;
        this.fetchAllSalesPerson();
      }
    })
  }
  DeleteSalesPersonInfo(client){
    let dialogRef = this.dialog.open(DeleteBgpersonsComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true,
      data : client
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res.isDelete) {
        this.deleteAccountUser(res);
        //this.fetchAllClients();
      }
    })
  }
  viewSalespersonInfo(saleInfo){
    this.router.navigate(['/admin/viewVendorInfo',btoa(JSON.stringify(saleInfo.userID))]);
  }
  EditSalespersonInfo(salespersonInfo){
    let dialogRef = this.dialog.open(UpdateVendorComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true,
      data : salespersonInfo
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        // this.filterdCustomers = [];
        // this.customerObj = res;
        // console.log(this.customerObj);
        this.fetchAllSalesPerson();
      }
    })
  }
  deleteAccountUser(res){
    let payload ={
      userID : res.userID
    }
    this.adminService.DeleteByVendor(payload).subscribe((posRes) => {
      // console.log("All Users", posRes);
      if (posRes.response == 3) {
        if(this.isSearchByName){
          this.getSalesSearchByName();
        }else{
          this.fetchAllSalesPerson();
        }
      } else {
        this.openSnackBar(posRes.message, "");      
      }
    }, (err: HttpErrorResponse) => {
      
      this.openSnackBar(err.message, "");
      this.adminService.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }
  gotoSelectedPage(num) {
    this.pageNo = num;
    if (!this.isSearchByName) {
      this.fetchAllSalesPerson();
    } else {
      this.getSalesSearchByName();
       // this.ProfileNotUpdateUserNext_prev()
    }
  }
  previousPage() {
    this.pageNo = this.pageNo - 1;
    if (this.totalPageCount <= this.pageNo) {
      this.isTotalCountReached = true;
    } else {
      this.isTotalCountReached = false;
    }
    if (!this.isSearchByName) {
      this.fetchAllSalesPerson();
    } else {
      this.getSalesSearchByName();
      // this.ProfileNotUpdateUserNext_prev();
    }

  }
  loadMore() {
    this.pageNo = this.pageNo + 1;
    if (this.totalPageCount <= this.pageNo) {
      this.isTotalCountReached = true;
    } else {
      this.isTotalCountReached = false;
    }
    if (!this.isSearchByName) {
      this.fetchAllSalesPerson()
    } else {
      this.getSalesSearchByName();
      // this.getUsersByName()
    }
  }
  ngOnDestroy() {
    this.customerSubscribe.unsubscribe();
  }

  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }

}
