import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatSnackBar } from "@angular/material";
import { SignoutComponent } from '../signout/signout.component';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  AllModules = [
    { imgpath : "../../../assets/dashboard/bank.png" , link : "/admin/dashboard",},
    { imgpath : "../../../assets/dashboard/raw_material.png" , link : "/admin/dashboard"},
    { imgpath : "../../../assets/dashboard/New Order.png" , link : "/admin/dashboard"},
    { imgpath : "../../../assets/dashboard/expenses.png" , link : "/admin/dashboard"},
    { imgpath : "../../../assets/dashboard/Procurement.png" , link : "/admin/dashboard"},
    { imgpath : "../../../assets/dashboard/closing balance.png" , link : "/admin/dashboard"},
    // { imgpath : "../../../assets/dashboard/collction.png" , link : "/admin/collection"},
    // { imgpath : "../../../assets/dashboard/amount_givne.png" , link : "/admin/amountGiven"},
    { imgpath : "../../../assets/dashboard/Deposit_approve.png" , link : "/admin/dashboard"},
    { imgpath : "../../../assets/dashboard/sales_approve.png" , link : "/admin/dashboard"},
    { imgpath : "../../../assets/dashboard/production_requirment.png" , link : "/admin/dashboard"},
    // { imgpath : "../../../assets/dashboard/messages.png" , link : "/admin/chat"},
    // { imgpath : "../../../assets/dashboard/daily_visit.png" , link : "/admin/dailyvisit"},
  ]
  user: any;
  isMainAdmin:boolean = false;
  userProfilePic : string;
  baseURL : string;
  constructor(
    private router:Router,
    private dialog: MatDialog, 
    private snackBar: MatSnackBar
  ) { 
    // this.router.events.subscribe((evt) => {
    //   if (!(evt instanceof NavigationEnd)) {
    //     return;
    //   }
    //   if (evt instanceof NavigationEnd) {
    //     this.isUrl =""+evt.url;
    //     if(this.isUrl == '/AllReports' || this.isUrl.match('ViewReports')){
    //       this.Findurl = true;
    //     }else{
    //       this.Findurl = false;
    //     }
    //   }
    //   window.scrollTo(0, 0);
    // });
  }

  ngOnInit() {
    this.baseURL = "https://dev-api.bhavgroup.com";
    let token = sessionStorage.getItem('token');
    if(token == null){
      // // console.log("Token",token);
     this.router.navigateByUrl('/login');
    };
    let adminType = sessionStorage.getItem('isMainAdmin')
    this.isMainAdmin = adminType == "1" ? true : false;
    this.user = JSON.parse(sessionStorage.getItem('user'));
    //console.log(this.user);

  }

  logOut(){
    // sessionStorage.clear();
    // this.router.navigateByUrl('/login')
    let dailogRef = this.dialog.open(SignoutComponent, {
      panelClass: "col-md-5",
      hasBackdrop: true,
      disableClose: true,
    });
    dailogRef.afterClosed().subscribe((res) => {
      if (res == true) {
        sessionStorage.clear();
        //this.adminService.callLogOut.subscribe(false);

       this.router.navigateByUrl('/login')
      }
    });

  }

}
