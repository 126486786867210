import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from "@angular/forms";
import { AdminService } from '../../admin.service';
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import { MatSnackBar } from "@angular/material";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.css']
})
export class ProductEditComponent implements OnInit {
  previewUrl: any;
  addBrandForm: FormGroup;
  submitted: boolean = false;
  AllProductInfo: Array<any> = [];
  AllProductNames = [];
  loading: boolean = false;
  // route params 
  routeSub: any;
  id: any;
  queryStr: any;
  productInfo: any;
  baseUrl: any;
  brandName: string = "";
  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private adminservice: AdminService,
    private snackBar: MatSnackBar,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.previewUrl = "../../../assets/logo.png";
    this.baseUrl = this.adminservice.baseUrl;
    this.addBrandForm = this.fb.group({
      brandImage: [""],
      brandname: ["", Validators.required],
      productName: ["", Validators.required],
      productPrice: ["", Validators.required],
      sprit: ["", Validators.required],
      bottles: ["", Validators.required],
      flavors: ["", Validators.required],
      caramel: ["", Validators.required],
      caps: ["", Validators.required],
      labels: ["", Validators.required],
      emptybox: ["", Validators.required],
      particans: ["", Validators.required]
    })
    this.getAllBrands();
    this.activateRoute.params.subscribe((params) => {
      this.onLoadData();
    });
  }
  onLoadData() {
    this.routeSub = this.activateRoute.params.subscribe((params) => {
      //log the entire params object
      this.id = params["id"];
    });
    this.activateRoute.queryParamMap.subscribe((params) => {
      this.queryStr = { ...params };
    });
    if (this.queryStr.params.isEncpt == "y") {
      this.id = atob(this.id);
      this.fetchData(this.id);
    } else {
      this.id = atob(this.id);
      this.fetchData(this.id);
    }
  }
  fetchData(dataIfo) {
    this.productInfo = JSON.parse(dataIfo);
    console.log( this.productInfo);
    this.brandName = this.productInfo.BrandName;
    this.addBrandForm.patchValue({
      brandname: this.productInfo.BrandName,
      productName: this.productInfo.ProductName,
      productPrice: this.productInfo.Price,
      sprit: this.productInfo.spritinliters,
      bottles: this.productInfo.Bottles,
      flavors: this.productInfo.Flavours,
      caramel: this.productInfo.Caramel,
      caps: this.productInfo.Caps,
      labels: this.productInfo.lables,
      emptybox: this.productInfo.EmptyBox,
      particans: this.productInfo.Particans
    });
    this.previewUrl = this.baseUrl + this.productInfo.Productimage;
    this.http
      .get(this.previewUrl, { responseType: "blob" })
      .subscribe((data) => {
        let file = new File([data], "sample.jpg");
        this.addBrandForm.get("brandImage").setValue(file);
        console.log(file);
      });
    console.log(JSON.parse(dataIfo));
  }

  getAllBrands() {
    this.adminservice.getproducts().subscribe(
      (posRes) => {
        console.log(posRes);
        if (posRes.response == 3) {
          this.AllProductInfo = posRes.Info;
          let pInfo = [];
          this.AllProductInfo.filter(function (el) {
            let obj = {
              "BrandName": el.BrandName,
              "Brandimage": el.Brandimage
            }
            pInfo.push(obj);
          })
          this.AllProductNames = pInfo;
          // this.openSnackBar(posRes.message, "");
        } else {
          this.openSnackBar(posRes.message, "");
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
  }
  //File Upload
  fileProgress(event) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);
      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.previewUrl = reader.result;
        this.addBrandForm.get("brandImage").setValue(file);
        // this.isUploadShow = true;
        // this.isFileUploaded = true;
      };
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }
  numericOnly(event) {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }
  onupdateSubmit() {
    this.submitted = true;
    this.loading = true;
    let payLoad = { ...this.addBrandForm.value };
    console.log(payLoad);
    let apiPayLoad = {
      "BrandName": payLoad.brandname,
      "ProductName": payLoad.productName,
      "Price": Number(payLoad.productPrice),
      "spritinliters": Number(payLoad.sprit),
      "Bottles": Number(payLoad.bottles),
      "Flavours": Number(payLoad.flavors),
      "Caramel": Number(payLoad.caramel),
      "Caps": Number(payLoad.caps),
      "lables": Number(payLoad.labels),
      "EmptyBox": Number(payLoad.emptybox),
      "Particans": Number(payLoad.particans),
      "ProductID" : this.productInfo.ProductID
    }
    console.log(apiPayLoad);
    if (this.addBrandForm.valid) {
      if (this.addBrandForm.value.brandImage != "") {
        let formData = new FormData();
        formData.append("productimage", this.addBrandForm.get("brandImage").value);
        formData.append("productInfo", JSON.stringify(apiPayLoad));
        console.log(apiPayLoad);
        this.adminservice.UpdateProduct(formData).subscribe(
          (posRes) => {
            console.log(posRes);
            if (posRes.response == 3) {
              this.loading = false;
              this.submitted = false;
              this.openSnackBar(posRes.message, "");
              this.router.navigateByUrl('admin/products');
            } else {
              this.loading = false;
              this.submitted = false;
              this.openSnackBar(posRes.message, "");
            }
          },
          (err: HttpErrorResponse) => {
            this.loading = false;
            // console.log("Set Profile", err);
            if (err.error instanceof Error) {
              this.openSnackBar(err.message, "Client Error");
              console.log(err.message);
            } else {
              this.openSnackBar(err.message, "Server Error");
              console.log(err.message);
            }
          })
      } else {
        this.openSnackBar("Please upload Product Image ", "");
        this.submitted = false;
        this.loading = false;
      }
    } else {
      console.log(JSON.stringify(this.addBrandForm.value))
    }
    console.log(payLoad);
  }
  backproductList() {
    this.router.navigateByUrl('/admin/products');
  }
  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }

}
