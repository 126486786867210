import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { AdminComponent } from '../admin/admin.component';

@Component({
  selector: 'app-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.css']
})
export class SignoutComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<AdminComponent>) { }

  ngOnInit() {
  }
  submit() {
    this.dialogRef.close(true)
  }
  close() {
    this.dialogRef.close(false)
  }

}
