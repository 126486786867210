import { Component, OnInit,OnChanges,SimpleChanges, Input  } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';

import { AdminService } from 'src/app/admin.service';
import { ProcurementdeleteComponent } from '../procurementdelete/procurementdelete.component';
import { HttpErrorResponse, HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-procurements',
  templateUrl: './procurements.component.html',
  styleUrls: ['./procurements.component.css']
})
export class ProcurementsComponent implements OnInit {

  Amount: number = 0;
  procureForm: FormGroup;
  serverResponse: any;
  Procurement: any;
  totalAmount: number = 0;
  isLoading: boolean;
  issubmitted: boolean = true;
  isupdated: boolean = false;
  procurementID: any;
  hourlyBudgetValidation: boolean;
  loading: boolean = false;
  Quantity : number =0;
  itemslist:any;
  Price : number =0;
  constructor(private admin: AdminService, private snackBar: MatSnackBar,
    private fb: FormBuilder, private dialog: MatDialog) { }

  ngOnInit() {
    this.procureForm = this.fb.group({
      Name: new FormControl('', [Validators.required]),
      item: new FormControl('', [Validators.required]),
      Price: new FormControl('', [Validators.required]),
      Amount : new FormControl('', [Validators.required]),
      Quantity: new FormControl('', [Validators.required,Validators.maxLength(10),Validators.minLength(10)])
    })
    this.getprocure()
  }

  
  getprocure() {
    this.admin.showLoader.next(true);
    console.log(localStorage.getItem('dropdownitems'));
    let user = JSON.parse(sessionStorage.getItem('user'));
    let dropdownitems=JSON.parse(localStorage.getItem('dropdownitems'));
    //console.log(dropdownitems);
    this.itemslist = dropdownitems.RAWMaterials;
    let obj = {
      procurementID: user.userID
    }
    this.admin.getprocure(obj).subscribe((posRes) => {
      this.serverResponse = posRes;
      if (this.serverResponse.response == 3) {
        this.admin.showLoader.next(false);
        this.Procurement = this.serverResponse.Info;
        let tamt = 0;
        this.Procurement.filter(pro => {
          tamt += Number(pro.totalAmount);
        })
        this.totalAmount = tamt;
        this.loading = false;
        this.openSnackBar(posRes.message, "");
      } else {
        this.admin.showLoader.next(false);
        this.openSnackBar(posRes.message, "");
      }
    },(err: HttpErrorResponse) => {
      
      this.openSnackBar(err.message, "");
      this.admin.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }

  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }

  procureupdate(pro) {
    console.log(pro);
    this.isupdated = true;
    this.procurementID = pro.procurementID;
    // this.totalAmount=pro.totalAmount;
    this.procureForm.patchValue({
      Name: pro.Name,
      item: pro.item,
      Price: "" + pro.Price,
      Quantity: pro.Quantity,

    })
    
    document.getElementById("Name").focus();
  }
  totalAmountValue(value){
    this.Amount = Number(this.Quantity)*Number(this.Price);
  }
  procure() {
    this.admin.showLoader.next(true);
    this.loading = true;
    let user = JSON.parse(sessionStorage.getItem('user'));
    let payLoad = { ...this.procureForm.value };
    payLoad.userID = user.userID;
    payLoad.totalAmount = Number(payLoad.Quantity) * Number(payLoad.Price);
    payLoad.timestamp = "" + new Date().getTime();
    if (this.isupdated) {
      let payload = { ...this.procureForm.value };
      payload.procurementID = this.procurementID;
      payload.totalAmount = Number(payload.Quantity) * Number(payload.Price);
      payload.timestamp = "" + new Date().getTime();
      delete payload.userID;
      console.log(payload)
      this.admin.updateprocure(payload).subscribe((posRes) => {
        this.serverResponse = posRes;
        console.log(this.serverResponse)
        if (this.serverResponse.response == 3) {
          this.loading = false;
          this.issubmitted = false;
          this.isupdated = false;
          this.admin.showLoader.next(false);
          this.openSnackBar(posRes.message, "");
          this.getprocure();
        // this.procureForm.reset()
        location.reload()
        }else{
          this.loading =false;
        }
      },(err: HttpErrorResponse) => {
      
        this.openSnackBar(err.message, "");
        this.admin.showLoader.next(false);
        if (err.error instanceof Error) {
          console.warn("Client SIde Error", err.error);
        } else {
          console.warn("Server Error", err.error);
        }
      })
    } else {
      this.admin.procurements(payLoad).subscribe((posRes) => {
        this.serverResponse = posRes;
        if (this.serverResponse.response == 3) {
          // this.loading=false;
          this.openSnackBar(posRes.message, "");
          this.getprocure();
          this.admin.showLoader.next(false);
          //this.procureForm.reset()
          location.reload()
        }else{
          this.loading=false;
        }
      },(err: HttpErrorResponse) => {
      
        this.openSnackBar(err.message, "");
        this.admin.showLoader.next(false);
        if (err.error instanceof Error) {
          console.warn("Client SIde Error", err.error);
        } else {
          console.warn("Server Error", err.error);
        }
      })
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.Quantity && changes.Price) {
      this.Amount = this.Quantity * this.Price;
    }
  }

  // ngOnChanges() {
  //   console.log("on changes")
  //   let price: number = parseInt(this.procureForm.get("Price").value);
  //   let Quantity: number = parseInt(this.procureForm.get("Quantity").value);
  //   this.procureForm.get("Quantity").valueChanges.subscribe((val) => {
  //     if (val) {
  //       Quantity = val;
  //      let d=Quantity*price
  //      this.procureForm.patchValue({
  //       Amount:d
  //      })
  //     }
     
  //   });
  //   this.procureForm.get("Price").valueChanges.subscribe((val) => {
  //     if (val) {
  //       price = parseInt(val);
  //       let d=Quantity*price
  //      this.procureForm.patchValue({
  //       Amount:d
  //      })
  //     } else {
  //       price = 0;
  //     }
  //     console.log(this.jobBudgetInHours);
  //   });
  

  // }

  procuredelete(pro) {
    console.log(pro);
    this.admin.showLoader.next(true);
    let dialogRef = this.dialog.open(ProcurementdeleteComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true,
      data: pro
    })
    dialogRef.afterClosed().subscribe(res => {
      let user = JSON.parse(sessionStorage.getItem('user'));
      console.log(res)
      if (res.isDelete) {
        console.log(pro)
        this.isLoading = true;
        let obj = {
          procurementID: res.procurementID
        }

        this.admin.deleteprocure(obj).subscribe((dele) => {

          console.log("del", dele);
          if (dele.response === 3) {
            this.admin.showLoader.next(false);
            this.isLoading = false;
            this.getprocure();
            location.reload()
            this.openSnackBar(dele.message, "");
           
         
          } else {
            this.isLoading = false;
          }
        })
      } else {
        this.getprocure();
      }

    },(err: HttpErrorResponse) => {
      
      this.openSnackBar(err.message, "");
      this.admin.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })

  }







  checkLength2(e, input) {
    const functionalKeys = ['Backspace', 'ArrowRight', 'ArrowLeft'];

    if (functionalKeys.indexOf(e.key) !== -1) {
      return;
    }

    const keyValue = +e.key;
    if (isNaN(keyValue)) {
      e.preventDefault();
      return;
    }

    const hasSelection =
      input.selectionStart !== input.selectionEnd &&
      input.selectionStart !== null;
    let newValue;
    if (hasSelection) {
      newValue = this.replaceSelection(input, e.key);
    } else {
      newValue = input.value + keyValue.toString();
    }

    if (+newValue > 1111 || newValue.length > 4) {
      e.preventDefault();
      console.log(newValue)
    }
  }
  private replaceSelection(input, key) {
    const inputValue = input.value;
    const start = input.selectionStart;
    const end = input.selectionEnd || input.selectionStart;
    return inputValue.substring(0, start) + key + inputValue.substring(end + 1);
  }


}


