import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AdminService } from 'src/app/admin.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import { Router } from '@angular/router';
import { Neworder } from '../../Model/neworder';
@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css']
})
export class SalesComponent implements OnInit {

  customeruserID: string = "";
  customerName: string = "";
  salesmanuserID: string = "";
  salesmanName: string = "";
  lastPaidAmount: string = "";
  lastPaidDate: string = "";
  closingbalance: string = "";
  DisCount: string = "";
  Bonus: string = "";
  totalAmount: string = "";
  selectedClient: string = "";
  BrandName: string = "";

  baseurl: string;
  serverClientResponse: any;
  ClientInfo: any;
  clientNamesInfo: Array<any> = [];

  serverProductResponse: any;
  AllProductsInfoArray: Array<any> = [];
  AddProductInfoArray: Array<any> = [];

  brandProductInfo: any = [];
  productListForm: FormGroup;

  gridColumns = 3;
  selectProduct = "";
  selectedBandName = [];
  selectedProductsInfo: Array<any> = [];
  bonusQty: number = 0;
  totalQty: number = 0;
  totsalesAmount: number = 0;
  inputQty: string = "";
  loading: boolean = false;
  Adminuser: any;

  buttondisable: boolean = false;
  buttondisable1: boolean = false;
  showData: boolean = true;
  payloadData: any;
  msg: string = "";

  checkData : Array<Neworder>=[];
  // productsSubcribe: any;

  // salesorders: any = [];
  // salesorderss: any = [];

  // Discount: number;
  // AmountPaidDate: any;
  // AmountPaid: number;
  // closingBalance: number;
  // clientnames: any;
  // clientname: Array<any> = []
  // ordersForm: FormGroup;
  // datevalue: string = "";
  // lastpaid: string = "";
  // clbalance: string = "";
  // salesordersss: any;

  // arivProducts: any;
  // viraProduct: any;
  // arivProductss: any;
  // viraProducts: any;
  // arivDiscount: string = "";

  constructor(
    private adminservice: AdminService,
    private snackBar: MatSnackBar,
    private router: Router,
    private fb: FormBuilder) {

  }

  ngOnInit() {
    this.baseurl = "https://dev-api.bhavgroup.com";
    //this.gridColumns = this.gridColumns === 3 ? 4 : 3;
    this.Adminuser = JSON.parse(sessionStorage.getItem('user'));
    this.salesmanuserID = this.Adminuser.userID;
    this.salesmanName = this.Adminuser.customerName;
    this.selectedBandName = [];
    this.DisCount = "2.5";
    this.bonusQty = 5;
    this.totalQty = 100;
    this.totsalesAmount = 0;
    // this.selectedProductsInfo = [];
    this.getallclientnames();
    this.getProductsData();
    // this.productListForm = this.fb.group({

    // })

  }

  toggleGridColumns() {
    this.gridColumns = this.gridColumns === 3 ? 4 : 3;
  }

  getallclientnames() {
    let obj = {
      type: "4"
    }
    this.adminservice.adminorder(obj).subscribe((posRes) => {
      this.serverClientResponse = posRes;
      if (this.serverClientResponse.response == 3) {
        this.ClientInfo = this.serverClientResponse.info;
        this.clientNamesInfo = [];
        let clinfo = [];
        this.ClientInfo.filter(function (el) {
          let obj = {
            customeruserID: el.userID,
            customerName: el.customerName,
            lastPaidAmount: el.AmountPaid,
            closingbalance: el.closingBalance,
            totalAmount: el.totalAmount,
            lastPaidDate: el.AmountPaidDate
          }
          clinfo.push(obj);
        })
        this.clientNamesInfo = clinfo;
      }
    })
  }
  getProductsData() {
    this.adminservice.getproducts().subscribe((posRes) => {
      this.serverProductResponse = posRes;
      if (this.serverProductResponse.response == 3) {
        this.AllProductsInfoArray = this.serverProductResponse.Info;
        let apinfo = [];
        this.AllProductsInfoArray.filter(pinfo => {
          let bname = pinfo.BrandName;
          let bimage = pinfo.Brandimage;
          let plist = [];
          pinfo.Productslist.filter(spinfo => {
            let obj = {
              ProductID: spinfo.ProductID,
              ProductName: spinfo.ProductName,
              Price: spinfo.Price,
              Productimage: spinfo.Productimage,
              Quantity: "0",
              BrandName: bname,
            }
            plist.push(obj);
          })
          let obj1 = {
            BrandName: bname,
            Brandimage: bimage,
            orderlist: plist
          }
          apinfo.push(obj1);
          this.checkData.push(new Neworder(pinfo.BrandName,pinfo.Brandimage,plist));
          // this.checkData.BrandName =pinfo.BrandName;
          // this.checkData.Brandimage = pinfo.Brandimage;
          // this.checkData.orderlist = plist;
        })
        
        this.AddProductInfoArray = apinfo;
        // console.log(apinfo);
        console.log(this.checkData);
        this.displayAllproducts(this.AddProductInfoArray[0]);
        this.setImgStyles(this.AddProductInfoArray[0]);
      }
    })
  }
  onChanges(){
    let orderlist = [];
      this.selectedProductsInfo.filter(el => {
        el.Plist.orderlist.filter(elList => {
          if (elList.Quantity != 0)
            orderlist.push(elList);
        })
      })
      let totAmt = 0;
      let totQty = 0;
      orderlist.filter(el => {
        totQty += Number(el.Quantity);
        totAmt += Number(el.Quantity) * Number(el.Price);
      })
      this.totsalesAmount = totAmt;
      this.totalQty = totQty;


  }
  displayAllproducts(product) {
    let element = this.selectedBandName.includes(product.BrandName);
    if (element) {
      this.updateArraySelectedElements(product);
    } else {
      this.SaveArraySelectedElements(product);
    }
    // this.selectedBandName.push(product.BrandName);
    // this.BrandName = product.BrandName;
    // this.brandProductInfo = product.orderlist;
  }
  updateArraySelectedElements(product) {
    this.BrandName = product.BrandName;
    this.brandProductInfo = product.orderlist;
    this.selectedProductsInfo.filter(el => {
      if (el.BrandName == this.BrandName) {
        el.Plist = product
      }
      return el
    })
    // this.selectedProductsInfo.push(obj);
  }
  SaveArraySelectedElements(product) {
    this.BrandName = product.BrandName;
    this.brandProductInfo = product.orderlist;
    // console.log("selected save Array");
    // console.log(product);
    let obj = {
      "BrandName": this.BrandName,
      "Plist": product
    }
    this.selectedProductsInfo.push(obj);
    this.selectedBandName.push(product.BrandName);
  }
  removeArraySelectedElements(product) {
    this.BrandName = product.BrandName;
    this.brandProductInfo = product.orderlist;
    console.log("selected remove Array");
    console.log(product);
  }
  
  totalAmountValue(value){
    //console.log(value);
    // console.log(this.selectedProductsInfo);
    let totAmt =0;
    let totqty =0;
    let btqty = 0;
    this.selectedProductsInfo.filter(el=>{
      el.Plist.orderlist.filter(el1 =>{
        if(el1.Quantity != "0"){
          totAmt +=Number(el1.Price)*Number(el1.Quantity);
          totqty +=Number(el1.Quantity);
        }
      })
    })
    this.totalQty = totqty
    this.bonusQty = totqty / 20;
    this.totsalesAmount = totAmt;
    let totamounts = 0;
    if (this.DisCount == "2.5") {
      totamounts = ((this.totsalesAmount * 2.5) / 100);
      this.totsalesAmount -= totamounts;
    }
    if (this.DisCount == "5") {
      totamounts = ((this.totsalesAmount * 5) / 100);
      this.totsalesAmount -= totamounts;
    }

  }
  displayAllproducts1(product) {
    //console.log(this.selectedBandName);
    //console.log(this.selectedBandName.length);
    if (this.selectedBandName.length > 0) {
      if (this.selectedBandName.length == 1) {
        let element = this.selectedBandName.includes(product.BrandName);
        if (!element) {
          let pinfo = [];
          let tqt = 0;
          let totAmt = 0;
          this.brandProductInfo.filter(el => {
            if (el.Quantity != "0") {
              pinfo.push(el);
              tqt += Number(el.Quantity);
              totAmt += Number(el.Quantity) * Number(el.Price);
            }
          })
          this.totalQty += tqt;
          this.bonusQty += this.totalQty / 20;
          this.totsalesAmount += totAmt;
          let obj = {
            "BrandName": this.BrandName,
            "Plist": pinfo
          }
          this.selectedProductsInfo.push(obj);
          this.selectedBandName.push(product.BrandName);
          // console.log(this.selectedProductsInfo);
          this.BrandName = product.BrandName;
          this.brandProductInfo = product.orderlist;
          //console.log("length 1 is there");
        }
      } else {
        // console.log("length 1 above is there");
        // console.log(this.BrandName);
        /// console.log(product.BrandName);
        let element = this.selectedBandName.includes(product.BrandName);
        if (element) {
          let pinfo = [];
          let tqt = 0;
          let totAmt = 0;
          this.brandProductInfo.filter(el => {
            if (el.Quantity != "0") {
              pinfo.push(el);
              tqt += Number(el.Quantity);
              totAmt += Number(el.Quantity) * Number(el.Price);
            }
          })
          this.totalQty += tqt;
          this.bonusQty += this.totalQty / 20;
          this.totsalesAmount += totAmt;
          let obj = {
            "BrandName": this.BrandName,
            "Plist": pinfo
          }
          this.selectedProductsInfo.push(obj);
          this.selectedBandName.push(product.BrandName);
          let bname = product.BrandName;

          let rmarray = this.removeArrayObject(this.selectedProductsInfo, 'BrandName', bname);


          //  console.log(this.selectedProductsInfo);
          //  console.log(this.brandProductInfo);
          // this.selectedProductsInfo.filter(elp =>{
          //   //if(elp.BrandName)
          // })
          this.openSnackBar("Implemetation this Test cases don't come back do sumbit update client order", "");
          //this.brandProductInfo = product.orderlist;
        } else {
          let element = this.selectedBandName.includes(product.BrandName);
          if (element) {
            this.openSnackBar("Already selected Brand can't update", "");
          } else {
            let pinfo = [];
            let tqt = 0;
            let totAmt = 0;
            this.brandProductInfo.filter(el => {
              if (el.Quantity != "0") {
                tqt += Number(el.Quantity);
                totAmt += Number(el.Quantity) * Number(el.Price);
                pinfo.push(el);
              }
            })
            this.totalQty += tqt;
            this.bonusQty += this.totalQty / 20;
            this.totsalesAmount += totAmt;
            let obj = {
              "BrandName": this.BrandName,
              "Plist": pinfo
            }
            this.selectedProductsInfo.push(obj);
            this.selectedBandName.push(product.BrandName);
            // console.log(this.selectedProductsInfo);
            this.BrandName = product.BrandName;
            this.brandProductInfo = product.orderlist;
            //this.selectedProductsInfo.push(this.brandProductInfo);
          }
        }
      }
    } else {
      this.selectedBandName.push(product.BrandName);
      this.BrandName = product.BrandName;
      this.brandProductInfo = product.orderlist;
      // console.log("less than zero");
    }
    // console.log(this.brandProductInfo);
    // console.log(this.selectedProductsInfo);
  }

  findArrayObject(arr, attr, value) {
    let objvalue;
    var i = arr.length;
    while (i--) {
      if (arr[i] && arr[i].hasOwnProperty(attr) && (arguments.length > 2 && arr[i][attr] === value)) {
        objvalue = arr[i];
      }
    }
    return objvalue;
  }
  // remove an array of object
  removeArrayObject(arr, attr, value) {
    var i = arr.length;
    while (i--) {
      if (arr[i]
        && arr[i].hasOwnProperty(attr)
        && (arguments.length > 2 && arr[i][attr] === value)) {

        arr.splice(i, 1);

      }
    }
    return arr;
  }


  numericOnly(event) {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }
  setImgStyles(product) {
    this.selectProduct = product.BrandName;
    let style;
    if (this.BrandName == product.BrandName) {
      style = {
        "border": "3px solid #144158"
      }
    }
    return style;
  }
  assignCorporationToManage(selectedValue) {
    console.log(selectedValue);
    this.customeruserID = selectedValue.customeruserID;
    this.customerName = selectedValue.customerName;
    this.lastPaidAmount = selectedValue.lastPaidAmount;
    this.closingbalance = selectedValue.closingbalance;
    this.lastPaidDate = selectedValue.lastPaidDate == "" ? "" + "0" : selectedValue.lastPaidDate;

    console.log(this.lastPaidDate);
    // this.selectedClient = 
  }
  InsertOrder2() {
    this.loading = true;
    this.buttondisable1 = true;
    this.adminservice.InsertClientOrder(this.payloadData).subscribe(
      (posRes) => {
        console.log(posRes);
        if (posRes.response == 3) {
          this.loading = false;
          this.buttondisable1 = false;
          this.openSnackBar(posRes.message, "");
          this.router.navigateByUrl('/admin/clientorders');
        } else {
          this.loading = false;
          this.buttondisable1 = false;
          this.openSnackBar(posRes.message, "");
          console.log(posRes);
        }
      },
      (err: HttpErrorResponse) => {
        this.loading = false;
        // console.log("Set Profile", err);
        if (err.error instanceof Error) {
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
  }
  InsertOrder() {
    // console.log(this.brandProductInfo);
    // console.log(this.selectedProductsInfo);
    this.loading = true;
    if (this.customeruserID == "") {
      this.openSnackBar("Please select Customer Name ", "");
      this.loading = false;
    } else {
      let orderlist = [];
      this.selectedProductsInfo.filter(el => {
        el.Plist.orderlist.filter(elList => {
          if (elList.Quantity != 0)
            orderlist.push(elList);
        })
      })
      let totAmt = 0;
      let totQty = 0;
      orderlist.filter(el => {
        totQty += Number(el.Quantity);
        totAmt += Number(el.Quantity) * Number(el.Price);
      })
      this.totsalesAmount = totAmt;
      this.totalQty = totQty;
      let bonusQty = totQty / 20;
      this.bonusQty = bonusQty;

      if (bonusQty < Number(this.inputQty)) {
        this.inputQty = "0";
        this.openSnackBar("You can't assign more than " + bonusQty + " Box", "");
        this.loading = false;
      } else {
        let totamounts = 0;
        if (this.DisCount == "2.5") {
          totamounts = ((this.totsalesAmount * 2.5) / 100);
          this.totsalesAmount -= totamounts;
        }
        if (this.DisCount == "5") {
          totamounts = ((this.totsalesAmount * 5) / 100);
          this.totsalesAmount -= totamounts;
        }
        let payload = {
          customeruserID: this.customeruserID,
          customerName: this.customerName,
          salesmanuserID: this.salesmanuserID,
          salesmanName: this.salesmanName,
          closingbalance: "" + this.closingbalance,
          lastPaidAmount: "" + this.lastPaidAmount,
          disCount: this.DisCount + "%",
          orderlist: orderlist,
          totalAmount: "" + this.totsalesAmount,
          Bonus: "MAX  " + this.inputQty + " For " + this.totalQty,
          lastpaidDate: "" + this.lastPaidDate
        }
        console.log(payload);
        this.buttondisable = false;
        this.loading = false;
        this.showData = false;
        this.msg = "can you check before submit Data ...!"
        this.payloadData = payload;

         this.adminservice.InsertClientOrder(payload).subscribe(
          (posRes) => {
            console.log(posRes);
            if (posRes.response == 3) {
              this.loading = false;
              this.buttondisable = false;
              this.openSnackBar(posRes.message, "");
              this.router.navigateByUrl('/admin/clientorders');
            } else {
              this.loading = false;
              this.buttondisable = false;
              this.openSnackBar(posRes.message, "");
              console.log(posRes);
            }
          },
          (err: HttpErrorResponse) => {
            this.loading = false;
            // console.log("Set Profile", err);
            if (err.error instanceof Error) {
              this.openSnackBar(err.message, "Client Error");
              console.log(err.message);
            } else {
              this.openSnackBar(err.message, "Server Error");
              console.log(err.message);
            }
          })

      }

      this.buttondisable = true;
    }


  }
  InsertOrder1() {
    console.log(this.brandProductInfo);
    console.log(this.AllProductsInfoArray);
    this.loading = true;
    let orderlst = [];
    let tqt = 0;
    this.brandProductInfo.filter(el => {
      if (el.Quantity != "0") {
        tqt += Number(el.Quantity);
      }
    })
    this.totalQty += tqt;
    this.bonusQty += this.totalQty / 20;
    if (this.bonusQty < Number(this.inputQty)) {
      this.openSnackBar("we can't assign above Bonus Quanty", "");
      this.inputQty = "";
    } else {
      if (this.customeruserID == "") {
        this.openSnackBar("Please Select Customer", "");
      } else {
        let pinfo = [];
        let totAmt = 0;
        this.brandProductInfo.filter(el => {
          if (el.Quantity != "0") {
            tqt += Number(el.Quantity);
            totAmt += Number(el.Quantity) * Number(el.Price);
          }
        })
        this.totsalesAmount += totAmt;
        if (this.DisCount == "2.5") {
          let totamounts = ((this.totsalesAmount * 2.5) / 100);
          this.totsalesAmount -= totamounts;
        }
        if (this.DisCount == "5") {
          let totamounts = ((this.totsalesAmount * 5) / 100);
          this.totsalesAmount -= totamounts;
        }
        this.brandProductInfo.filter(el => {
          if (el.Quantity != "0") {
            pinfo.push(el);
          }
        })
        let obj = {
          "BrandName": this.BrandName,
          "Plist": pinfo
        }
        this.selectedProductsInfo.push(obj);
        this.selectedProductsInfo.filter(el => {
          el.Plist.filter(el1 => {
            orderlst.push(el1);
          })
        })
        let payload = {
          customeruserID: this.customeruserID,
          customerName: this.customerName,
          salesmanuserID: this.salesmanuserID,
          salesmanName: this.salesmanName,
          closingbalance: "" + this.closingbalance,
          lastPaidAmount: "" + this.lastPaidAmount,
          disCount: this.DisCount + "%",
          orderlist: orderlst,
          totalAmount: "" + this.totsalesAmount,
          Bonus: "MAX  " + this.inputQty + " For " + this.totalQty,
        }
        console.log(payload);

        this.openSnackBar("InProgress Client Order", "");
        // this.adminservice.InsertClientOrder(payload).subscribe(
        //   (posRes) => {
        //     console.log(posRes);
        //     if (posRes.response == 3) {
        //       this.loading = false;
        //       this.openSnackBar(posRes.message, "");
        //       this.router.navigateByUrl('/admin/clientorders');
        //     } else {
        //       this.loading = false;
        //       this.openSnackBar(posRes.message, "");
        //       console.log(posRes);
        //     }
        //   },
        //   (err: HttpErrorResponse) => {
        //     this.loading = false;
        //     // console.log("Set Profile", err);
        //     if (err.error instanceof Error) {
        //       this.openSnackBar(err.message, "Client Error");
        //       console.log(err.message);
        //     } else {
        //       this.openSnackBar(err.message, "Server Error");
        //       console.log(err.message);
        //     }
        //   })
        // // this.openSnackBar("InProgress Client Order", "");
      }

    }


  }
  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }



  // text box length restriction code
  checkLength2(e, input) {
    const functionalKeys = ['Backspace', 'ArrowRight', 'ArrowLeft'];


    if (functionalKeys.indexOf(e.key) !== -1) {
      return;
    }

    const keyValue = +e.key;
    if (isNaN(keyValue)) {
      e.preventDefault();
      return;
    }

    const hasSelection = input.selectionStart !== input.selectionEnd && input.selectionStart !== null;
    let newValue;
    if (hasSelection) {
      newValue = this.replaceSelection(input, e.key)
    } else {
      newValue = input.value + keyValue.toString();
    }

    if (+newValue > 9999 || newValue.length > 4) {
      e.preventDefault();
    }
  }

  // text box length restriction code
  private replaceSelection(input, key) {
    const inputValue = input.value;
    const start = input.selectionStart;
    const end = input.selectionEnd || input.selectionStart;
    return inputValue.substring(0, start) + key + inputValue.substring(end + 1);
  }


}
