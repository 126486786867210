import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/admin.service';
import { MatDialog, MatSnackBar } from "@angular/material";
import { SignoutComponent } from '../signout/signout.component';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  isdetailsPage:boolean = false;
  isSideNavOpen:boolean = false;
  isMainAdmin:boolean = false;
  isLogin : boolean = false;
  user : any;
  constructor(
    private adminService:AdminService, 
    private router:Router,
    private dialog: MatDialog, 
    private snackBar: MatSnackBar) {
      // this.adminService.callLogOut.subscribe((val) => {
      //   if (val == true) {
      //     this.logOut();
      //   }
      // });
      // this.user = JSON.parse(sessionStorage.getItem("user")) || null;
      // if (this.user && this.user.userID) {
      //   this.isLogin = true;
      //   this.adminService.isAdminLoggedIn.next(true);
      // } else {
      //   this.isLogin = false;
      //   this.adminService.isAdminLoggedIn.next(false);
      // }
   }
  ngOnInit() {
    let token = sessionStorage.getItem('token');
    if(token == null){
      // // console.log("Token",token);
     this.router.navigateByUrl('/login');
    };
    let adminType = sessionStorage.getItem('isMainAdmin')
    this.isMainAdmin = adminType == "1" ? true : false;
  }
  logOut(){
    // sessionStorage.clear();
    // this.router.navigateByUrl('/login')
    let dailogRef = this.dialog.open(SignoutComponent, {
      panelClass: "col-md-5",
      hasBackdrop: true,
      disableClose: true,
    });
    dailogRef.afterClosed().subscribe((res) => {
      if (res == true) {
        sessionStorage.clear();
        //this.adminService.callLogOut.subscribe(false);

       this.router.navigateByUrl('/login')
      }
    });

  }
 
  openSideNav() {
    this.isSideNavOpen = !this.isSideNavOpen;
  }
}
