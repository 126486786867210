import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { AdminService } from 'src/app/admin.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { HttpErrorResponse, HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-clientordersedit',
  templateUrl: './clientordersedit.component.html',
  styleUrls: ['./clientordersedit.component.css']
})
export class ClientorderseditComponent implements OnInit {
  totalQty : number;
  sub: any;
  id: any;
  baseUrl: any;
  serverResponse: any;
  clientorders: any;
  ordersList: Array<any> = [];
  loading : boolean = false;

  // disCount: number;
  // totalAmount: number;
  
  // timestamp: number;
  // productsSubcribe: any;
  // 
  // clientorders: any;
  // clientorderForm: FormGroup;
  // listOfOrdersForm : FormGroup;
  // customerName: string;
  // salesmanName: String;
  // lastPaidAmount: number;
  // closingbalance: number;
  // Quantity: number;
  // 
  
  // 
  // 
  //totalAmount:number;

  constructor(
    private adminservice: AdminService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.totalQty = 0;
    this.sub = this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];

      this.getProductsData();
    })
    // console.log(this.id)

    this.baseUrl = "https://dev-api.bhavgroup.com"

  }
  backClientOrder() {
    this.router.navigateByUrl('/admin/clientorders');
  }

  numericOnly(event,data) {
   // console.log(data.ProductID);
    //console.log(event.target.value);
    // console.log(event.key);
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  getProductsData() {
    // this.customerName;
    // this.salesmanName;
    // this.timestamp = 0;
    // this.lastPaidAmount = 0;
    // this.closingbalance = 0;
    // this.Quantity = 0;
    // this.totalAmount = 0;
    let obj = {
      type: this.id
    }

    this.adminservice.getorders(obj).subscribe((posRes) => {
      this.serverResponse = posRes;
      //console.log(this.serverResponse)
      if (this.serverResponse.response == 3) {
        let tqty =0;
        this.clientorders = this.serverResponse.Info[0]
        //console.log(this.clientorders);
        this.clientorders.orderlist.filter(function (el) {
          tqty +=el.Quantity
        });
        this.totalQty = tqty;
        this.ordersList = this.clientorders.orderlist;
       // this.ordersList.forEach(()=>this.control.push(new FormControl()))
       // console.log(this.clientorders);
        // this.patchvalues();
      }
    })
  }
 
  saveUpdate(order){
    //console.log(this.ordersList);
    let updateItem = this.ordersList.find(item => item.ProductID === order.ProductID);
    let index = this.ordersList.indexOf(updateItem);
    this.ordersList[index].Quantity = order.Quantity;
    //console.log(this.ordersList);
    //console.log(order);
  }

  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }

  UpdateClientOrder(){
    this.loading = true;
    //console.log(this.ordersList);
    let totAmt = 0;
    let zeroqty = false; 
    this.ordersList.filter(function (el) {
      if(el.Quantity == 0){
        zeroqty = true
      }
      totAmt += Number(el.Price)*Number(el.Quantity);
    });
    if(zeroqty){
      this.openSnackBar("we can't update because quantity assigned to Zero","");
    }else{
      this.clientorders.totalAmount = totAmt;
      let payload = {
        orderID : this.id,
        orderlist : this.ordersList,
        totalAmount : ""+totAmt,
        disCount : this.clientorders.disCount,
        Bonus : this.clientorders.Bonus
      }
      //console.log(payload);
      this.adminservice.UpdateClientOrder(payload).subscribe(
        (posRes) => {
          //console.log(posRes);
          if (posRes.response == 3) {
            this.loading = false;
            this.openSnackBar(posRes.message, "");
            this.router.navigate(["/admin/Clientview", this.id]);
          } else {
            this.loading = false;
            this.openSnackBar(posRes.message, "");
          }
        },
        (err: HttpErrorResponse) => {
          this.loading = false;
          // console.log("Set Profile", err);
          if (err.error instanceof Error) {
            this.openSnackBar(err.message, "Client Error");
            console.log(err.message);
          } else {
            this.openSnackBar(err.message, "Server Error");
            console.log(err.message);
          }
        })
      //this.openSnackBar("Amount is "+totAmt,"")
    }
   // this.router.navigate(["/admin/Clientview", this.id])
    
  }

}
