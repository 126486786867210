import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ProductPersonsComponent }  from '../product-persons/product-persons.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../admin.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DeleteBgpersonsComponent } from '../delete-bgpersons/delete-bgpersons.component';
import { Router } from '@angular/router';
import { UpdateProductionPersonComponent } from '../update-production-person/update-production-person.component';
@Component({
  selector: 'app-allproduct-persons',
  templateUrl: './allproduct-persons.component.html',
  styleUrls: ['./allproduct-persons.component.css']
})
export class AllproductPersonsComponent implements OnInit {
  searchByNameForm: FormGroup;
  baseUrl: string = "";
  ProductionPersonObj: any = null;
  pageNo: number = 1;
  isSearchByName: boolean = false;
  filterdProductionperson : Array<any> = [];
  customerSubscribe: any;
  message: string = "Finding Product Perons";
  productionPerson: Array<any> = [];
  totalPageCount: number = 1;
  pageNumbers: Array<any> = [];
  isTotalCountReached: boolean = false;
  isFetchingProduction: boolean = false;
  isSuperAdmin: boolean = false;
  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private adminService : AdminService,
    private snackBar: MatSnackBar,
    private router : Router
  ) { }

  ngOnInit() {
    this.pageNo =1;
    this.baseUrl = "https://dev-api.bhavgroup.com"
    this.ProductionPersonObj = {
      type: "2",
      pageNo: "" + this.pageNo,
      size: "15",
    }
    this.searchByNameForm = this.fb.group({
      searchText: ["", Validators.required],
      type : ["2"],
      pageNo: [""],
      size: ["15"]
    })
    let adminType = sessionStorage.getItem('isMainAdmin');
    this.isSuperAdmin = adminType == "1" ? false : true;
    this.fetchAllProductionPerson();
  }
  fetchAllProductionPerson(){
    this.ProductionPersonObj.pageNo = "" + this.pageNo;
    this.isSearchByName = false;
    this.filterdProductionperson =[];
    this.adminService.showLoader.next(true);
    // console.log(this.ProductionPersonObj);
     this.customerSubscribe = this.adminService.fetchAllClients(this.ProductionPersonObj).subscribe((posRes) => {
      // console.log("All Users", posRes);
      this.message = "No Users Found.."
      this.adminService.showLoader.next(false);
      if (posRes.response == 3) {
        this.productionPerson = posRes.info;
        this.totalPageCount = posRes.pages;
        for (let i: number = 0; i < this.totalPageCount; i++) {
          this.pageNumbers.push(i + 1);
        }
        if (this.totalPageCount <= this.pageNo) {
          this.isTotalCountReached = true;
        } else {
          this.isTotalCountReached = false;
        }
       // let parentElm = document.getElementById('main-wrap');
        //let  currentScrollPositio = parentElm.pageYOffset;
       //  let currentScrollPosition = parentElm.scrollTop;
        //// console.log("Yposition",currentScrollPosition);
        // this.filterdCustomers = this.filterdCustomers.concat(this.customers);
        this.filterdProductionperson = this.productionPerson
        this.isFetchingProduction = false;
        //  parentElm.scrollTop = currentScrollPosition;
        // parentElm.scrollTop = 0;
      } else {
        this.openSnackBar(posRes.message, "");
        this.pageNo = this.pageNo - 1;
        this.isFetchingProduction = false;
      }
    }, (err: HttpErrorResponse) => {
      this.message = "No Users Found.."
      this.openSnackBar(err.message, "")
      this.pageNo = this.pageNo - 1;
      this.isFetchingProduction = false;
      this.adminService.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }

  searchByName() {
    if (this.searchByNameForm.valid) {
      this.filterdProductionperson = []
      this.pageNo = 1;
      this.isSearchByName = true;
      this.getProductionPersonSearchByName();
    } else {
      this.fetchAllProductionPerson();
      //this.openSnackBar("Enter First Name..", "")
    }
  }

  EditProductPerson(productionperson){
    let dialogRef = this.dialog.open(UpdateProductionPersonComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true,
      data : productionperson
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        // this.filterdCustomers = [];
        // this.customerObj = res;
        // console.log(this.customerObj);
        this.fetchAllProductionPerson();
      }
    })
  }

  getProductionPersonSearchByName(){
    this.adminService.showLoader.next(true);
    this.message = 'Finding Product Persons..'
    this.isFetchingProduction = true;
    this.searchByNameForm.patchValue({
      pageNo: "" + this.pageNo
    })
    this.isSearchByName = true;
    //let token = sessionStorage.getItem('token');
    let payload = { ...this.searchByNameForm.value };
    // payload.type="Users";
    //console.log(this.searchByNameForm.value);
    this.adminService.searchByName(payload).subscribe((posRes) => {
      // console.log("All Users", posRes);
      this.pageNumbers = [];
      this.adminService.showLoader.next(false);
      this.message = "No Production person Found.."
      if (posRes.response == 3) {
        this.productionPerson = posRes.FetchData;
        if(this.productionPerson.length>0){
          this.totalPageCount = posRes.pages;
          for (let i: number = 0; i < this.totalPageCount; i++) {
            this.pageNumbers.push(i + 1);
          }
          if (this.totalPageCount <= this.pageNo) {
            this.isTotalCountReached = true;
          } 
          else {
            this.isTotalCountReached = false;
          }       
          this.filterdProductionperson = this.productionPerson;
          // this.filterdCustomers = this.filterdCustomers.concat(this.customers);
          this.isFetchingProduction = false;
        }else{
          this.totalPageCount = 1;
          this.filterdProductionperson = this.productionPerson;
          this.isTotalCountReached = true;
        }
      } else {
        this.openSnackBar(posRes.message, "")
        this.pageNo = this.pageNo - 1;
        this.isFetchingProduction = false;        
      }
    }, (err: HttpErrorResponse) => {
      this.message = "No Product person Found.."
      this.openSnackBar(err.message, "")
      this.pageNo = this.pageNo - 1;
      this.isFetchingProduction = false;
      this.adminService.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }
  gotoSelectedPage(num) {
    this.pageNo = num;
    if (!this.isSearchByName) {
      this.fetchAllProductionPerson();
    } else {
      this.getProductionPersonSearchByName();
       // this.ProfileNotUpdateUserNext_prev()
    }
  }
  previousPage() {
    this.pageNo = this.pageNo - 1;
    if (this.totalPageCount <= this.pageNo) {
      this.isTotalCountReached = true;
    } else {
      this.isTotalCountReached = false;
    }
    if (!this.isSearchByName) {
      this.fetchAllProductionPerson();
    } else {
      this.getProductionPersonSearchByName();
      // this.ProfileNotUpdateUserNext_prev();
    }

  }
  loadMore() {
    this.pageNo = this.pageNo + 1;
    if (this.totalPageCount <= this.pageNo) {
      this.isTotalCountReached = true;
    } else {
      this.isTotalCountReached = false;
    }
    if (!this.isSearchByName) {
      this.fetchAllProductionPerson()
    } else {
      this.getProductionPersonSearchByName();
      // this.getUsersByName()
    }
  }
  ProductionPersonInsert(){
    let dialogRef = this.dialog.open(ProductPersonsComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
       // this.filterdCustomers = [];
       //  this.customerObj = res;
        // console.log(this.customerObj);
        this.pageNo = 1;
       this.fetchAllProductionPerson();
      }
    })
  }
  DeleteProductPersonInfo(client){
    let dialogRef = this.dialog.open(DeleteBgpersonsComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true,
      data : client
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res.isDelete) {
        this.deleteAccountUser(res);
        //this.fetchAllClients();
      }
    })
  }
  viewProductPerson(viewperson){
    this.router.navigate(['/admin/viewProductionpersonInfo',btoa(JSON.stringify(viewperson.userID))])
  }
  deleteAccountUser(res){
    let payload ={
      userID : res.userID
    }
    this.adminService.DeleteByUser(payload).subscribe((posRes) => {
      // console.log("All Users", posRes);
      if (posRes.response == 3) {
        if(this.isSearchByName){
          this.getProductionPersonSearchByName();
        }else{
          this.fetchAllProductionPerson();
        }
      } else {
        this.openSnackBar(posRes.message, "");      
      }
    }, (err: HttpErrorResponse) => {
      
      this.openSnackBar(err.message, "");
      this.adminService.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }
  ngOnDestroy() {
    this.customerSubscribe.unsubscribe();
  }

  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }

}
