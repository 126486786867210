import { Component, OnInit,ChangeDetectorRef } from '@angular/core';
import { AdminService } from '../../admin.service';
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatDialog } from "@angular/material";
import { FormGroup, FormBuilder, Validators, AbstractControl } from "@angular/forms";
@Component({
  selector: 'app-warehouse',
  templateUrl: './warehouse.component.html',
  styleUrls: ['./warehouse.component.css']
})
export class WarehouseComponent implements OnInit {
  allbranchInfo : any;
  addWarehous: FormGroup;
  loading: boolean = false;
  submitted: boolean = false;
  constructor(
    private adminservice: AdminService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.addWarehous = this.fb.group({
      branchName: ["", Validators.required],
      WarehouseName: ["", Validators.required],
    })
    this.getAllBranch();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.addWarehous.controls;
  }
  getAllBranch(){
    this.adminservice.getAllbranch().subscribe(
      (posRes) => {
       // console.log(posRes);
        if (posRes.response == 3) {
         this.allbranchInfo = posRes.branchList;
         console.log(this.allbranchInfo);
        } else {
          this.openSnackBar(posRes.message, "");
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
  }
  onSubmit(){
    this.submitted = true;
    this.loading = true;
    let payLoad = { ...this.addWarehous.value };
    this.submitted = false;
    this.loading = false;
  }

   //message alerts showing
   openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
      verticalPosition: "top",
    });
  }
}
