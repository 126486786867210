import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AdminService } from 'src/app/admin.service';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-closing-balance-sheet',
  templateUrl: './closing-balance-sheet.component.html',
  styleUrls: ['./closing-balance-sheet.component.css']
})
export class ClosingBalanceSheetComponent implements OnInit {
  Total_Ctns:string="00";
  Total_Collectn:string="00";
  SalesValue:string="00";
  Total_CB:string="00";
  closingbalance: any = [];
  pageNo: number = 1;
  PaymentTable: Array<any> =  [];
  customerSubscribe: any;
  clientObj: any = null;
  serverResponse: any;
  message: string = "Finding Clients..";
  clients: Array<any> = [];
  totalPageCount: number = 1;
  pageNumbers: Array<any> = [];
  filterdClients: Array<any> = [];
  isTotalCountReached: boolean = false;
  isFetchingClients: boolean = false;
  baseUrl: string = "";
  isDisabled: boolean = true;
  constructor(
    private adminService: AdminService,
    private router: Router,    
    private snackBar: MatSnackBar
    ) { }

  ngOnInit() {   
    this.ClosingbalanceSheet();
    //this.fetchAllClients();  
  }
  ClosingbalanceSheet(){
    let user = JSON.parse(sessionStorage.getItem('user'));
    console.log("UID",user.userID)   
    let CRSheetpayload = {
      userID:user.userID     //"dathu3@gmail.com"
    };
    console.log("payload", CRSheetpayload);
      this.adminService.showLoader.next(true);  
      this.customerSubscribe = this.adminService.ClosingBalanceSheet(CRSheetpayload).subscribe((posRes) => {      
        this.adminService.showLoader.next(false);        
        if (posRes.response == 3) {
          this.Total_Ctns = posRes.info.TotalCartons;
          this.Total_Collectn = posRes.info.TotalCollection;
          this.SalesValue = posRes.info.salesValue;
          this.Total_CB = posRes.info.TotalCB;          
          
          this.filterdClients = posRes.usersInfo;
          console.log("clients", this.filterdClients);
          this.openSnackBar(posRes.message, "");
         
        } else {
          this.openSnackBar(posRes.message, "");
       
        }
      }, (err: HttpErrorResponse) => {
       
        this.openSnackBar(err.message, "")
     
        this.adminService.showLoader.next(false);
        if (err.error instanceof Error) {
          console.warn("Client SIde Error", err.error);
        } else {
          console.warn("Server Error", err.error);
        }
      })
  }
  // fetchAllClients() {
  //   this.clientObj.pageNo = "" + this.pageNo;   
  //   this.adminService.showLoader.next(true);  
  //   this.customerSubscribe = this.adminService.fetchAllClients(this.clientObj).subscribe((posRes) => {
  //      console.log("All Users", posRes);
  //     this.message = "No Users Found.."
  //     this.adminService.showLoader.next(false);
  //     if (posRes.response == 3) {
  //       this.clients = posRes.info;
  //       this.totalPageCount = posRes.pages;
  //       for (let i: number = 0; i < this.totalPageCount; i++) {
  //         this.pageNumbers.push(i + 1);
  //       }
  //       if (this.totalPageCount <= this.pageNo) {
  //         this.isTotalCountReached = true;
  //       } else {
  //         this.isTotalCountReached = false;
  //       }
       
  //       this.filterdClients = this.clients
  //       this.isFetchingClients = false;
       
  //     } else {
  //       this.openSnackBar(posRes.message, "");
  //       this.pageNo = this.pageNo - 1;
  //       this.isFetchingClients = false;
  //     }
  //   }, (err: HttpErrorResponse) => {
  //     this.message = "No Users Found.."
  //     this.openSnackBar(err.message, "")
  //     this.pageNo = this.pageNo - 1;
  //     this.isFetchingClients = false;
  //     this.adminService.showLoader.next(false);
  //     if (err.error instanceof Error) {
  //       console.warn("Client SIde Error", err.error);
  //     } else {
  //       console.warn("Server Error", err.error);
  //     }
  //   })

  // }
  viewpaymnets(pay){
    this.router.navigate(["/admin/clsview", pay.customerName])
  }

  

  numericOnly(event) {    
    // let patt = /^([0-9])$/;
    // let result = patt.test(event.key);
    // return result;
  }
  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }

}
