import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatDialog } from "@angular/material";
import { FormGroup, FormBuilder, Validators, AbstractControl, FormGroupDirective } from "@angular/forms";

import { AdminService } from '../../admin.service';
import { HttpErrorResponse, HttpClient } from "@angular/common/http";
import { DeleteDailogComponent } from '../delete-dailog/delete-dailog.component';

@Component({
  selector: 'app-add-new-brands',
  templateUrl: './add-new-brands.component.html',
  styleUrls: ['./add-new-brands.component.css']
})
export class AddNewBrandsComponent implements OnInit {
  previewImg: any = "../../../assets/Icon ionic-ios-add-circle.png";
  sucessInfo: boolean = false;
  addBrandForm: FormGroup;
  selectPic: boolean = false;
  AllProductInfo : Array<any> = [];
  AllProductNames = [];
  // userID :string = "";
  loading: boolean = false;
  suceessDataInfo: string = "";
  baseurl : string ="";
  constructor(
    private dialog: MatDialog,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private adminservice: AdminService
  ) { }

  ngOnInit() {
    this.baseurl ="https://dev-api.bhavgroup.com"
    this.addBrandForm = this.fb.group({
      productImage : ["", Validators.required],
      BrandName: ["", Validators.required],
      // Price: ["", Validators.required],
    });
    this.getProductBrands();
    // this.http
    //   .get(this.previewImg, { responseType: "blob" })
    //   .subscribe((file) => {
    //     let imgFile = new File([file], "userImg.jpg");
    //     this.addBrandForm.get("productImage").setValue(imgFile);
    //   });     
  }
  getProductBrands() {
    this.adminservice.getproducts().subscribe(
      (posRes) => {
        //console.log(posRes);
        if (posRes.response == 3) {
          this.AllProductInfo = posRes.Info;
          let pInfo =[];
          this.AllProductInfo.filter(function (el){
            let obj = {
              "BrandName" : el.BrandName,
              "Brandimage" : el.Brandimage
            }
            pInfo.push(obj);
          })
          this.AllProductNames = pInfo;
         // this.openSnackBar(posRes.message, "");
        } else {
          this.openSnackBar(posRes.message, "");
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
  }
  numericOnly(event) {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }
  closeTab() {
    // this.dialogRef.close(false);
  }
  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
      verticalPosition: "top",
    });
  }

  //Image Upload
  fileProgress(event: any) {
    this.selectPic = true;
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);
      // When file uploads set it to file formcontrol
      reader.onload = () => {
        //this.adminProfileForm.get('profilePic').setValue(file);
        this.addBrandForm.get("productImage").setValue(file);
        this.previewImg = reader.result;
      };
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }
  AddNewBrands() {
    this.loading = true;
    let payLoad = { ...this.addBrandForm.value };
    let user = JSON.parse(sessionStorage.getItem('user'));
    //console.log("UID",user.userID)
    payLoad.userID = user.userID;
  
    delete payLoad.productImage;
    console.log("load",payLoad);
    let formData = new FormData();
    formData.append("brandimage", this.addBrandForm.get("productImage").value);
    formData.append("brandInfo", JSON.stringify(payLoad));
    this.adminservice.adminbrandinsert(formData).subscribe(
      (posRes) => {
       console.log(posRes);
        if (posRes.response == 3) {
          this.loading = false;
          this.openSnackBar(posRes.message, "");         
         this.getProductBrands();
         this.clearInputMethod2();
        } else {
          this.loading = false;
          this.openSnackBar(posRes.message, "");
          //console.log(posRes);
        }
      },
      (err: HttpErrorResponse) => {
        this.loading = false;
        // console.log("Set Profile", err);
        if (err.error instanceof Error) {
          this.openSnackBar(err.message, "Client Error");
          console.log(err.message);
        } else {
          this.openSnackBar(err.message, "Server Error");
          console.log(err.message);
        }
      })
  }

  clearInputMethod2() { 
    this.addBrandForm.setValue({productImage: '', BrandName: ''});
    this.previewImg = "../../../assets/Icon ionic-ios-add-circle.png";
    let payLoad = { ...this.addBrandForm.value };
    //console.log("formvalues",payLoad)
   }

   DeleteBrandDetails(product){
    let dialogRef = this.dialog.open(DeleteDailogComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true,
      data : { product : product, message : "Do you want to delete the related products of the Brand ?" }
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res.isDelete) {
        this.RemoveBrand(res);
        //this.fetchAllClients();
      }
    })
  }
  RemoveBrand(res){
    let payload ={
      BrandName : res.BrandName      
    }
    this.adminservice.DeleteByBrand(payload).subscribe((posRes) => {      
      if (posRes.response == 3) {
        this.openSnackBar(posRes.message, "");  
        this.getProductBrands();
      } else {
        this.openSnackBar(posRes.message, "");      
      }
    }, (err: HttpErrorResponse) => {
      
      this.openSnackBar(err.message, "");
      this.adminservice.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })
  }
}

